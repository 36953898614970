const hasValidCordinationNumber = (input) => {
    const [, , , dayStr] = /^(\d{2})(\d{2})(\d{2})/.exec(input);
    return Number(dayStr) > 60;
};

const hasValidLength = (input) => {
    return input.length === 12;
};

export const coordinationNumberRule = (value) => {
    if (hasValidLength(value)) {
        const cleaned = value.replace(/[+-]/, '').slice(2, 8);
        return hasValidCordinationNumber(cleaned);
    }
    return false;
}
