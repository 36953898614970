<template>
  <div class="container contact-us-container">
    <template v-if="state === CONTACT_US_STATES.DEFAULT">
      <div class="my-4 text-center">
        <h2>{{ getTitleFromState(CONTACT_US_STATES.DEFAULT) }}</h2>
      </div>
      <div class="row justify-content-center">
        <ContactCard
          icon="fal fa-wallet"
          :title="getTitleFromState(CONTACT_US_STATES.PAYMENT)"
          @click="setState(CONTACT_US_STATES.PAYMENT)"
        />
        <ContactCard
          icon="fal fa-exclamation-square"
          :title="getTitleFromState(CONTACT_US_STATES.WRONG_INFO)"
          @click="setState(CONTACT_US_STATES.WRONG_INFO)"
        />
        <ContactCard
          icon="fal fa-file"
          :title="getTitleFromState(CONTACT_US_STATES.REGISTER_EXTRACT)"
          @click="setState(CONTACT_US_STATES.REGISTER_EXTRACT)"
        />
        <ContactCard
          icon="fal fa-user-times"
          :title="getTitleFromState(CONTACT_US_STATES.TERMINATION)"
          @click="setState(CONTACT_US_STATES.TERMINATION)"
        />
        <ContactCard
          icon="fas fa-ellipsis-h"
          :title="getTitleFromState(CONTACT_US_STATES.OTHER)"
          @click="setState(CONTACT_US_STATES.OTHER)"
        />
      </div>
    </template>
    <template v-else>
      <div>
        <div
          class="row back-link"
          @click="setState(CONTACT_US_STATES.DEFAULT)"
        >
          <i class="far fa-arrow-left" />
          <span class="px-2">Tillbaka till kategorier</span>
        </div>
        <div class="row form-header">
          <h2>{{ getTitleFromState(state) }}</h2>
        </div>
        <div class="row">
          <div class="col-md-6">
            <div class="form-block">
              <Other
                v-if="state === CONTACT_US_STATES.OTHER"
                :model="model"
              />
              <WrongInfo
                v-else-if="state === CONTACT_US_STATES.WRONG_INFO"
                :model="model"
              />
              <Payment
                v-else-if="state === CONTACT_US_STATES.PAYMENT"
                :model="model"
              />
              <RegisterExtract
                v-else-if="state === CONTACT_US_STATES.REGISTER_EXTRACT"
                :model="model"
              />
              <Termination
                v-else-if="state === CONTACT_US_STATES.TERMINATION"
                :model="model"
              />
            </div>
          </div>
          <MoreInformation
            title="Information"
            :rich-text="getMoreInformationFromState(state)"
          />
        </div>
      </div>
    </template>
  </div>
</template>
<script setup>
import { ref, onMounted } from 'vue';
import { CONTACT_US_STATES, loadCaptchaScript } from './contactUsUtils';
import ContactCard from './ContactCard.vue';
import MoreInformation from './MoreInformation.vue';
import RegisterExtract from './RegisterExtract.vue';
import Termination from './Termination.vue';
import Payment from './Payment.vue';
import Other from './Other.vue';
import WrongInfo from './WrongInfo.vue';

const props = defineProps({ model: Object });
const state = ref(CONTACT_US_STATES.DEFAULT);

onMounted(() => {
    if (props.model.recaptchaAPIKey) {
   
        loadCaptchaScript({ recaptchaAPIKey: props.model.recaptchaAPIKey })
    } else {
        console.log('warning: no recaptchaAPIKey key provided from episerver');
    }
})

const setState = (newState) => {
    if (newState) {
        return state.value = newState;
    }
    return state.value = CONTACT_US_STATES.DEFAULT;
};
const getTitleFromState = (currState) => {
    switch (currState) {
        case CONTACT_US_STATES.PAYMENT:
            return 'Betalning';
        case CONTACT_US_STATES.WRONG_INFO:
            return 'Fel information i din upplysning';
        case CONTACT_US_STATES.REGISTER_EXTRACT:
            return 'Registerutdrag';
        case CONTACT_US_STATES.TERMINATION:
            return 'Uppsägning';
        case CONTACT_US_STATES.OTHER:
            return 'Övrigt';
        case CONTACT_US_STATES.DEFAULT:
        default:
            return 'Välj kategori';
    }
};

const getMoreInformationFromState = (currState) => {
    switch (currState) {
        case CONTACT_US_STATES.PAYMENT:
            return props.model.paymentInfoText;
        case CONTACT_US_STATES.WRONG_INFO:
            return props.model.wrongInfoText;
        case CONTACT_US_STATES.REGISTER_EXTRACT:
            return props.model.registerExtractInfoText;
        case CONTACT_US_STATES.TERMINATION:
            return props.model.terminationInfoText;
        case CONTACT_US_STATES.OTHER:
            return props.model.otherInfoText;
        case CONTACT_US_STATES.DEFAULT:
        default:
            return '';
    }
};

</script>