<template>
  <div class="row">
    <div class="col-md-12">
      <div class="d-flex justify-content-between p-2">
        <h4>
          Blancolån
        </h4>
        <NdsIconButton theme=" enento" variant="text" prefix="far" iconName="question-circle"
          @click="openUnsecuredLoanQuestion" aria-label="Blancolån förklaring" />
      </div>
      <div v-if="hasTerminatedUnsecuredLoans" class="d-flex justify-content-end my-3">
        <NdsButton theme="enento" :prefix="{ prefix: 'far', iconName: showTerminated ? 'eye-slash' : 'eye' }"
          variant="secondary" @click="showTerminated = !showTerminated">
          {{ showTerminated ? 'Dölj avslutade' : 'Visa avslutade' }}
        </NdsButton>
      </div>
      <div v-if="unsecuredLoans.length > 0" class="block__enento__accordion__card-wrapper">
        <template v-for="(inquiry, $index) in unsecuredLoans">
          <div v-if="inquiry.toDate == null || showTerminated" :key="$index" class="block__enento__accordion__card">
            <div class="panel-heading">
              <a class="d-block collapsed p-3 pr-5" data-toggle="collapse" :href="'#unsecuredloans-' + $index">
                <span class="font-weight-bold">{{ inquiry.creditor }}</span>
              </a>
            </div>
            <div :id="'unsecuredloans-' + $index" class="collapse p-3">
              <div class="row pb-3">
                <div class="col-8">
                  Kreditlimit:
                </div>
                <div class="col-4 pl-0 text-right">
                  {{ formatToSek(inquiry.grantedCredit) }}
                </div>
                <div class="col-8 color-ocean">
                  Utnyttjad kredit:
                </div>
                <div class="col-4 pl-0 color-ocean text-right">
                  {{ formatToSek(inquiry.usedCredit) }}
                </div>
              </div>
              <div class="row">
                <template v-if="inquiry.fromDate != null">
                  <div class="col-8">
                    Startdatum:
                  </div>
                  <div class="col-4 pl-0 text-right">
                    {{ FormatDate(inquiry.fromDate.timeStamp.value) }}
                  </div>
                </template>
                <template v-if="inquiry.toDate == null || inquiry.usedCredit > 0">
                  <div class="col-8">
                    Senast uppdaterad:
                  </div>
                  <div class="col-4 pl-0 text-right">
                    {{ FormatDate(inquiry.updatedAtDate.timeStamp.value) }}
                  </div>
                </template>
                <template v-if="inquiry.toDate != null">
                  <div class="col-8 color-red">
                    Avslutad:
                  </div>
                  <div class="col-4 pl-0 color-red text-right">
                    {{ FormatDate(inquiry.toDate.timeStamp.value) }}
                  </div>
                </template>
              </div>
            </div>
          </div>
        </template>
      </div>
      <ZeroState v-else icon="far fa-sack-dollar"
      :text="model.descriptionNoUnsecuredLoanDevelopment.value || 'Inga blancolån registrerade'" :isRichText="true" />
    </div>
    <div class="col-md-12 mt-3">
      <div class="d-flex justify-content-between p-2">
        <h4 class="mb-0">
          Blancolånutveckling
        </h4>
        <NdsIconButton theme="enento" variant="text" prefix="far" iconName="question-circle"
          @click="openUnsecuredLoanDevelopmentQuestion" aria-label="Blancolånutveckling förklaring" />
      </div>
      <div class="ndsCreditChartContainer">
        <NdsCreditPlotChart title="" emptyTitle="Ingen blancolånutveckling är registrerad" :creditData="creditData" />
      </div>
    </div>
  </div>
  <ModalsContainer />
</template>
<script setup>
import { ref, computed } from 'vue';
import { ModalsContainer, useModal } from 'vue-final-modal';
import { NdsIconButton, NdsButton, NdsCreditPlotChart } from '@nds/vue';
import { formatToSek, FormatDate } from '../_helpers.js';
import ConfirmModal from '../components/modals/ConfirmModal.vue';
import ZeroState from "../components/myinformation/ZeroState.vue";

const props = defineProps({
  model: Object,
  report: Object,
});

const showTerminated = ref(true);
const hasTerminatedUnsecuredLoans = ref(false);
const unsecuredLoans = computed(() => {
  if (props.report.creditReport?.ctiCreditsWithInquirer?.creditInformations) {
    return props.report.creditReport.ctiCreditsWithInquirer.creditInformations.map((creditInformation) => {
      if (props.report.creditReport.ctiCreditWithInquirerToCreditTypeMapping[creditInformation.creditNumber] === 5) {
        if (creditInformation.toDate && !hasTerminatedUnsecuredLoans.value) {
          hasTerminatedUnsecuredLoans.value = true;
        }
        return creditInformation;
      }
      return null;
    }).filter(Boolean);
  }
  return [];
});

const creditData = computed(() => ({
  futureCreditsLabel: 'Nuläge',
  usedCreditsLabel: 'Utnyttjad kredit',
  usedCreditsLabelTooltip: 'Utnyttjad',
  entries: props.report.unsecuredLoansChartData.entries,
}));

const { open: openUnsecuredLoanQuestion, close: closeUnsecuredLoanQuestion } = useModal({
  component: ConfirmModal,
  attrs: {
    title: 'Blancolån',
    icon: 'far fa-question-circle',
    body: props.model.questionUnsecuredLoan.value,
    onConfirm() {
      closeUnsecuredLoanQuestion();
    },
    onCancel() {
      closeUnsecuredLoanQuestion();
    },
  },
});

const { open: openUnsecuredLoanDevelopmentQuestion, close: closeUnsecuredLoanDevelopmentQuestion } = useModal({
  component: ConfirmModal,
  attrs: {
    title: 'Blancolånutveckling',
    icon: 'far fa-question-circle',
    body: props.model.questionUnsecuredLoanDevelopment.value,
    onConfirm() {
      closeUnsecuredLoanDevelopmentQuestion();
    },
    onCancel() {
      closeUnsecuredLoanDevelopmentQuestion();
    },
  },
});

</script>
<style scoped>
.ndsCreditChartContainer {
  width: 100%;
  height: 320px;
}
</style>