<template>
  <VuePopper
    arrow
    placement="bottom"
  >
    <template #content="{ close }">
      <div
        :id="`popover-body-${product.id}`"
        ref="content"
        class="payment-options-pooper-content"
      >
        <ul class="payment__options-popover">
          <li
            v-if="product.paymentType == 'CARD'"
            class=""
          >
            <a
              href="#"
              class="payment__options-link"
              @click="onChangeCard(close)"
            >
              Byt kort
            </a>
          </li>
          <li v-if="product.paymentType == 'INVOICE'">
            <a
              target="_blank"
              href="https://customer.horizonafs.com/SE/uc"
              class="payment__options-link"
              @click="close"
            >
              Gå till fakturaportal <i class="fas fa-xs fa-external-link fa-fw" />
            </a>
          </li>
          <li>
            <a
              target="_blank"
              :href="`/minuc/betalningar/createreceiptpdf?pId=${product.productPackage.id}&pDate=${product.purchasedDate}&orderId=${product.id}`"
              class="payment__options-link"
              @click="close"
            >
              Kvitto
            </a>
          </li>

          <li v-if="product.isCancellable && product.paymentType !== 'RESELLER'">
            <a
              href="#"
              class="payment__options-link"
              @click="onTerminateProduct(close)"
            >
              Avsluta tjänst
            </a>
          </li>
          <li
            v-else
            @click="close"
          >
            <span class="disabled">
              Avsluta tjänst
            </span>
          </li>
        </ul>
      </div>
    </template>
    <i
      :id="`popover-trigger-${product.id}`"
      ref="caller"
      tabindex="0"
      class="far fa-ellipsis-h fa-2x fa-fw color-darkocean hover-pointer"
    />
  </VuePopper>
</template>
<script setup>
import VuePopper from "vue3-popper";

defineProps({
  product: Object
});
const emit = defineEmits(['onChangeCard', 'onTerminateProduct'])

const onChangeCard = (close) => {
  emit('onChangeCard');
  close();
}

const onTerminateProduct = (close) => {
  emit('onTerminateProduct');
  close();
}
</script>
<style lang="scss" scoped>
@import "@/../../styles/abstracts/_variables.scss";

.payment-options-pooper-content {
  font-family: Roboto;

  ul {
    list-style-type: none;
    padding: 12px;
    margin: 0;

    li {
      border-radius: 6px;

      a {
        margin: 8px 25px 8px 14px;
      }

      span {
        &.disabled {
          color: $gray;
          margin: 8px 25px 8px 14px;
        }
      }

    }
  }

}
</style>