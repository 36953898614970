<template>
  <NdsButton v-if="model?.CtaUrl" class="single-button" :class="{ 'w-100-md': isSingleBlock }" theme="minuc"
    :on-bg="model.NDSOnBg ? 'dark' : 'light'" :href="model?.CtaUrl" :variant="model.NDSButtonVariant"
    :size="size">
    {{ model.Heading }}
  </NdsButton>
  <NdsButton v-else type="button" class="single-button" :class="{ 'w-100-md': isSingleBlock }" theme="minuc"
    :on-bg="model.NDSOnBg ? 'dark' : 'light'" :variant="model.NDSButtonVariant" :size="size">
    {{ model.Heading }}
  </NdsButton>
</template>
<script setup>
import { computed } from 'vue';
import { NdsButton } from '@nds/vue';

const props = defineProps({
  model: Object,
  isSingleBlock: Boolean
});

const size = computed(() => {
  if (!props.model?.NDSButtonSize) {
    return 'normal';
  }

  switch (props.model.NDSButtonSize) {
    case 'small':
      return 'small';
    case 'large':
      return 'large';
    case 'default':
    default:
      return 'normal';
  }
});

</script>
<style lang="scss" scoped>
@import "@/../../styles/abstracts/_breakpoints.scss";

@include breakpoint(max-phone) {
  .single-button {
    width: 100%;
  }
}
</style>
