<template>
  <div class="dashboard col-md-8 col-lg-9 py-0 py-lg-5 px-0 px-lg-3" data-testid="dashboard-my-uc-score-page">
    <MobileMenu />
    <div class="col-12">
      <div v-if="pending" class="dashboard__card">
        <h2 class="h2-bold">
          Mitt UC-score
        </h2>
        <div class="align-items-center justify-content-center d-flex">
          <NdsLoader />
        </div>
      </div>
      <div v-else-if="failed" class="dashboard__card">
        <h2 class="h2-bold">
          Mitt UC-score
        </h2>
        <Reloader @reload="getCreditInfo" />
      </div>
      <template v-else>
        <UcScore v-if="customerCreditInfo.creditReport.creditwatchScoreList" size="lg" :marker-radius="25"
          :report="customerCreditInfo" />
        <div class="col-12 dashboard__card large-padding">
          <div class="question-uc-score-wrapper">
            <i v-if="model.questionUCScore && model.questionUCScore.value !== ''" class="fal fa-lg fa-question-square"
              @click="onQuestionCardClicked" />
            <template v-if="customerCreditInfo.creditReport.creditScore === null">
              <p v-html="model.descriptionNoScoreGrade.value" />
            </template>
          </div>
          <h2 class="h2-bold">
            {{ model.headline.value }}
          </h2>
          <p v-html="model.description.value" />
        </div>
      </template>
    </div>
    <RelatedArticles v-if="model.relatedArticles.length > 0" :articles="model.relatedArticles.expandedValue" />
    <MobileBottomMenu />
  </div>
</template>
<script setup>
import $ from 'jquery';
import UcScore from '../components/charts/UcScore.vue';
import MobileMenu from '../components/MobileMenu.vue';
import MobileBottomMenu from '../components/MobileBottomMenu.vue';
import RelatedArticles from '../components/RelatedArticles.vue';
import { NdsLoader } from '@nds/vue';
import { useStore } from 'vuex';
import { computed, onMounted } from 'vue';
import { CUSTOMER_CREDIT_INFO_FETCH } from '../Scripts/store/modules/customerCreditInfoContex';
import { CUSTOMER_CREDIT_NOTIFICATIONS_FETCH_AND_CLEAR_CATEGORY } from '../Scripts/store/modules/customerCreditNotificationsContex'
import { APP_SHOW_SIDEBAR } from '../Scripts/store/modules/appContext';
import Reloader from '../components/Reloader.vue';

const props = defineProps({
  model: Object,
});

const store = useStore();
const customerCreditInfo = computed(() => store.state.customerCreditInfoContex.customerCreditInfo);
const pending = computed(() => store.state.customerCreditInfoContex.pending);
const failed = computed(() => store.state.customerCreditInfoContex.failed);

onMounted(() => {
  document.title = props.model.name || "Mina tjänster";
  store.dispatch(CUSTOMER_CREDIT_NOTIFICATIONS_FETCH_AND_CLEAR_CATEGORY, { filterCategory: "UC_SCORE" });
  if (!store.state.customerCreditInfoContex.loaded) {
    store.dispatch(CUSTOMER_CREDIT_INFO_FETCH);
  }
  if (!store.state.appContext.showSideBar) {
    store.dispatch(APP_SHOW_SIDEBAR);
  }
});

const getCreditInfo = () => {
  store.dispatch(CUSTOMER_CREDIT_INFO_FETCH);
}

const onQuestionCardClicked = () => {
  $('.question-uc-score-card-overlay').show();
}

</script>
