<template>
  <div
    class="checkout-page-container container-fluid py-3 py-md-4"
    data-testid="checkout-steper-control"
  >
    <div class="container">
      <div
        class="row position-relative flex-column flex-md-row text-center text-md-left justify-content-center justify-content-md-between align-items-center align-items-md-center"
      >
        <button
          v-if="activeTab !== ACTIVE_TAB.COMPLETE_TAB"
          class="btn btn-enento-text checkout__mobile-btn mr-4 d-md-block"
          @click="goBackOneStep"
        >
          <i class="far fa-chevron-left fa-sm mr-3" />Bakåt
        </button>
        <p
          v-if="activeTab !== ACTIVE_TAB.COMPLETE_TAB"
          class="step-counter body-xsmall mb-0 d-md-none"
        >
          {{ getStepCounter() }}
        </p>
        <ul
          id="checkout-steps-tab"
          class="nav nav-tabs arrow__container border-0"
          role="tablist"
        >
          <li class="d-flex align-items-center nav-item arrow__item">
            <a
              id="checkout-checkoutSelectProduct-tab"
              class="nav-link  position-relative p-3 py-md-2 px-md-3"
              :class="{
                'active': activeTab === ACTIVE_TAB.SELECT_PRODUCT_TAB,
                'completed': selectedProduct,
                'pe-none': activeTab === ACTIVE_TAB.COMPLETE_TAB
              }"
              data-toggle="tab"
              href="#checkout-checkoutSelectProduct"
              role="tab"
              aria-controls="checkout-checkoutSelectProduct"
              aria-selected="true"
              @click="setStep(CHECKOUT_STEP.SELECT_PRODUCT)"
            >
              <p class="arrow__content mb-0 d-none d-md-block">Välj tjänst</p>
              <p class="arrow__content mb-0 d-none d-md-block">Steg 1</p>
            </a>
          </li>
          <li class="d-flex align-items-center nav-item arrow__item">
            <a
              id="checkout-CheckoutEnterInformation-tab"
              class="nav-link position-relative p-3 py-md-2 px-md-3"
              :class="{
                'active': activeTab === ACTIVE_TAB.ENTER_INFORMATION_TAB,
                'disabled': activeTab === ACTIVE_TAB.ENTER_INFORMATION_TAB && !checkoutEnterInformationDisabled,
                'completed': hasProvidedAndConfirmedCustomer,
                'pe-none': activeTab === ACTIVE_TAB.COMPLETE_TAB
              }"
              data-toggle="tab"
              href="#checkout-CheckoutEnterInformation"
              role="tab"
              aria-controls="tab2"
              aria-selected="false"
              @click="setStep(CHECKOUT_STEP.ENTER_INFORMATION)"
            >
              <p class="arrow__content mb-0 d-none d-md-block">Din beställning</p>
              <p class="arrow__content mb-0 d-none d-md-block">Steg 2</p>
            </a>
          </li>
          <li class="d-flex align-items-center nav-item arrow__item">
            <a
              id="checkoutPayment-tab"
              class="nav-link position-relative p-3 py-md-2 px-md-3 disabled"
              :class="{
                'active': activeTab === ACTIVE_TAB.PAYMENT_TAB,
                'completed pe-none': activeTab === ACTIVE_TAB.COMPLETE_TAB
              }"
              data-toggle="tab"
              href="#checkoutPayment"
              role="tab"
              aria-controls="tab3"
              aria-selected="false"
              @click="setStep(CHECKOUT_STEP.ENTER_INFORMATION)"
            >
              <p class="arrow__content mb-0 d-none d-md-block">Slutför beställning</p>
              <p class="arrow__content mb-0 d-none d-md-block">Steg 3</p>
            </a>
          </li>
        </ul>
      </div>
    </div>
  </div>
</template>
<script setup>
import { useStore } from 'vuex';
import { computed } from 'vue';
import {
    ACTIVE_TAB,
    CHECKOUT_SELECT_STEP,
    CHECKOUT_STEP,
    CHECKOUT_SELECT_PREVIOUS_STEP,
} from '../../Scripts/store/modules/checkoutContext';
import { getPathnameFromUrl } from './checkoutUtils';

const props = defineProps({
    model: Object,
});

const store = useStore();
const activeTab = computed(() => store.state.checkoutContext.activeTab);
const selectedProduct = computed(() => store.state.checkoutContext.selectedProduct);
const hasProvidedAndConfirmedCustomer = computed(() => store.state.checkoutContext.hasProvidedAndConfirmedCustomer);
const checkoutEnterInformationDisabled = computed(() => false); // TODO investigate how to calculate this

const getStepCounter = () => `Steg ${activeTab.value} av 3`;

const setStep = (step) => {
    store.dispatch(CHECKOUT_SELECT_STEP, { step });
};

const goBackOneStep = () => {
    if (activeTab.value !== ACTIVE_TAB.SELECT_PRODUCT_TAB) {
        return store.dispatch(CHECKOUT_SELECT_PREVIOUS_STEP);
    }
    const productPageUrl = props.model?.productPageUrl?.value || '/';
    return window.location.href = getPathnameFromUrl(productPageUrl);
};
</script>
<style lang="scss" scoped>
@import "@/../../styles/abstracts/_mixins.scss";
@import "@/../../styles/abstracts/_variables.scss";
@import "@/../../styles/abstracts/_breakpoints.scss";

.nav-link {
    &.active {
        background: inherit;
        border: 0;

        &:after {
            color: $ocean;
            top: calc(50% - 10px);
        }
    }

    &:after {
        font-weight: 700;
        color: $silver;
        font-family: "Font Awesome 5 Pro";
        position: absolute;
        display: inline-block;
        content: "\f111";
        font-size: .875em;
        left: 10px;
        top: calc(50% - 11px);
    }

    &.completed:not(.active) {
        &:after {
            color: $ocean;
            content: "\f058";
        }
    }


    .card {
        border-radius: 8px;

        &.highlighted {
            background: $beach;
        }
    }

    .card-body {
        padding: 16px;

        @include attention() {
            cursor: pointer;
        }

        input[type=radio] {
            width: 24px;
            height: 24px;
        }

        i {
            font-size: 19px;
        }
    }

    .card-label {
        position: absolute;
        top: -15px;
        left: 25px;
        padding: 2px 8px;
        border-radius: 6px;
        background-color: $violet;
        color: $white;
    }

    .checkbox-container {
        display: inline-block;
        position: relative;
        padding-left: 30px;
        /* Adjust as needed */
        cursor: pointer;
        user-select: none;
    }

    /* Hide the actual checkbox input */
    .checkbox-container input {
        position: absolute;
        opacity: 0;
        cursor: pointer;
    }

    /* Style the custom checkbox */
    .checkmark {
        position: absolute;
        top: 0;
        left: 0;
        height: 20px;
        width: 20px;
        border-radius: 3px;
        border: 1px solid $gray;
    }

    /* Style the checkmark icon (FontAwesome) */
    .checkmark i {
        display: none;
        /* Hide by default */
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
        color: #333;
    }

    /* Show the checkmark icon when the checkbox is checked */
    .checkbox-container input:checked+.checkmark {
        background-color: $dark-ocean;
        border-color: $dark-ocean;

        i {
            display: block;
            font-size: 10px;
            color: $white;
        }
    }
}

@include breakpoint(tablet-portrait) {

    .checkout-page-container {
        background-color: $light-smoke;
    }

    .nav-tabs {
        flex-grow: 1;
    }

    .nav-item {
        flex-grow: 1;
        box-shadow: 0px 1px 3px 0px rgba(0, 0, 0, 0.1);

        .nav-link {
            flex-grow: 1;
            background-color: $white;

            p {
                color: $emperor;
            }

            &:after {
                color: $gallery;
                font-family: "Font Awesome 5 Pro";
                position: absolute;
                display: inline-block;
                font-weight: 700;
                content: "\f111";
                font-size: 16px;
                left: 25px;
                top: calc(50% - 11px);
            }

            &.active {
                background-color: $deep-ocean;

                p {
                    color: $ocean-foam;
                }

                &:after {
                    color: $summer-sky;
                    content: "\f192";
                }
            }

            &.completed:not(.active) {
                background-color: $dark-ocean;

                p {
                    color: $ocean-foam;
                }

                &:after {
                    color: $summer-sky;
                    content: "\f058";
                }
            }
        }

        &:first-child {
            .nav-link:after {
                left: 15px;
            }
        }
    }

    .arrow__container {
        --d: 1rem;
        /* arrow depth */
        --gap: 0.4rem;
        /* arrow thickness, gap */
        margin-right: var(--d);
        filter: drop-shadow(0px 1px 3px rgba(0, 0, 0, 0.10)) drop-shadow(0px 2px 1px rgba(0, 0, 0, 0.06)) drop-shadow(0px 1px 1px rgba(0, 0, 0, 0.07));
    }

    .arrow__item {
        flex: 1;
        justify-content: center;
        align-items: center;
        margin-right: calc(var(--d) * -1 + var(--gap));
        clip-path: polygon(0% 0%, calc(100% - var(--d)) 0%, 100% 50%, calc(100% - var(--d)) 100%, 0% 100%, var(--d) 50%);

        .arrow__content {
            padding-left: 35px;
        }

        &:first-child {
            clip-path: polygon(0% 0%, calc(100% - var(--d)) 0%, 100% 50%, calc(100% - var(--d)) 100%, 0% 100%);

            .arrow__content {
                padding-left: 25px;
            }
        }
    }

}

@include breakpoint(max-tablet-portrait) {
    .checkout__mobile-btn {
        position: absolute;
        left: 0;
    }
}
</style>