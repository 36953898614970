<template>
  <div class="px-3 row">
    <div
      class="col-12"
      style="margin-top:3rem;"
    >
      <div class="saving-goal-header-wrapper">
        Utmaningar
        <a
          v-if="model.questionChallenges.value && model.questionChallenges.value != ''"
          class="ml-auto"
          data-toggle="modal"
          data-target="#challenge-question"
          aria-expanded="false"
          aria-controls="challenge-question"
        >
          <i class="far fa-question-circle color-darkocean hover-pointer" />
        </a>
      </div>
      <span
        v-if="numberOfActiveChallenges == 1"
        class="small-label"
      >{{ numberOfActiveChallenges }} aktiv utmaning</span>
      <span
        v-if="numberOfActiveChallenges > 1"
        class="small-label"
      >
        {{
          numberOfActiveChallenges
        }} aktiva utmaningar
      </span>
      <span
        v-if="numberOfActiveChallenges == 0"
        class="small-label"
      >0 aktiva utmaningar</span>
    </div>
    <template v-for="challenge in challenges">
      <template v-for="(customerChallenge, index) in challenge.customerChallenges">
        <div
          v-if="customerChallenge.challengeStatus === 'ACTIVE'"
          :key="`challenges_customer_challenge_${index}`"
          class="col-12"
        >
          <div :class="'dashboard__card-orange '+customerChallenge.challengeStatus">
            <h3>{{ categoryNames[challenge.challengeCategory] }}</h3>
            <span>{{ parseInt(customerChallenge.challengePeriod / 30) }} månader</span><br>
            <div class="savings-container">
              <div
                class="savings-meter"
                data-testid="savings-meter-challenges-active"
                :style="'max-width:100%;width:'+ChallengeGoalMet(customerChallenge)+'%;'"
              />
            </div>
            <span>Utmaningen startades {{ FormatChallengeDate(customerChallenge) }}</span><br>
            <a
              href="#"
              class="action-link right"
              @click="DeleteChallenge(customerChallenge)"
            >
              Avbryt
              utmaningen
            </a><br>
          </div>
        </div>
      </template>
    </template>
    <template v-for="(challenge, index) in challenges">
      <div
        v-if="!challenge.hasActiveChallenge && challenge.isAvailable"
        :key="`challenges_accordion_${index}`"
        class="col-12"
      >
        <div class="block__enento__accordion__card-wrapper mb-3">
          <div class="block__enento__accordion__card">
            <div
              id="accordion"
              class="panel-group"
              role="tablist"
              aria-multiselectable="true"
            >
              <div
                :id="challenge.challengeCategory"
                class="panel-heading"
                role="tab"
              >
                <a
                  class="d-block collapsed p-3 pr-5"
                  role="button"
                  :title="challenge.challengeCategory"
                  data-toggle="collapse"
                  data-parent="#accordion"
                  :href="'#collapsed-'+challenge.challengeCategory"
                  aria-expanded="true"
                  :aria-controls="'#collapsed-'+challenge.challengeCategory"
                >
                  <span class="font-weight-bold">{{ categoryNames[challenge.challengeCategory] }}</span>
                </a>
              </div>
              <div
                :id="'collapsed-'+challenge.challengeCategory"
                class="panel-collapse collapse"
                role="tabpanel"
                :aria-labelledby="challenge.challengeCategory"
              >
                <div class="panel-body px-3 my-4">
                  <template v-if="challenge.challengeCategory==='PAY_OFF_YOUR_MORTGAGE'">
                    <p
                      v-if="model.pAY_OFF_YOUR_MORTGAGE != null"
                      v-html="model.pAY_OFF_YOUR_MORTGAGE.value"
                    />
                    <input
                      v-model="challengeModel.value"
                      class="savings-slider mb-4"
                      step="5000"
                      min="5000"
                      max="100000"
                      type="range"
                    >
                    <div class="slider-label-box">
                      <span>{{ challengeModel.value }}</span>
                    </div>
                  </template>
                  <template v-if="challenge.challengeCategory==='DO_NOT_BUY_DOWN_PAYMENT'">
                    <p v-html="model.dO_NOT_BUY_DOWN_PAYMENT?.value" />
                  </template>
                  <template v-if="challenge.challengeCategory==='DO_NOT_TAKE_BLANK_LOAN'">
                    <p v-html="model.dO_NOT_TAKE_BLANK_LOAN?.value" />
                  </template>
                  <template v-if="challenge.challengeCategory==='DO_NOT_TAKE_NEW_LOANS'">
                    <p v-html="model.dO_NOT_TAKE_NEW_LOANS?.value" />
                  </template>
                  <template v-if="challenge.challengeCategory==='PAY_OFF_YOUR_DOWN_PAYMENT'">
                    <p v-html="model.pAY_OFF_YOUR_DOWN_PAYMENT?.value" />
                  </template>
                  <template v-if="challenge.challengeCategory==='DO_NOT_BUY_ANYTHING_WITH_CREDIT_CARD'">
                    <p v-html="model.dO_NOT_BUY_ANYTHING_WITH_CREDIT_CARD?.value" />
                  </template>
                  <input
                    v-model="challenge.periodInDays"
                    class="savings-slider"
                    min="2"
                    max="12"
                    type="range"
                  >
                  <div class="slider-label-box">
                    <span>{{ challenge.periodInDays }} månader</span>
                  </div>
                  <div class="d-flex">
                    <a
                      href="#"
                      class="action-link ml-auto"
                      @click="CreateChallenge(challenge)"
                    >Starta utmaningen</a>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </template>
    <div class="col-12">
      <button
        v-if="showTerminated"
        class="toggleButton"
        data-testid="hide-terminated-toggle-button"
        @click="showTerminated = !showTerminated"
      >
        <i class="fas fa-eye-slash" /> Dölj avslutade
      </button>
      <button
        v-if="!showTerminated"
        class="toggleButton"
        @click="showTerminated = !showTerminated"
      >
        <i class="far fa-eye" /> Visa avslutade
      </button>
    </div>
    <template v-if="showTerminated">
      <template v-for="challenge in challenges">
        <template v-for="(customerChallenge, index) in challenge.customerChallenges">
          <div
            v-if="customerChallenge.challengeStatus !== 'ACTIVE'"
            :key="`challenges_customer_challenge_category_${index}`"
            class="col-12"
          >
            <div :class="'dashboard__card-orange '+customerChallenge.challengeStatus">
              <h3>{{ categoryNames[challenge.challengeCategory] }}</h3>
              <span>{{ parseInt(customerChallenge.challengePeriod / 30) }} månader</span><br>
              <div class="savings-container">
                <div
                  class="savings-meter"
                  data-testid="savings-meter-challenges-terminated"
                  :style="'max-width:100%;width:'+ChallengeGoalMet(customerChallenge)+'%;'"
                />
              </div>
              <span>Utmaningen startades {{ FormatChallengeDate(customerChallenge) }}</span><br>
              <template v-if="customerChallenge.challengeStatus === 'FAILED'">
                <p v-html="model.failedChallenge.value" />
              </template>
              <template v-if="customerChallenge.challengeStatus === 'CANCELED'">
                <p v-html="model.failedChallenge.value" />
              </template>
              <template v-if="customerChallenge.challengeStatus === 'COMPLETED'">
                <p v-html="model.succededChallenge.value" />
              </template>
            </div>
          </div>
        </template>
      </template>
    </template>
    <InfoModal
      id="challenge-question"
      :title="model.questionChallengesTitle.value"
      :body="model.questionChallenges.value"
    />
  </div>
</template>
<script>
import InfoModal from '@/components/modals/InfoModal.vue';
import {OddOrEven, NumberFormat, FormatDate} from '@/_helpers.js';
import $ from 'jquery';

export default {
  name: 'Challenges',
  components: {
        InfoModal
  },
  props: {

    challengesData: Object,
    model: Object
  },
  data: function () {
    return {
      numberOfActiveChallenges: 0,
      challenges: [],
      challengeModel: {
        value: 5000,
        customerId: 0,
        periodInDays: 1,
        challengeCategory: ""
      },
      categoryNames: {
        DO_NOT_BUY_ANYTHING_WITH_CREDIT_CARD: "Köp inget på kreditkort",
        DO_NOT_BUY_DOWN_PAYMENT: "Köp inget på avbetalning",
        DO_NOT_TAKE_BLANK_LOAN: "Ta inga nya blancolån",
        DO_NOT_TAKE_NEW_LOANS: "Ta inga nya krediter",
        PAY_OFF_YOUR_DOWN_PAYMENT: "Avsluta en avbetalning",
        PAY_OFF_YOUR_MORTGAGE: "Betala av på ditt bolån"
      },
      showTerminated: true,
    }
  },
  created: function () {
    this.numberOfActiveChallenges = this.challengesData.numberOfActiveChallenges;
    this.challenges = this.challengesData.challengeCategoryList;
  },
  methods: {
    OddOrEven: function (index) {
      return OddOrEven(index);
    },
    FormatDate: function (timestamp) {
      return FormatDate(timestamp);
    },
    NumberFormat: function (num) {
      return NumberFormat(num);
    },
    FormatChallengeDate: function (customerChallenge) {
      var challenge = customerChallenge;
      var options = {weekday: 'long', year: 'numeric', month: 'long', day: 'numeric'};
      //var today = new Date(challenge.challengeCreationTime.year, challenge.challengeCreationTime.monthValue -1, challenge.challengeCreationTime.dayOfMonth);
      var today = new Date(challenge.challengeCreationTime);
      return today.toLocaleDateString("sv-SE", options);
    },
    ChallengeGoalMet: function (customerChallenge) {
      var challenge = customerChallenge;
      var creationDate = new Date(challenge.challengeCreationTime);
      var goalDate = new Date(creationDate);
      goalDate.setDate(creationDate.getDate() + challenge.challengePeriod);
      var dateNow = new Date();
      if (dateNow.getTime() >= goalDate.getTime()) return 100;
      return ((dateNow.getTime() - creationDate.getTime()) / (goalDate.getTime() - creationDate.getTime())) * 100;
    },
    CreateChallenge: function (challenge) {
      var self = this;
      this.challengeModel.challengeCategory = challenge.challengeCategory;
      this.challengeModel.periodInDays = challenge.periodInDays;
      $.post("/newcreditwatchapi/createchallenge", {challengeModel: this.challengeModel},
          function (response) {
            challenge.customerChallenges.push(response);
            self.numberOfActiveChallenges++;
            challenge.hasActiveChallenge = true;
          });
    },
    DeleteChallenge: function (customerChallenge) {
      var self = this;
      var challengeId = customerChallenge.id;
      $.post("/newcreditwatchapi/deletechallenge", {challengeId: challengeId},
          function () {
            self.challenges.forEach(function (challenge) {
              challenge.customerChallenges.forEach(function (customerChallenge) {
                if (customerChallenge.id === challengeId) {
                  customerChallenge.challengeStatus = 'CANCELED';
                  challenge.hasActiveChallenge = false;
                }
              });
            });
            self.numberOfActiveChallenges--;
          });
    }
  }
}
</script>