<template v-if="ctiConsumerProperties != null">
  <div data-testid="minupplysning-consumerproperties-mobile-tab">
    <div
      id="consumer-properties-mobile-table"
      class="dashboard__table"
    >
      <div
        v-for="(property, index) in ctiConsumerProperties.consumerPropertyDetails"
        id="consumer-properties-mobile-accordion"
        :key="index"
        class="block__enento__accordion"
      >
        <div class="panel-group border-0">
          <div class="panel-heading">
            <a
              class="d-block collapsed"
              data-toggle="collapse"
              :href="'#consumer-properties-mobile-accordion-' + index"
              data-testid="consumer-properties-mobile-accordion"
            >
              <p class="dashboard__table-column color-nightrider font-weight-medium py-3 mb-0">
                {{ property.propertyName }}
              </p>
            </a>
          </div>
          <div
            :id="'consumer-properties-mobile-accordion-' + index"
            class="collapse"
            data-parent="#consumer-properties-mobile-accordion"
          >
            <div class="dashboard__table-column">
              <p class="mb-0">
                <span class="color-emperor font-weight-medium">Kommun</span>
                <span class="d-block">{{ property.municipality }}</span>
              </p>
            </div>
            <div class="dashboard__table-column">
              <p class="mb-0">
                <span class="color-emperor font-weight-medium">Fastighetstyp</span>
                <span class="d-block">{{ property.propertyTypeText }}</span>
              </p>
            </div>
            <div class="dashboard__table-column">
              <p class="mb-0">
                <span class="color-emperor font-weight-medium">Areal</span>
                <span class="d-block">{{ NumberFormat(property.areal) }} {{ property.arealTypeText }}</span>
              </p>
            </div>
            <div class="dashboard__table-column">
              <p class="mb-0">
                <span class="color-emperor font-weight-medium">Ägarandel</span>
                <span class="d-block">{{ property.ownedShare }}%</span>
              </p>
            </div>
            <div
              v-if="property.purchaseDate != null"
              class="dashboard__table-column"
            >
              <p class="mb-0">
                <span class="color-emperor font-weight-medium">Förvärvsdatum</span>
                <span class="d-block">{{
                  new Date(property.purchaseDate.timeStamp.value).toLocaleDateString('sv-SE')
                }}</span>
              </p>
            </div>
            <div class="dashboard__table-column">
              <p class="mb-0">
                <span class="color-emperor font-weight-medium">Taxeringsvärde</span>
                <span class="d-block">{{ NumberFormat(property.taxAssessedValue) }} 000 kr</span>
              </p>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import {FormatDate, NumberFormat} from '../_helpers.js';

export default {
  name: 'ConsumerPropertiesMobile',
  props: {
    ctiConsumerProperties: Object,
  },
  data: function () {
    return {}
  },
  methods: {
    FormatDate: function (timestamp) {
      return FormatDate(timestamp);
    },
    NumberFormat: function (num) {
      return NumberFormat(num);
    }
  }
}
</script>