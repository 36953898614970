<template>
  <div class="dashboard col-md-8 col-lg-9 py-0 py-lg-5 px-0 px-lg-3" data-testid="dashboard-mortgages-page">
    <MobileMenu />
    <div class="col-12">
      <div class="dashboard__card">
        <div class="row">
          <div class="col-12 mt-2">
            <h2>{{ model.name }}</h2>
            <p v-html="model.description.value" />
          </div>
          <div v-if="pending" class="align-items-center justify-content-center d-flex flex-fill">
            <NdsLoader />
          </div>
          <div v-else-if="failed" class="col-12">
            <Reloader @reload="getCreditInfo" />
          </div>
          <template v-else>
            <ul class="nav nav-tabs d-flex flex-nowrap w-100 border-0" role="tablist">
              <li role="presentation" class="d-flex nav-item text-nowrap">
                <a href="#profile" class="active" aria-controls="profile" role="tab" data-toggle="tab">Bolån</a>
              </li>
              <li role="presentation" class="d-flex nav-item text-nowrapp">
                <a href="#messages" aria-controls="messages" role="tab" data-toggle="tab">Blancolån</a>
              </li>
              <li class="d-flex flex-fill nav-item">
                <a href="#" class="pe-none flex-fill" />
              </li>
            </ul>
            <div class="col-12">
              <div class="tab-content py-4">
                <div id="profile" role="tabpanel" class="tab-pane active">
                  <Mortgages :model="model"
                    :report="customerCreditInfo" />
                </div>
                <div id="messages" role="tabpanel" class="tab-pane">
                  <UnsecuredLoans :model="model"
                    :report="customerCreditInfo" />
                </div>
              </div>
            </div>
          </template>
        </div>
      </div>
    </div>
    <RelatedArticles v-if="model.relatedArticles.length > 0" :articles="model.relatedArticles.expandedValue" />
    <MobileBottomMenu />
  </div>
</template>
<script setup>
import { useStore } from 'vuex';
import { computed, onMounted } from 'vue';
import { NdsLoader } from '@nds/vue';
import Mortgages from '../components/Mortgages.vue';
import MobileMenu from '../components/MobileMenu.vue';
import MobileBottomMenu from '../components/MobileBottomMenu.vue';
import UnsecuredLoans from '../components/UnsecuredLoans.vue';
import RelatedArticles from '../components/RelatedArticles.vue';
import { CUSTOMER_CREDIT_INFO_FETCH } from '../Scripts/store/modules/customerCreditInfoContex';
import { APP_SHOW_SIDEBAR } from '../Scripts/store/modules/appContext';
import Reloader from '../components/Reloader.vue';

const props = defineProps({
  model: Object
});
const store = useStore();
const customerCreditInfo = computed(() => store.state.customerCreditInfoContex.customerCreditInfo);
const failed = computed(() => store.state.customerCreditInfoContex.failed);
const pending = computed(() => store.state.customerCreditInfoContex.pending);

onMounted(() => {
  document.title = props.model.name || "Mina tjänster";
  if (!store.state.customerCreditInfoContex.loaded) {
    store.dispatch(CUSTOMER_CREDIT_INFO_FETCH);
  }
  if (!store.state.appContext.showSideBar) {
    store.dispatch(APP_SHOW_SIDEBAR);
  }
});

const getCreditInfo = () => {
  store.dispatch(CUSTOMER_CREDIT_INFO_FETCH);
}
</script>
