<template>
  <div
    id="remarks-kfm-table"
    class="col-12 dashboard__table"
  >
    <template v-if="complaints.consumerPaymentDebtWithSESData.debtDate">
      <div class="py-4">
        <h4 class="mb-0">
          Aktuell skuld rapporterad från Kronofogdemyndigheten
        </h4>
      </div>
      <div class="px-0">
        <div class="d-flex align-items-center">
          <p>Datum:</p>
          <p class="ml-auto">
            {{ FormatDate(complaints.consumerPaymentDebtWithSESData.debtDate.timeStamp.value) }}
          </p>
        </div>
        <div class="d-flex align-items-center">
          <p class="mb-2">
            Allmänna mål ({{ complaints.consumerPaymentDebtWithSESData.numberOfPublicClaims }} st):
          </p>
          <p class="mb-2 font-weight-medium ml-auto">
            {{ NumberFormat(complaints.consumerPaymentDebtWithSESData.publicClaims) }} kr
          </p>
        </div>
        <div class="d-flex align-items-center">
          <p class="mb-0">
            Enskilda mål ({{ complaints.consumerPaymentDebtWithSESData.numberOfPrivateClaims }} st):
          </p>
          <p class="mb-0 font-weight-medium ml-auto">
            {{ NumberFormat(complaints.consumerPaymentDebtWithSESData.privateClaims) }} kr
          </p>
        </div>
        <hr>
        <div class="d-flex align-items-center">
          <p class="mb-2 font-weight-medium">
            Antal mål totalt:
          </p>
          <p class="mb-2 font-weight-medium ml-auto">
            {{ complaints.consumerPaymentDebtWithSESData.sumOfClaims }} st
          </p>
        </div>
        <div class="d-flex align-items-center">
          <p class="font-weight-medium color-ocean">
            Belopp total:
          </p>
          <p class="font-weight-medium ml-auto color-ocean">
            {{ NumberFormat(complaints.consumerPaymentDebtWithSESData.sumClaims) }} kr
          </p>
        </div>
      </div>
      <div
        v-if="complaints.historicalDebtDetails.length > 0 && complaints.historicalDebtDetails[0].sumDebtText"
        class="px-0 py-3"
      >
        <p
          class=""
          v-html="complaints.historicalDebtDetails[0].sumDebtText"
        />
        <p>Beloppen ovan avser oreglerade skulder hos kronofogden. Observera att dessa skulder ibland omfattar fordringar som även finns redovisade som betalningsanmärkningar.</p>
      </div>
      <!--<div class="px-3" v-if="complaints.consumerPaymentDebtWithSESData && complaints.consumerPaymentDebtWithSESData.debtText">
                <p class="" v-html="complaints.consumerPaymentDebtWithSESData.debtText"></p>
            </div>-->
    </template>
    <template v-if="complaints.historicalDebtDetails && complaints.historicalDebtDetails.length > 0">
      <h5>Historiskt skuldsaldo rapporterat från Kronofogdemyndigheten</h5>
      <p>Skuld hos kronofogden de senaste 24 månaderna. Beloppen i kr visar det högsta beloppet som rapporterats till UC under månaden. För period som är utelämnad, saknar UC kännedom om skuldsaldot.</p>
      <div
        id="remarks-kfm-history-table"
        class="col-12 dashboard__table"
      >
        <div class="row dashboard__table-header">
          <div class="col-3 dashboard__table-column">
            <h5 class="mb-0">
              Datum
            </h5>
          </div>
          <div class="col-3 dashboard__table-column">
            <h5 class="mb-0">
              Antal mål
            </h5>
          </div>
          <div class="col-2 dashboard__table-column px-0 text-right">
            <h5 class="mb-0">
              Allmänna mål
            </h5>
          </div>
          <div class="col-2 dashboard__table-column px-0 text-right">
            <h5 class="mb-0">
              Enskilda mål
            </h5>
          </div>
          <div class="col-2 dashboard__table-column pl-0 text-right">
            <h5 class="mb-0">
              Belopp total
            </h5>
          </div>
        </div>
        <div
          v-for="(complaint, index) in complaintsChunk.historicalDebtDetails"
          :key="`remarks_kfm_${index}`"
          class="row"
        >
          <div
            v-if="complaint.debtDate.timeStamp.value"
            class="col-3 dashboard__table-column"
          >
            <span class="d-block">{{ FormatDateRemoveDays(complaint.debtDate.timeStamp.value) }}</span>
          </div>
          <div class="col-3 dashboard__table-column">
            <span class="d-block">{{ complaint.sumClaims }} st</span>
          </div>
          <div class="col-2 dashboard__table-column pr-0 text-right">
            <span class="d-block">
              {{ NumberFormat(complaint.publicClaims) }} kr
            </span>
          </div>
          <div class="col-2 dashboard__table-column pr-0 text-right">
            <span class="d-block">
              {{ NumberFormat(complaint.privateClaims) }} kr
            </span>
          </div>
          <div class="col-2 dashboard__table-column text-right">
            <span class="d-block">
              {{ NumberFormat(getTotalClaimsOfComplaint(complaint)) }} kr
            </span>
          </div>
        </div>
        <Paging
          :margin-bottom="'mb-3'"
          :items="complaints.historicalDebtDetails"
          :items-per-page="10"
          :paging-enabled="true"
          @chunk="ShowSpan"
        />
      </div>
    </template>
  </div>
</template>
<script>
import {FormatDate, FormatDateRemoveDays, NumberFormat} from '../_helpers.js';
import Paging from "./Paging.vue";

export default {
  name: 'Remarks',
  components: {Paging},
  props: {
    complaints: Object,
    model: Object
  },
  data: function () {
    return {
        complaintsChunk: {
            historicalDebtDetails: []
        }
    }
  },
  methods: {
    ShowSpan(chunk) {
          this.complaintsChunk.historicalDebtDetails = chunk;
    },
    getTotalClaimsOfComplaint(complaint) {
      let total = complaint.privateClaims + complaint.publicClaims;
      return total;
    },
    FormatDateRemoveDays: function (timestamp) {
      return FormatDateRemoveDays(timestamp);
    },
    FormatDate: function (timestamp) {
      return FormatDate(timestamp);
    },
    NumberFormat: function (num) {
      return NumberFormat(num);
    }
  }
}
</script>