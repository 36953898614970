export const CONTACT_US_STATES = {
    DEFAULT: 'DEFAULT',
    OTHER: 'OTHER',
    PAYMENT: 'PAYMENT',
    REGISTER_EXTRACT: 'REGISTER_EXTRACT',
    TERMINATION: 'TERMINATION',
    WRONG_INFO: 'WRONG_INFO',
}

export const loadCaptchaScript = ({ recaptchaAPIKey }) => {
    const recaptchaScript = document.createElement("script");
    recaptchaScript.setAttribute(
        "src",
        `https://www.google.com/recaptcha/api.js?render=${recaptchaAPIKey}`
    );
    document.head.appendChild(recaptchaScript);
}

export const reCaptchaSubmit = ({ recaptchaAPIKey, handleRecaptchaTokenRecieved }) => {
    window.grecaptcha.ready(() => {
        window.grecaptcha.execute(recaptchaAPIKey, { action: 'submit' })
        .then((token) => {
            if (token !== "") {
                return handleRecaptchaTokenRecieved(token);
            } else {
                console.log('reCaptchaSubmit token not recieved');
                return window.grecaptcha.reset();
            }
        }).catch(() => {
            console.log('reCaptchaSubmit error');
            return window.grecaptcha.reset();
        });
    });
}