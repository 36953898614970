<template>
  <div>
    <div
      id="income-and-taxation-table"
      class="col-12 dashboard__table"
      data-testid="min-upplysning-income-income-table"
    >
      <div class="row dashboard__table-header">
        <div class="col-6 dashboard__table-column">
          <h5 class="mb-0" />
        </div>
        <div class="col-3 dashboard__table-column text-right">
          <h5 class="mb-0">
            {{ currentYearIncomeAndTaxation.incomeYearDate }}
          </h5>
        </div>
        <div class="col-3 dashboard__table-column text-right">
          <h5 class="mb-0">
            {{ lastYearIncomeAndTaxation.incomeYearDate }}
          </h5>
        </div>
      </div>
      <IncomeAndTaxationRow
        :label="currentYearIncomeAndTaxation.fields.employmentIncome.label"
        :curr-value="currentYearIncomeAndTaxation.fields.employmentIncome.value"
        :prev-value="lastYearIncomeAndTaxation.fields.employmentIncome.value"
      />
      <IncomeAndTaxationRow
        v-if="soleTrader"
        :label="currentYearIncomeAndTaxation.fields.activeBusinessActivityIncome.label" 
        :curr-value="currentYearIncomeAndTaxation.fields.activeBusinessActivityIncome.value"
        :prev-value="lastYearIncomeAndTaxation.fields.activeBusinessActivityIncome.value"
      />
      <IncomeAndTaxationRow
        v-if="soleTrader"
        :label="currentYearIncomeAndTaxation.fields.passiveBusinessActivityIncome.label" 
        :curr-value="currentYearIncomeAndTaxation.fields.passiveBusinessActivityIncome.value"
        :prev-value="lastYearIncomeAndTaxation.fields.passiveBusinessActivityIncome.value"
      />
      <IncomeAndTaxationRow
        :label="currentYearIncomeAndTaxation.fields.assessedEarnedIncome.label" 
        :curr-value="currentYearIncomeAndTaxation.fields.assessedEarnedIncome.value"
        :prev-value="lastYearIncomeAndTaxation.fields.assessedEarnedIncome.value"
      />
      <IncomeAndTaxationRow
        :label="currentYearIncomeAndTaxation.fields.capitalIncome.label" 
        :curr-value="currentYearIncomeAndTaxation.fields.capitalIncome.value"
        :prev-value="lastYearIncomeAndTaxation.fields.capitalIncome.value"
      />
      <IncomeAndTaxationRow
        :label="currentYearIncomeAndTaxation.fields.capitalDeficit.label" 
        :curr-value="currentYearIncomeAndTaxation.fields.capitalDeficit.value"
        :prev-value="lastYearIncomeAndTaxation.fields.capitalDeficit.value"
      />
      <IncomeAndTaxationRow
        :label="currentYearIncomeAndTaxation.fields.aggregateIncomeAfterDeductionBeforeTax.label" 
        :curr-value="currentYearIncomeAndTaxation.fields.aggregateIncomeAfterDeductionBeforeTax.value"
        :prev-value="lastYearIncomeAndTaxation.fields.aggregateIncomeAfterDeductionBeforeTax.value"
      />
      <IncomeAndTaxationRow
        :label="currentYearIncomeAndTaxation.fields.finalTax.label" 
        :curr-value="currentYearIncomeAndTaxation.fields.finalTax.value"
        :prev-value="lastYearIncomeAndTaxation.fields.finalTax.value"
      />
      <IncomeAndTaxationRow
        v-if="soleTrader"
        :label="currentYearIncomeAndTaxation.fields.activeBusinessActivityDeficiency.label" 
        :curr-value="currentYearIncomeAndTaxation.fields.activeBusinessActivityDeficiency.value"
        :prev-value="lastYearIncomeAndTaxation.fields.activeBusinessActivityDeficiency.value"
      />
      <IncomeAndTaxationRow
        v-if="soleTrader"
        :label="currentYearIncomeAndTaxation.fields.passiveBusinessIncome.label" 
        :curr-value="currentYearIncomeAndTaxation.fields.passiveBusinessIncome.value"
        :prev-value="lastYearIncomeAndTaxation.fields.passiveBusinessIncome.value"
      />
    </div>
  </div>
</template>
<script setup>
import { computed } from 'vue';
import { getIncomeAndTaxationModel } from './incomeAndTaxationUtils';
import IncomeAndTaxationRow from './IncomeAndTaxationRow.vue';

const props = defineProps({
  ctiConsumerIncomeAndTaxation: Object,
  soleTrader: Boolean,
});

const currentYearIncomeAndTaxation = computed(() => getIncomeAndTaxationModel({incomeAndTaxation: props.ctiConsumerIncomeAndTaxation.incomeAndTaxation[0], showMissingValue: true }));
const lastYearIncomeAndTaxation = computed(() => getIncomeAndTaxationModel({incomeAndTaxation: props.ctiConsumerIncomeAndTaxation.incomeAndTaxation[1], showMissingValue: false }));
</script>