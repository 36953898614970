import $ from 'jquery';
import { showErrorToast } from './_toastrhandling.js';
import * as connectionhandling from './_connectionhandling.js';
import * as loader from './_loader.js';
$(document).ready(function () {
    $(document.body).on("click", "#orderBack", function () {
        $("#checkout-container-div").html("");
        $('#orderBack').hide();
        $(".purchase-popup-step-1").show();
    });
    $(document.body).on("submit", '#order-layer-form', function (event) {
        event.preventDefault();

        var customerEmail,
            customerPersonNumber,
            customerPhoneNumber;

        // Get selected product packages
        var selected = [];
        var freeUnder21 = true;

        $("input[type='radio'][name='productradio']:checked").each(function () {
            selected.push($(this).attr('id'));
            if (freeUnder21) {
                freeUnder21 = $(this).attr('data-freeUnder21') === "True";
            }

        });

        $('.offers input[type="checkbox"]').filter(':checked').each(function () {
            selected.push($(this).attr('id'));
            if (freeUnder21) {
                freeUnder21 = $(this).attr('data-freeUnder21') === "True";
            }
        });

        // CHECK AGE IF < OR > 21
        var personalIdentityNumberToAge = function (personalIdentityNumber) {
            var birthDate = personalIdentityNumber.substr(0, 8);
            var normalizedBirthDate =
                birthDate.substr(0, 4) + '-' + birthDate.substr(4, 2) + '-' + birthDate.substr(6, 2);
            var normalizedBirthDateObj = new Date(normalizedBirthDate);
            var now = new Date();

            var ageDifMs = now - normalizedBirthDateObj.getTime();
            var ageDate = new Date(ageDifMs);

            return Math.abs(ageDate.getUTCFullYear() - 1970);
        };

        if ($(".form-control").hasClass('is-invalid')) {
            // Nah
        } else {

            customerEmail = $("#orderEmail").val();
            customerPersonNumber = $("#orderPersonId").val();
            customerPhoneNumber = $("#orderPhone").val();
            var age = personalIdentityNumberToAge(customerPersonNumber);

            if (customerEmail !== "" && customerPersonNumber !== "" && customerPhoneNumber !== "") {

                //loader.show();
                $.ajax({
                    type: "POST",
                    url: '/ajax/CustomerApi/CreateCustomer',
                    data: {
                        personNumber: customerPersonNumber,
                        email: customerEmail,
                        phoneNumber: customerPhoneNumber
                    },
                    dataType: "json",
                    success: function (data) {
                        //check if product has free21, then check age, otherwise use normal flow.
                        if (freeUnder21 && age < 21) {
                            freePurchase(data.internalId, selected);
                            window.location = $("input[name=thank-you-page-url]").val();
                        } else {
                            const ppi = $("input[name='productradio']:checked").attr('id');

                            $.post("/customerapi/createpayment", { externalCustomerId: data.externalId, productPackageIds: parseInt(ppi) }, function (response) {

                                $(".purchase-popup-step-1").hide();
                                $("#checkout-container-div").html("");

                                let category = $("input[name='productradio']:checked").attr('product-category');
                                if ($("input[name='productradio']:checked").attr('product-recurringbuy')) {
                                    category = category + "/SUBSCRIPTION";
                                }
                                else {
                                    category = category + "/ONDEMAND";
                                }
                                let price = $("input[name='productradio']:checked").attr('price');
                                window.dataLayer = window.dataLayer || [];
                                window.dataLayer.push({
                                    'event': 'checkout',
                                    'ecommerce': {
                                        'checkout': {
                                            'actionField': { 'step': 1 },
                                            'products': [{
                                                'name': $("input[name='productradio']:checked").attr('product-name'),
                                                'id': ppi,
                                                'price': price != null ? parseFloat(price):'',
                                                'category': category, // null
                                                'quantity': 1,
                                                'variant': 'UC'
                                            }]
                                        }
                                    }
                                });

                                const paymentId = response.paymentId;
                                if (paymentId) {
                                    const checkoutOptions = {
                                        checkoutKey: $('#netscheckoutkey').val(),
                                        paymentId: paymentId,
                                        containerId: "checkout-container-div",
                                        language: "sv-SE"
                                    };
                                    const checkout = new window.Dibs.Checkout(checkoutOptions);
                                    checkout.on('payment-completed', function () {
                                        window.location = '/bekraftelse?ppid=' + parseInt(ppi) + '&pid=' + paymentId;
                                    });
                                }
                            }).fail(function (response) {
                                loader.hide();
                                const ppi = $("input[name='productradio']:checked").attr('id');
                                showErrorMessage(ppi, response.status);
                            });
                        }
                    },
                    error: function () {
                        loader.hide();
                        const ppi = $("input[name='productradio']:checked").attr('id');
                        showErrorMessage(ppi, 500);
                    }
                });
            }
        }
    });

    $(document.body).on("click",
        ".conditions :checkbox",
        function () {
            var n = $(".conditions input:checked").length;
            if (n < 2) {
                $("#register-customer-btn").addClass("disabled");
            }
            else {
                $("#register-customer-btn").removeClass("disabled");
            }
            //if ($(".conditions.datamonitor-condition input:checked")) {
            //    $(".btn-datamonitor").removeClass("disabled");
            //    $(".btn-datamonitor").removeClass("disabled-custom");
            //}
            //else {
            //    $(".btn-datamonitor").addClass("disabled");
            //    $(".btn-datamonitor").addClass("disabled-custom");
            //}
        });

    //$(document.body).on("click",
    //    ".conditions.datamonitor-condition :checkbox",
    //    function (event) {
    //        if ($(".conditions.datamonitor-condition #conditionscheck").is(':checked')) {
    //            $(".btn-datamonitor").removeClass("disabled");
    //            $(".btn-datamonitor").removeClass("disabled-custom");
    //        }
    //        else {
    //            $(".btn-datamonitor").addClass("disabled");
    //            $(".btn-datamonitor").addClass("disabled-custom");
    //        }
    //    });

    $(document.body).on("click",
        "#register-customer-btn",
        function (event) {
            event.preventDefault();
            if ($("#register-customer-btn").hasClass('disabled')) {
                // Nah.
            } else {
                var customerEmail = $("input[name=email]").val();
                var customerPersonNumber = $("input[name=personId]").val();
                var customerPhoneNumber = $("input[name=phone]").val();
                if (customerEmail === "") {
                    customerEmail = $("#orderEmail").val();
                    customerPersonNumber = $("#orderPersonId").val();
                    customerPhoneNumber = $("#orderPhone").val();
                }
                var invitationCode = $("input[name=registration-block-invitation-code]").val();
                if (invitationCode === undefined) {
                    // try to get it from url
                    const params = new Proxy(new URLSearchParams(window.location.search), {
                        get: (searchParams, prop) => searchParams.get(prop),
                    });
                    invitationCode = params.c;
                }
                var digitalMail = $("input[name=digital-mail]").val();
                var loggedIn = $("input[name=logged-in]").val();

                if (loggedIn === "true" && digitalMail === "true") {
                    $('.form').fadeOut();
                    $('.form.step-two').fadeIn(500);

                    connectionhandling.registerDigitalMailbox(0, function (resData) {
                        if (resData.success) {
                            $('.form.step-two').fadeOut(500);
                            $('.form.step-three').fadeIn(500);
                        } else {
                            showErrorToast("Kunde inte registrera Digital Brevlåda. Försök igen senare eller kontakta kundtjänst.");
                        }
                    });
                } else if (loggedIn === "false" && digitalMail === "true") {
                    $('.form').fadeOut();
                    $('.form.step-two').fadeIn(500);
                    $.post("/ajax/CustomerApi/CreateCustomer",
                        {
                            personNumber: customerPersonNumber,
                            email: customerEmail,
                            phoneNumber: customerPhoneNumber,
                            activate: true,
                            invitationCode: invitationCode
                        },
                        function () {
                            registerDigitalMailboxLoggedOut(customerPersonNumber, function (resData) {
                                if (resData.success) {
                                    $('.form.step-two').fadeOut(500);
                                    $('.form.step-three').fadeIn(500);
                                } else {
                                    showErrorToast("Kunde inte registrera Digital Brevlåda. Försök igen senare eller kontakta kundtjänst.");
                                }
                            });
                        }).fail(function (xhr) {
                            showErrorToast(xhr.responseText.replace('""', ''));
                        });
                } else {
                    $.post("/ajax/CustomerApi/CreateCustomer",
                        {
                            personNumber: customerPersonNumber,
                            email: customerEmail,
                            phoneNumber: customerPhoneNumber,
                            activate: true,
                            invitationCode: invitationCode
                        },
                        function () {
                            if (digitalMail === "true") {
                                $('.form').fadeOut();
                                $('.form.step-two').fadeIn();
                                connectionhandling.registerDigitalMailbox(0, function (resData) {
                                    if (resData.success) {
                                        $('.form.step-two').fadeOut(500);
                                        $('.form.step-three').fadeIn(500);
                                    } else {
                                        showErrorToast("Kunde inte registrera Digital Brevlåda. Försök igen senare eller kontakta kundtjänst.");
                                    }
                                });
                            } else {
                                window.location = $("input[name=thank-you-page-url]").val();
                            }
                        }).fail(function (xhr) {
                            showErrorToast(xhr.responseText.replace('""', ''));
                        });
                }
            }
        });

    //$(document.body).on("change",
    //    ".order-layer .product-checkbox",
    //    function (e) {
    //        if ($(this).prop("checked")) {
    //            addToCart(googleProducts[$(this).data("product-index")]);
    //        } else {
    //            removeFromCart(googleProducts[$(this).data("product-index")]);
    //        }
    //    });

    //$(document.body).on("change",
    //    ".order-layer .product-radio",
    //    function (e) {
    //        removeFromCart(oldRadioProduct);
    //        addToCart(googleProducts[$(this).data("product-index")]);
    //        oldRadioProduct = googleProducts[$(this).data("product-index")];
    //    });



    function freePurchase(internalId, productPackageIds) {
        $.post("/ajax/CustomerApi/FreePurchase", { customerId: internalId, productPackageIds: productPackageIds });
    }

    function registerDigitalMailboxLoggedOut(personId, okCallback, failedCallback) {
        $.post("/ajax/CustomerApi/RegisterDigitalMailboxLoggedOut", { personId: personId })
            .done(okCallback)
            .fail(failedCallback);
    }

    function showErrorMessage(productPackage, errorCode) {

        $.ajax({
            type: 'GET',
            url: '/ajax/View/GetErrorView',
            data: {
                productPackage: productPackage,
                errorCode: errorCode
            },
            dataType: 'html',
            success: function (url) {
                $(".order-layer .form").remove();
                $(".order-layer .overlay").remove();
                $('.order-layer').append(url);

                $('#order-overlay').css('background', 'rgba(0,0,0,0.6)');

            },
            error: function () {
            }
        });
        $("#order-layer").fadeIn();
    }

});