<template>
  <div
    class="row"
    data-testid="digital-mail-box-messages"
  >
    <div
      v-if="messages?.length > 0"
      class="col-12"
    >
      <div
        id="digitalmailbox-table"
        class="col-12 dashboard__table mb-2"
      >
        <div class="row head border-bottom border-color-nightrider">
          <div class="col-4 col-md-3">
            <span class="color-nightrider font-weight-medium">Datum</span>
          </div>
          <div class="col-8 col-md-9">
            <span class="color-nightrider font-weight-medium">Företag</span>
          </div>
        </div>
        <template
          v-for="(message, index) in messagesChunk"
          :key="`digital_mail_box_message_${index}`"
        >
          <div :class="notRead(message.unread) + ' row data-row'">
            <div class="col-4 col-md-3">
              {{ formatDateTime(message.eventDate) }}
            </div>
            <div class="col-8 col-md-9">
              <a
                target="_blank"
                :href="`/mina-tjanster/digital-brevlada/GetDMFile?dmId=${message.digitalMailboxId}&fileName=${message.inquirers}&date=${toShortDateString(message.eventDate)}`"
              >
                <i class="far fa-file-pdf mr-2" />{{ message.inquirers }}</a>
            </div>
          </div>
        </template>
      </div>
    </div>
    <div
      v-else
      class="col-12"
    >
      <span
        class="color-nightrider font-weight-medium"
        data-testid="digital-brevlada-page-no-messages"
      >
        Du har inga meddelanden!
      </span>
    </div>
    <Paging
      v-if="messages != null && messages.length > 0"
      :items="messages"
      :items-per-page="itemsPerPage"
      :paging-enabled="pagingEnabled"
      @chunk="showSpan"
    />
  </div>
</template>
<script setup>
import { ref } from 'vue';
import Paging from './Paging.vue';

defineProps({
    messages: Array,
    pagingEnabled: Boolean,
    itemsPerPage: Number
});

const messagesChunk = ref([]);

const notRead = (unread) => unread ? "not-read" : "even";

const formatDateTime = (date) => {
    const re = /-?\d+/;
    const m = re.exec(date);
    const d = new Date(parseInt(m[0]));
    return d.toLocaleDateString('sv-SE');
};

const toShortDateString = (date) => {
    const re = /-?\d+/;
    const m = re.exec(date);
    return m[0];
};

const showSpan = (chunk) => {
    messagesChunk.value = chunk;
};
</script>
<style lang="scss" scoped>
@import "@/../../styles/abstracts/_variables.scss";

.dashboard__table {

    .row.data-row div:last-child {
        text-align: left;

        a {
            text-decoration: underline;
        }
    }

    .not-read {
        background-color: $rodeo-dust;

        a {
            font-weight: 500;
        }
    }
}
</style>