export const defaultGenericErrorModel = {
    errorTitle: 'Ett fel har inträffat!',
    errorText: '<p>Ett tekniskt fel har inträffat, vänligen kontakta kundservice så hjälper vi dig! <a class="enento__link" href="/kundservice">Kontakta oss</a></p>',
    errorTextLoggedIn: '',
    errorAlreadyBoughtCtaText: '',
    errorAlreadyBoughtCtaUrl: '',
};

export const policeReportErrorModel = {
    errorTitle: 'Ett fel har inträffat!',
    errorText: "<p>Din polisrapport kunde tyvärr inte laddas upp, vänligen försök igen.</p> <p>Kontrollera att filstorleken inte överstiger 100 mb och att filen laddas upp i formatet: pdf, tiff, png, gif, jpg eller jpeg.</p><p>Om problemet kvarstår, vänligen kontakta <a href='mailto:utredningen@uc.se' target='_blank'>utredningen@uc.se</a></p>",
    errorTextLoggedIn: '',
    errorAlreadyBoughtCtaText: '',
    errorAlreadyBoughtCtaUrl: '',
};

export const policeReportSuccessModel = {
    errorTitle: 'Polisanmälan uppladdad',
    errorText: "Vi kontrollerar din polisanmälan och meddelar dig via sms och e-post när den blivit godkänd eller om du behöver komplettera.",
    errorTextLoggedIn: '',
    errorAlreadyBoughtCtaText: '',
    errorAlreadyBoughtCtaUrl: '',
};

export const changeCardModelLoading = {
    errorTitle: 'Byt kort',
    iconClass: 'far fa-credit-card',
    isLoading: true,
    errorText: '',
    errorTextLoggedIn: '',
    errorAlreadyBoughtCtaText: '',
    errorAlreadyBoughtCtaUrl: '',
};

export const changeCardModel = {
    errorTitle: 'Byt kort',
    iconClass: 'far fa-credit-card',
    isLoading: false,
    errorText: '',
    errorTextLoggedIn: '',
    errorAlreadyBoughtCtaText: '',
    errorAlreadyBoughtCtaUrl: '',
};

export const creditReportError = {
    errorTitle: 'Ett fel har inträffat!',
    errorText: '<p>Tjänsten är för närvarande inte tillgänglig. Vänligen kontakta kundservice på telefon <a class="enento__link" href="tel:0771-20 22 77">0771-20 22 77</a> eller skicka ett e-post till <a class="enento__link" href="mailto:minuc@uc.se">minuc@uc.se</a>. Ange kod 500.</p><p>&nbsp;</p><p>Kundservice öppettider:</p><p>mån - fre 10:00 - 15:00</p>',
    errorTextLoggedIn: '',
    errorAlreadyBoughtCtaText: '',
    errorAlreadyBoughtCtaUrl: '',
};