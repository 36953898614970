import { createStore } from 'vuex';
import { articlesContex } from './modules/articlesContext';
import { epiDataModel } from './modules/epiDataModel';
import { epiContext } from './modules/epiContext';
import { appContext } from './modules/appContext';
import { customerInfoContex } from './modules/customerInfoContex';
import { fraudBarrierContex } from './modules/fraudBarrierContex';
import { customerCreditInfoContex } from './modules/customerCreditInfoContex';
import { customerCreditNotificationsContex } from './modules/customerCreditNotificationsContex';
import { umbrellaMembersContext } from './modules/umbrellaMembersContext';
import { checkoutContext } from './modules/checkoutContext';
import { PERSIST_STORE_KEY } from './storeUtils';
import VuexPersistence from 'vuex-persist';

const vuexLocal = new VuexPersistence({
    key: PERSIST_STORE_KEY,
    storage: window.sessionStorage,
});

const store = createStore({
    modules: {
        appContext,
        articlesContex,
        epiDataModel,
        epiContext,
        customerInfoContex,
        fraudBarrierContex,
        customerCreditInfoContex,
        customerCreditNotificationsContex,
        umbrellaMembersContext,
        checkoutContext,
    },
    plugins: [vuexLocal.plugin]
});

export default store;
