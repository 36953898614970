<template>
  <VueFinalModal
    class="enroll-modal-container"
    content-class="enroll-modal-content flex flex-col bg-white dark:bg-black rounded border border-gray-100 dark:border-gray-800"
    data-testid="enroll-modal"
  >
    <div class="enroll-modal-header d-flex align-items-baseline">
      <h3
        id="info-modal-label"
        class="enroll-modal-title"
      >
        <i class="far fa-globe fa-fw color-nightrider fa-h3-size mr-2" />
        {{ model.title }}
      </h3>
      <i
        class="far fa-times hover-pointer error-close"
        aria-label="Close"
        @click="emit('confirm')"
      />
    </div>
    <div class="modal-content-wrapper">
      <div
        class="enroll-modal-body"
        v-html="model.text"
      />
      <form role="form">
        <div class="col-md-6 form-group order-3 order-md-2">
          <label for="email">E-postadress</label>
          <input
            id="email"
            v-model="form.customerEmail"
            type="text"
            class="form-control"
            :class="v$.customerEmail.$error ? 'is-invalid' : ''"
            placeholder="E-postadress"
            @keyup="onDataChange"
          >
          <small
            v-if="v$.customerEmail.email.$invalid"
            class="is-invalid"
          >
            {{ v$.customerEmail.email.$message }}
          </small>
          <small
            v-else-if="v$.customerEmail.required.$invalid"
            class="is-invalid"
          >
            {{ v$.customerEmail.required.$message }}
          </small>
        </div>

        <div class="col-md-8 form-group order-3 order-md-2 form-check">
          <div class="form-check">
            <input
              id="conditionsCheck"
              v-model="form.conditionsCheck"
              type="checkbox"
              class="form-check-input"
              @click="onDataChange"
            >
            <label
              class="form-check-label"
              for="conditionsCheck"
            >
              <span>Godkänn</span>
              <a
                :href="model.modalConditionsLink"
                class="ml-1"
                target="_blank"
              >villkor</a>
            </label>
          </div>
        </div>
      </form>
    </div>
    <div class="enroll-modal-footer">
      <button
        type="button"
        class="btn btn-enento-blue"
        :disabled="v$.$invalid"
        @click="onSubmit"
      >
        {{
          model.confirmLabel || 'stäng' }}
      </button>
    </div>
  </VueFinalModal>
</template>
<script setup>
import { VueFinalModal } from 'vue-final-modal';
import { useStore } from 'vuex';
import { computed, ref, reactive } from 'vue';
import { useVuelidate } from '@vuelidate/core'
import { required, email, helpers, sameAs } from '@vuelidate/validators';
import {
  CUSTOMER_INFO_ENROLL_ONLINE_MONITORING,
} from '../../Scripts/store/modules/customerInfoContex';

const props = defineProps({
  model: Object,
});

const store = useStore();
const dataChanged = ref(false);
const customerEssentials = computed(() => store.state.customerInfoContex.customerEssentials);


const form = reactive({
  customerEmail: customerEssentials.value.customerEmail,
  conditionsCheck: false,
});

const rules = computed(() => {
  return {
    customerEmail: {
      required: helpers.withMessage('Vänligen fyll i detta fält', required),
      email: helpers.withMessage('Vänligen fyll i en giltig e-postadress', email)
    },
    conditionsCheck: {
      sameAsRawValue: sameAs(true),
    },
  }
});

const v$ = useVuelidate(rules, form);

const onDataChange = () => {
  if (!v$.value.$error) {
    dataChanged.value = true;
  } else {
    dataChanged.value = false;
  }
};

const emit = defineEmits(['confirm']);

const onSubmit = () => {
  store.dispatch(CUSTOMER_INFO_ENROLL_ONLINE_MONITORING, {
    monitoringBlockId: props.model.monitoringBlockId,
    email: customerEssentials.value.customerEmail,
  });

  emit('confirm');
}      
</script>
<style lang="scss">
@import "@/../../styles/abstracts/_mixins.scss";
@import "@/../../styles/abstracts/_variables.scss";
@import "@/../../styles/abstracts/_breakpoints.scss";

.modal-content-wrapper {
  overflow-y: auto;
  max-height: calc(100vh - 225px);
}


.enroll-modal-container {
  display: flex;
  justify-content: center;
  align-items: center;
}

.enroll-modal-content {
  background-color: #fff;
  width: 100%;
  position: relative;
  display: flex;
  flex-direction: column;
  pointer-events: auto;
  margin: 1.25rem auto;

  @include breakpoint(small-phone) {
    max-width: 346px;
  }

  @include breakpoint(tablet-portrait) {
    max-width: 450px;
  }

  @include breakpoint(large-desktop) {
    max-width: 528px;
  }

  .enroll-modal-header {
    display: flex;
    align-items: flex-start;
    justify-content: space-between;
    padding: 1rem 1rem;

    .close {
      padding: 1rem 1rem;
      margin: -1rem -1rem -1rem auto;
    }

    .enroll-modal-title {
      margin-bottom: 0;
      line-height: 1.5;
    }

    i {
      font-size: 24px;
      color: #333;
    }
  }

  .enroll-modal-body {
    position: relative;
    flex: 1 1 auto;
    padding: 1rem;
  }

  .enroll-modal-footer {
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    justify-content: flex-end;
    padding: 0.75rem;
  }
}
</style>
