<!--
    Landing page for the site.
    When the BuyTicketBlock modal is shown, the OPE overlays in the Hero are turned off through the `showModal` data property.

    View for Models/Pages/LandingPage.cs
-->

<template>
  <div
    v-if="articles?.length > 0"
    class="financial-listing-page financial-listing-page-container p-3"
  >
    <div class="row row-grid related-articles-section">
      <div class="col-3 col-md-4">
        <hr>
      </div>
      <div
        class="col-6 col-md-4 section-header"
        style="text-align:center;"
      >
        <span>Relaterade artiklar</span>
      </div>
      <div class="col-3 col-md-4">
        <hr>
      </div>
    </div>
    <div
      id="article-list-grid"
      class="row"
    >
      <div
        v-for="(article, index) in articles"
        :key="`related_article_list_${index}`"
        class="col-12 col-lg-4 card-container"
      >
        <RouterLink
          :to="article.url.replace('https://www1.minuc.se', '').replace('https://www.minuc.se', '').replace('https://minuc.se', '')"
          class="card-link"
        >
          <div class="card">
            <img :src="article.topImage.value.url">
            <div class="card-content">
              <h5 v-if="article.headline.value != ''">
                {{ article.headline.value }}
              </h5>
              <h5 v-else>
                {{ article.name }}
              </h5>
              <div
                v-if="article.selectedTags != null"
                class="tag-label-container"
              >
                <span
                  v-for="(tag, index) in article.selectedTags.value.split(',')"
                  :key="`related_article_tag_${index}`"
                  class="tag-label"
                >{{ tag }}</span>
              </div>
            </div>
          </div>
        </RouterLink>
      </div>
    </div>
  </div>
</template>

<script>
import $ from 'jquery';

export default {
    components: {

    },
    props: {
        articles: Array
    },
    created: function () {
    },
    mounted: function () {
        $('.site-content').removeClass('myucscorepage');
    },
    methods: {
        Redirect: function (url) {
            window.location.href = url;
        }
    }
};
</script>
