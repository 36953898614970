export default {
    orderPersonId: {
        required: true,
        ssn: true
    },
    orderEmail: {
        required: true,
        email: true
    },
    orderPhone: {
        required: true,
        phone: true
    }
}