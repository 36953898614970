<template>
  <div
    v-if="showReportOverlay"
    class="customerreport-layer"
    data-testid="customer-report"
  >
    <div
      class="report row"
      data-testid="min-upplysning-report"
    >
      <h2>Min Upplysning</h2>
      <span
        class="close"
        @click="closeReport"
      >Stäng</span>
      <a
        href="/ajax/Customerapi/GetCustomerReport?print=true"
        target="_blank"
        class="print-link"
      >
        Skriv ut /
        Spara som PDF
      </a>
      <div class="iframe-container">
        <iframe src="/ajax/Customerapi/GetCustomerReport" />
      </div>
    </div>
  </div>
</template>
<script setup>
import $ from 'jquery';
import { useStore } from 'vuex';
import { computed } from 'vue';
import { APP_HIDE_REPORT } from '../Scripts/store/modules/appContext';

const store = useStore();

const showReportOverlay = computed(() => store.state.appContext.showReportOverlay);

const closeReport = () => {
    store.dispatch(APP_HIDE_REPORT);
    const $overlay = $('.overlay');
    $overlay.hide();
};
</script>
<style lang="scss" scoped>
@import "@/../../styles/abstracts/_breakpoints.scss";

.iframe-container {
    margin: 15px 0;
    -webkit-overflow-scrolling: touch;
    overflow-y: hidden;
    width: 100%;

    @include breakpoint(max-tablet-portrait) {
        height: 300px;
    }

    @include breakpoint(tablet-portrait) {
        height: 700px;
    }

    iframe {
        width: 100%;
        height: 100%;
        border: none;
    }
}
</style>