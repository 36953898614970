import { trackProductClick, trackAddToCart } from '../../../utils/ecommercetrackingUtils';
import { postProductPackage } from '../../providers/frontendApiControllerProviders';
import { CUSTOMER_INFO_FETCH, CUSTOMER_INFO_PRODUCTS_RESET, } from './customerInfoContex';
import { APP_FETCH_SIDEBAR_MENU, } from './appContext';
import { UMBRELLA_MEMBERS_SET_RESET, } from './umbrellaMembersContext';
import router from '../../router';

const CHECKOUT = 'CHECKOUT';
export const CHECKOUT_SELECT_STEP = `${CHECKOUT}/SELECT_STEP`;
export const CHECKOUT_SELECT_PRODUCT = `${CHECKOUT}/SELECT_PRODUCT`;
export const CHECKOUT_SELECT_PREVIOUS_STEP = `${CHECKOUT}/SELECT_PREVIOUS_STEP`;
export const CHECKOUT_SET_CUSTOMER_ID = `${CHECKOUT}/SET_CUSTOMER_ID`;
export const CHECKOUT_SET_CUSTOMER = `${CHECKOUT}/SET_CUSTOMER`;
export const CHECKOUT_INIT = `${CHECKOUT}/INIT`;
export const CHECKOUT_PRODUCT_PACKAGES_FETCH = `${CHECKOUT}/PRODUCT_PACKAGES_FETCH`;
const CHECKOUT_UPDATE_STEP = `${CHECKOUT}/UPDATE_STEP`;
const CHECKOUT_UPDATE_PRODUCT = `${CHECKOUT}/UPDATE_PRODUCT`;
const CHECKOUT_UPDATE_CUSTOMER_ID = `${CHECKOUT}/UPDATE_CUSTOMER_ID`;
const CHECKOUT_UPDATE_CUSTOMER = `${CHECKOUT}/UPDATE_CUSTOMER`;
const CHECKOUT_PRODUCT_PACKAGE_INFORMATION_UPDATE = `${CHECKOUT}/PRODUCT_PACKAGE_INFORMATION_UPDATE`;
const CHECKOUT_PRODUCT_PACKAGE_INFORMATION_PENDING = `${CHECKOUT}/PRODUCT_PACKAGE_INFORMATION_PENDING`;
const CHECKOUT_PRODUCT_PACKAGE_INFORMATION_FAILED = `${CHECKOUT}/PRODUCT_PACKAGE_INFORMATION_FAILED`;
const CHECKOUT_UPDATE_SELECT_PREVIOUS_STEP = `${CHECKOUT}/UPDATE_SELECT_PREVIOUS_STEP `;

const relatedProductsIdsToString = (relatedProductsIds) => `${relatedProductsIds.join()}`;

export const CHECKOUT_STEP = {
    SELECT_PRODUCT: 0,
    SELECT_PRODUCT_COMPLETE: 1,
    ENTER_INFORMATION: 2,
    PAYMENT: 3,
    COMPLETE: 4,
};

export const ACTIVE_TAB = {
    SELECT_PRODUCT_TAB: 1,
    ENTER_INFORMATION_TAB: 2,
    PAYMENT_TAB: 3,
    COMPLETE_TAB: 4,
};

const state = {
    step: CHECKOUT_STEP.SELECT_PRODUCT,
    activeTab: ACTIVE_TAB.SELECT_PRODUCT_TAB,
    selectedProduct: null,
    hasProvidedAndConfirmedCustomer: false,
    externalCustomerId: null,
    customer: {
        email: '',
        ssn: '',
        phoneNumber: '',
    },
    productPackageId: null,
    relatedProductsIds: null,
    relatedProducts: null,
    productPackageInformation: null,
    productPackageInformationPending: false,
    productPackageInformationFailed: false,
    productPackageInformationLoaded: false,
};

const mutations = {
    [CHECKOUT_UPDATE_STEP](state, newModel) {
        state.step = newModel.step;
        switch (newModel.step) {
            case CHECKOUT_STEP.SELECT_PRODUCT:
                state.hasProvidedAndConfirmedCustomer = false;
                state.activeTab = ACTIVE_TAB.SELECT_PRODUCT_TAB;
                break;
            case CHECKOUT_STEP.ENTER_INFORMATION:
                state.hasProvidedAndConfirmedCustomer = false;
                state.activeTab = ACTIVE_TAB.ENTER_INFORMATION_TAB;
                break;
            case CHECKOUT_STEP.PAYMENT:
                state.hasProvidedAndConfirmedCustomer = true;
                state.activeTab = ACTIVE_TAB.PAYMENT_TAB;
                break;
            case CHECKOUT_STEP.COMPLETE:
                state.activeTab = ACTIVE_TAB.COMPLETE_TAB;
                break;
            default:
                state.activeTab = ACTIVE_TAB.SELECT_PRODUCT_TAB;
        }
    },
    [CHECKOUT_UPDATE_PRODUCT](state, newModel) {
        state.selectedProduct = newModel.product;
    },
    [CHECKOUT_UPDATE_CUSTOMER_ID](state, newModel) {
        state.externalCustomerId = newModel.externalCustomerId;
    },
    [CHECKOUT_UPDATE_CUSTOMER](state, newModel) {
        state.customer = {
            email: newModel.email,
            ssn: newModel.ssn,
            phoneNumber: newModel.phoneNumber,
        };
    },
    [CHECKOUT_UPDATE_SELECT_PREVIOUS_STEP](state) {
        if (state.activeTab === ACTIVE_TAB.PAYMENT_TAB) {
            state.activeTab = ACTIVE_TAB.ENTER_INFORMATION_TAB;
            state.step = CHECKOUT_STEP.PAYMENT;
        } else {
            state.activeTab = ACTIVE_TAB.SELECT_PRODUCT_TAB;
            state.step = CHECKOUT_STEP.ENTER_INFORMATION;
        }
    },
    [CHECKOUT_PRODUCT_PACKAGE_INFORMATION_PENDING](state) {
        state.productPackageInformation = null;
        state.productPackageId = null;
        state.relatedProductsIds = null;
        state.relatedProducts = null;
        state.productPackageInformationPending = true;
        state.productPackageInformationFailed = false;
        state.productPackageInformationLoaded = false;
    },
    [CHECKOUT_PRODUCT_PACKAGE_INFORMATION_UPDATE](state, newModel) {
        state.productPackageInformation = newModel.productPackageInformation;
        state.productPackageId = newModel.productPackageId;
        state.relatedProductsIds = relatedProductsIdsToString(newModel.relatedProductsIds);
        state.relatedProducts = newModel.relatedProducts;
        state.productPackageInformationPending = false;
        state.productPackageInformationFailed = false;
        state.productPackageInformationLoaded = true;
    },
    [CHECKOUT_PRODUCT_PACKAGE_INFORMATION_FAILED](state) {
        state.productPackageInformation = null;
        state.productPackageId = null;
        state.relatedProductsIds = null;
        state.relatedProducts = null;
        state.productPackageInformationPending = false;
        state.productPackageInformationFailed = true;
        state.productPackageInformationLoaded = true;
    },
};

const actions = {
    [CHECKOUT_SELECT_STEP]({ commit, dispatch, state }, { step, isLoggedin, isContinuingWithUpsellProduct }) {
        if (step === CHECKOUT_STEP.ENTER_INFORMATION) {
            if(state.productPackageId !== state.selectedProduct.productPackage.id) {
                const url = state.relatedProducts.find(({id}) => id === state.selectedProduct.productPackage.id)?.url;
                if(url) {
                    router.push({ path: url });
                    dispatch(CHECKOUT_PRODUCT_PACKAGES_FETCH, { productPackageId: state.selectedProduct.productPackage.id, relatedProductsIds: [] });
                }
            }
            if(!isContinuingWithUpsellProduct) {
                trackAddToCart({ product: state.selectedProduct });
            }
        }
        if (step === CHECKOUT_STEP.COMPLETE && isLoggedin) {
            dispatch(CUSTOMER_INFO_FETCH, { reFetch: true });
            dispatch(CUSTOMER_INFO_PRODUCTS_RESET);
            dispatch(UMBRELLA_MEMBERS_SET_RESET);
            dispatch(APP_FETCH_SIDEBAR_MENU);
        }
        return commit(CHECKOUT_UPDATE_STEP, { step });
    },
    [CHECKOUT_SELECT_PRODUCT]({ commit }, { product }) {
        trackProductClick({ product });
        return commit(CHECKOUT_UPDATE_PRODUCT, { product });
    },
    [CHECKOUT_SELECT_PREVIOUS_STEP]({ commit }) {
        return commit(CHECKOUT_UPDATE_SELECT_PREVIOUS_STEP);
    },
    [CHECKOUT_SET_CUSTOMER_ID]({ commit }, { externalCustomerId }) {
        return commit(CHECKOUT_UPDATE_CUSTOMER_ID, { externalCustomerId });
    },
    [CHECKOUT_SET_CUSTOMER]({ commit }, { email, ssn, phoneNumber }) {
        return commit(CHECKOUT_UPDATE_CUSTOMER, { email, ssn, phoneNumber });
    },
    [CHECKOUT_INIT]({ state, dispatch }, { productPackageId, relatedProductsIds, relatedProducts }) {
        if (state.productPackageId !== productPackageId || state.relatedProductsIds !== relatedProductsIdsToString(relatedProductsIds)) {
            return dispatch(CHECKOUT_PRODUCT_PACKAGES_FETCH, { productPackageId, relatedProductsIds, relatedProducts });
        }
        return;
    },
    [CHECKOUT_PRODUCT_PACKAGES_FETCH]({ commit }, { productPackageId, relatedProductsIds, relatedProducts }) {
        commit(CHECKOUT_PRODUCT_PACKAGE_INFORMATION_PENDING);
        return postProductPackage({ productPackageId, relatedProductsIds }).then(response => {
            commit(CHECKOUT_PRODUCT_PACKAGE_INFORMATION_UPDATE, { productPackageInformation: response.data, productPackageId, relatedProductsIds, relatedProducts });
        }).catch(() => {
            commit(CHECKOUT_PRODUCT_PACKAGE_INFORMATION_FAILED);
        });
    },
};

export const checkoutContext = {
    state,
    mutations,
    actions,
};
