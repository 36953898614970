<template v-if="ctiConsumerProperties != null">
  <div data-testid="minupplysning-consumerproperties-tab">
    <h4 class="mb-3">
      Fastigheter
    </h4>
    <div class="row">
      <div
        v-for="(property, index) in ctiConsumerProperties.consumerPropertyDetails"
        id="consumerproperties-table"
        :key="`consumer_properties_details_${index}`"
        :class="HelperColumns(ctiConsumerProperties.consumerPropertyDetails)"
        data-testid="min-upplysning-properties-table"
      >
        <div class="dashboard__table">
          <div class="dashboard__table-header">
            <div class="dashboard__table-column">
              <h5 class="mb-0">
                {{ property.propertyName }}
              </h5>
            </div>
          </div>
          <div class="dashboard__table__block-wrapper">
            <div class="col-12">
              <p class="mb-0">
                <span class="dashboard__table__block">Kommun</span>
                <span
                  class="dashboard__table__block"
                  data-testid="min-upplysning-properties-table-kommun"
                >{{ property.municipality }}</span>
              </p>
            </div>
          </div>
          <div class="dashboard__table__block-wrapper">
            <div class="col-12">
              <p class="mb-0">
                <span class="dashboard__table__block">Fastighetstyp</span>
                <span
                  class="dashboard__table__block"
                  data-testid="min-upplysning-properties-table-propertytype"
                >{{ property.propertyTypeText }}</span>
              </p>
            </div>
          </div>
          <div class="dashboard__table__block-wrapper">
            <div class="col-12">
              <p class="mb-0">
                <span class="dashboard__table__block">Areal</span>
                <span
                  class="dashboard__table__block"
                  data-testid="min-upplysning-properties-table-areal"
                >{{ NumberFormat(property.areal) }} {{ property.arealTypeText }}</span>
              </p>
            </div>
          </div>
          <div class="dashboard__table__block-wrapper">
            <div class="col-12">
              <p class="mb-0">
                <span class="dashboard__table__block">Ägarandel</span>
                <span
                  class="dashboard__table__block"
                  data-testid="min-upplysning-properties-table-ownedshare"
                >{{ property.ownedShare }}%</span>
              </p>
            </div>
          </div>
          <div
            v-if="property.purchaseDate != null"
            class="dashboard__table__block-wrapper"
          >
            <div class="col-12">
              <p class="mb-0">
                <span class="dashboard__table__block">Förvärvsdatum</span>
                <span
                  class="dashboard__table__block"
                  data-testid="min-upplysning-properties-table-purchased-date"
                >{{
                  new Date(property.purchaseDate.timeStamp.value).toLocaleDateString('sv-SE')
                }}</span>
              </p>
            </div>
          </div>
          <div class="dashboard__table__block-wrapper">
            <div class="col-12">
              <p class="mb-0">
                <span class="dashboard__table__block">Taxeringsvärde</span>
                <span
                  class="dashboard__table__block"
                  data-testid="min-upplysning-properties-table-value"
                >{{ NumberFormat(property.taxAssessedValue) }} 000 kr</span>
              </p>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import {FormatDate, NumberFormat} from '../_helpers.js';

export default {
  name: 'ConsumerProperties',
  props: {
    ctiConsumerProperties: Object,
  },
  data: function () {
    return {}
  },
  methods: {
    FormatDate: function (timestamp) {
      return FormatDate(timestamp);
    },
    NumberFormat: function (num) {
      return NumberFormat(num);
    },
    HelperColumns: function (property) {
      if (property.length === 1) {
        return "col-lg-12";
      } else {
        return "col-lg-6";
      }
    }
  }
}
</script>