<template>
  <form
    id="other-form"
    ref="formRef"
    :action="model.formUrl"
    method="POST"
  >
    <div class="form-group">
      <label for="name">{{ formModel.name.label }}</label>
      <input
        id="name"
        v-model="form.name"
        type="text"
        class="form-control"
        :placeholder="formModel.name.placeholder" 
        name="name"
        :aria-describedby="formModel.ariaLabel"
        @keyup="onDataChange"
      >
    </div>
    <div class="form-group">
      <label for="ssid">{{ formModel.ssid.label }}</label>
      <input
        id="ssid"
        v-model="form.ssid"
        type="text"
        class="form-control"
        :class="v$.ssid.$error ? 'is-invalid' : ''" 
        :name="model.ssnKey"
        :placeholder="formModel.ssid.placeholder"
        :aria-describedby="formModel.ssid.ariaLabel"
        @keyup="onDataChange"
      >
      <small
        v-if="v$.ssid.required.$invalid && dataChanged"
        class="is-invalid"
      >
        {{ v$.ssid.required.$message }}
      </small>
      <small
        v-else-if="v$.ssid.valid_cord.$invalid && dataChanged"
        class="is-invalid"
      >
        {{ v$.ssid.valid_cord.$message }}
      </small>
      <small
        v-else-if="v$.ssid.valid_ssid.$invalid && dataChanged"
        class="is-invalid"
      >
        {{ v$.ssid.valid_ssid.$message }}
      </small>
    </div>
    <div class="form-group">
      <label for="email">{{ formModel.email.label }}</label>
      <input
        id="email"
        v-model="form.email"
        type="text"
        class="form-control"
        :class="v$.email.$error ? 'is-invalid' : ''"
        :placeholder="formModel.email.placeholder"
        :aria-describedby="formModel.email.ariaLabel" 
        name="email"
        @keyup="onDataChange"
      >
      <small
        v-if="v$.email.required.$invalid && dataChanged"
        class="is-invalid"
      >
        {{ v$.email.required.$message }}
      </small>
      <small
        v-else-if="v$.email.email.$invalid && dataChanged"
        class="is-invalid"
      >
        {{ v$.email.email.$message }}
      </small>
    </div>
    <div class="form-group">
      <label for="description">{{ formModel.description.label }}</label>
      <textarea
        id="description"
        v-model="form.description"
        type="text"
        class="form-control"
        rows="3"
        :class="v$.description.$error ? 'is-invalid' : ''"
        :placeholder="formModel.description.placeholder"
        :aria-describedby="formModel.description.ariaLabel"
        @keyup="onDataChange"
      />
      <small
        v-if="v$.description.required.$invalid && dataChanged"
        class="is-invalid"
      >
        {{ v$.description.required.$message }}
      </small>
    </div>
    <!-- Hidden content -->
    <input
      type="hidden"
      name="description"
      :value="formatedDescription"
    >
    <input
      type="hidden"
      name="orgid"
      :value="model.orgId"
    >
    <input
      type="hidden"
      name="retURL"
      :value="model.paymentRetUrl"
    >
    <input
      type="hidden"
      name="reason"
      value="Invoicing"
    >
    <input
      type="hidden"
      name="subject"
      value="Betalning"
    >
    <input
      type="hidden"
      name="priority"
      value="Medium"
    >
    <input
      type="hidden"
      name="recordType"
      :value="model.recordType"
    >
    <input
      type="hidden"
      :name="model.countryKey"
      value="SE"
    >
    <input
      type="hidden"
      name="origin"
      value="Web-Betalning"
    >
    <input
      type="hidden"
      class="captcha_settings"
      name="captcha_settings"
      :value="captchaSettings"
    >
    <input
      type="hidden"
      class="g-recaptcha-response"
      name="g-recaptcha-response"
      :value="recaptchaToken"
    >
    <input
      v-if="model.recaptchaAPIKey"
      id="recaptchaAPIKey"
      type="hidden"
      :value="model.recaptchaAPIKey"
    >
    <!-- end Hidden content -->
    <template v-if="model.integrityReadMoreLink">
      <div class="form-check">
        <input
          id="integrityCheck"
          v-model="form.integrityCheck"
          type="checkbox"
          class="form-check-input"
          @click="onDataChange"
        >
        <label
          class="form-check-label"
          for="integrityCheck"
        >
          <a
            :href="model.IntegrityReadMoreLink"
            target="_blank"
          >Jag har läst integritetspolicyn</a>
        </label>
      </div><span class="required-label"><small><i>* Obligatoriska fält och måste fyllas
        i</i></small></span><br>
      <template v-if="model.recaptchaAPIKey">
        <span class="required-label">
          <small>
            <i>
              *This site is protected by reCAPTCHA and the Google
              <a
                style="text-decoration:underline"
                href="https://policies.google.com/privacy"
                target="_blank"
              > Privacy Policy</a> and
              <a
                style="text-decoration:underline"
                href="https://policies.google.com/terms"
                target="_blank"
              > Terms of Service</a> apply.
            </i>
          </small>
        </span>
      </template>
      <br>
      <button
        v-if="v$.$invalid"
        disabled
        type="submit"
        class="btn btn-primary"
        style="float:right;"
      >
        Skicka
      </button>
      <button
        v-else
        type="submit"
        class="btn btn-primary"
        style="float:right;"
        @click="onSubmit"
      >
        Skicka
      </button>
    </template>
  </form>
</template>
<script setup>
import { computed, reactive, ref, nextTick  } from 'vue';
import { useVuelidate } from '@vuelidate/core'
import { required, email, helpers, sameAs } from '@vuelidate/validators';
import { isValidSocialSecNumber, isValidCoordinationNumber } from '../../utils/validators';
import { reCaptchaSubmit } from './contactUsUtils';
import helperTexts from '../../../../json/helperTexts.json';

const props = defineProps({ model: Object });
const dataChanged = ref(false);
const formRef = ref(null);
const recaptchaToken = ref('');
const captchaSettingsTimeStamp = ref('');
const captchaSettings = computed(() => (JSON.stringify({
    keyname: props.model.keyname,
    fallback: true,
    orgId: props.model.orgId,
    ts: captchaSettingsTimeStamp.value,
})));

const formModel = {
    name: {
        label: 'FÖR- OCH EFTERNAMN',
        placeholder: '',
        ariaLabel: 'emailHelp',
    },
    ssid: {
        label: 'PERSONNUMMER *',
        placeholder: helperTexts.placeholderSSN,
        ariaLabel: 'emailHelp',
    },
    email: {
        label: 'E-POSTADRESS *',
        placeholder: helperTexts.placeholderEmail,
        ariaLabel: 'emailHelp',
    },
    description: {
        label: 'BESKRIVNING *',
        placeholder: 'Vänligen beskriv ditt ärende',
        ariaLabel: 'emailHelp',
    },
};

const form = reactive({
    name: '',
    ssid: '',
    email: '',
    description: '',
    integrityCheck: false,
});

const rules = computed(() => {
    return {
        ssid: {
            valid_ssid: {
                $validator: isValidSocialSecNumber,
                $message: helperTexts.validationMessageSSN,
            },
            valid_cord: {
                $validator: isValidCoordinationNumber,
                $message: helperTexts.validationMessageSSNCoordination
            },
            required: helpers.withMessage(helperTexts.validationMessageRequired, required),
        },
        email: {
            required: helpers.withMessage(helperTexts.validationMessageRequired, required),
            email: helpers.withMessage(helperTexts.validationMessageEmail, email),
        },
        description: {
            required: helpers.withMessage(helperTexts.validationMessageRequired, required),
        },
        integrityCheck: {
            sameAs: sameAs(true),
        },
    }
})

const formatedDescription = computed(() => `Webformulär: Betalning: \nBeskrivning: ${form.description}`);

const v$ = useVuelidate(rules, form);

const onDataChange = () => {
    if (!v$.value.$error) {
        dataChanged.value = true;
    } else {
        dataChanged.value = false;
    }
};

const handleRecaptchaTokenRecieved = (token) => {
    captchaSettingsTimeStamp.value = new Date().getTime();
    recaptchaToken.value = token;
    nextTick(() => {
        formRef.value.submit();
    });
}

const onSubmit = (e) => {
    e.preventDefault(false);
    if(props.model.recaptchaAPIKey) {
       return  reCaptchaSubmit({recaptchaAPIKey: props.model.recaptchaAPIKey, handleRecaptchaTokenRecieved });
    }
    return formRef.value.submit();
};
</script>