<template>
  <div class="new-creditwatch">
    <div class="site-content myucscorepage">
      <div
        id="uc-score"
        class="row uc-score-graph-container"
      >
        <div
          v-if="loadedFailed || !loaded"
          class="col-12 dark-background"
        >
          <Reloader
            v-if="loadedFailed"
            theme="dark"
            @reload="GetNewChartData"
          />
          <div
            v-else-if="!loaded"
            class="align-items-center justify-content-center d-flex"
          >
            <NdsLoader />
          </div>
        </div>
        <div class="col-12">
          <div
            class="usergraph__hc-nav"
            style="display: none;"
          >
            <span
              class="usergraph__hc-navprev"
              @click="updateUCScoreChartPrev(1)"
            ><i
              class="fas fa-chevron-left"
            /></span> <span class="usergraph__hc-current">Månad -
              Månad</span> <span
              class="usergraph__hc-navnext"
              @click="updateUCScoreChartNext(1)"
            ><i
              class="fas fa-chevron-right"
            /></span>
          </div>
          <div
            id="graph-uc-score"
            class="uc-score-graph first-row"
          />
          <div
            v-if="report.creditReport.creditwatchScoreList.length > 0"
            class="risk-score-container"
          >
            <div class="slider-pin">
              <div
                class="pin-container"
                :style="{ 'right': ((999 - report.creditReport.creditwatchScoreList[report.creditReport.creditwatchScoreList.length - 1].scoreValue) / 999) * 100 + '%' }"
              >
                <span>{{ report.creditReport.creditwatchScoreList[report.creditReport.creditwatchScoreList.length
                  - 1].scoreValue }}</span><i class="fas fa-chevron-down" />
              </div>
            </div>
            <div class="risk-uc-score-slider" />
            <div
              v-if="report.creditReport.creditwatchScoreList[report.creditReport.creditwatchScoreList.length - 1].scoreValue >= 1 && report.creditReport.creditwatchScoreList[report.creditReport.creditwatchScoreList.length - 1].scoreValue <= 454"
              class="risk-grade-label-container row"
            >
              <div class="col-12">
                <div
                  class="risk-grade-label weak"
                  :style="{ 'right': ((999 - report.creditReport.creditwatchScoreList[report.creditReport.creditwatchScoreList.length - 1].scoreValue) / 999) * 100 + '%' }"
                  data-testid="risk-grade-svag"
                >
                  Svag
                </div>
              </div>
              <div class="col-12">
                <div
                  class="risk-grade-intervall"
                  :style="{ 'right': ((999 - report.creditReport.creditwatchScoreList[report.creditReport.creditwatchScoreList.length - 1].scoreValue) / 999) * 100 + 1 + '%' }"
                >
                  1-454
                </div>
              </div>
            </div>
            <div
              v-if="report.creditReport.creditwatchScoreList[report.creditReport.creditwatchScoreList.length - 1].scoreValue >= 455 && report.creditReport.creditwatchScoreList[report.creditReport.creditwatchScoreList.length - 1].scoreValue <= 558"
              class="risk-grade-label-container row"
            >
              <div class="col-12">
                <div
                  class="risk-grade-label less-good"
                  :style="{ 'right': ((999 - report.creditReport.creditwatchScoreList[report.creditReport.creditwatchScoreList.length - 1].scoreValue) / 999) * 100 + '%' }"
                  data-testid="risk-grade-mindre-bra"
                >
                  Mindre bra
                </div>
              </div>
              <div class="col-12">
                <div
                  class="risk-grade-intervall"
                  :style="{ 'right': ((999 - report.creditReport.creditwatchScoreList[report.creditReport.creditwatchScoreList.length - 1].scoreValue) / 999) * 100 + 1 + '%' }"
                >
                  455-558
                </div>
              </div>
            </div>
            <div
              v-if="report.creditReport.creditwatchScoreList[report.creditReport.creditwatchScoreList.length - 1].scoreValue >= 559 && report.creditReport.creditwatchScoreList[report.creditReport.creditwatchScoreList.length - 1].scoreValue <= 634"
              class="risk-grade-label-container row"
            >
              <div class="col-12">
                <div
                  class="risk-grade-label good"
                  :style="{ 'right': ((999 - report.creditReport.creditwatchScoreList[report.creditReport.creditwatchScoreList.length - 1].scoreValue) / 999) * 100 + '%' }"
                  data-testid="risk-grade-bra"
                >
                  Bra
                </div>
              </div>
              <div class="col-12">
                <div
                  class="risk-grade-intervall"
                  :style="{ 'right': ((999 - report.creditReport.creditwatchScoreList[report.creditReport.creditwatchScoreList.length - 1].scoreValue) / 999) * 100 + 1 + '%' }"
                >
                  559-634
                </div>
              </div>
            </div>
            <div
              v-if="report.creditReport.creditwatchScoreList[report.creditReport.creditwatchScoreList.length - 1].scoreValue >= 635 && report.creditReport.creditwatchScoreList[report.creditReport.creditwatchScoreList.length - 1].scoreValue <= 744"
              class="risk-grade-label-container row"
            >
              <div class="col-12">
                <div
                  class="risk-grade-label very-good"
                  :style="{ 'right': ((999 - report.creditReport.creditwatchScoreList[report.creditReport.creditwatchScoreList.length - 1].scoreValue) / 999) * 100 + '%' }"
                  data-testid="risk-grade-mycket-bra"
                >
                  Mycket bra
                </div>
              </div>
              <div class="col-12">
                <div
                  class="risk-grade-intervall"
                  :style="{ 'right': ((999 - report.creditReport.creditwatchScoreList[report.creditReport.creditwatchScoreList.length - 1].scoreValue) / 999) * 100 + 1 + '%' }"
                >
                  635-744
                </div>
              </div>
            </div>
            <div
              v-if="report.creditReport.creditwatchScoreList[report.creditReport.creditwatchScoreList.length - 1].scoreValue >= 745 && report.creditReport.creditwatchScoreList[report.creditReport.creditwatchScoreList.length - 1].scoreValue <= 999"
              class="risk-grade-label-container row"
            >
              <div class="col-12">
                <div
                  class="risk-grade-label"
                  :style="{ 'right': ((999 - report.creditReport.creditwatchScoreList[report.creditReport.creditwatchScoreList.length - 1].scoreValue) / 999) * 100 + '%' }"
                  data-testid="risk-grade-utmarkt"
                >
                  Utmärkt
                </div>
              </div>
              <div class="col-12">
                <div
                  class="risk-grade-intervall"
                  :style="{ 'right': ((999 - report.creditReport.creditwatchScoreList[report.creditReport.creditwatchScoreList.length - 1].scoreValue) / 999) * 100+1+'%' }"
                >
                  745-999
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import Highcharts from 'highcharts';
import $ from 'jquery';
import { NdsLoader } from '@nds/vue';
import Reloader from '@/components/Reloader.vue';
export default {
    name: 'UCScore',
    components: {
      NdsLoader,
      Reloader,
    },
    props: {
        chartData: Object,
        report: Object,
        height: Number,
        width: Number,
        markerRadius: Number,
        size: String
    },
    data: function () {
        return {
            creditWatch: {
                ucScoreChartMaxItems: 6,
                chartMaxItems: 6,
                totalResData: {},
                resData: {},
                chartIndexMax: 0,
                chartIndexMin: 0
            },
            ucscoreChart: {},
            loaded: false,
            loadedFailed: false
            /*
             this.creditWatch.totalResData = res;
                this.creditWatch.resData = this.creditWatch.totalResData['UC_CREDIT_SCORE'];
                this.creditWatch.chartIndexMax = this.creditWatch.resData.Categories.length - 1;
             */
        }
    },
    mounted: function () {
        var self = this;
        this.chartRedraw(false);
        self.loaded = true;
        self.loadedFailed = false;
        //setTimeout(() => {
        self.setUCScoreChartData(self.report.ucscoreChartData);
        self.updateUCScoreChartNavigator();
        self.ucscoreChart = new Highcharts.Chart(self.getNewChartOptions());

    }, methods: {
        filterNewChartData: function () {
            let creditWatch = this.creditWatch;
            creditWatch.clonedResData = JSON.parse(JSON.stringify(creditWatch.resData));
            creditWatch.filteredResData = {};
            creditWatch.filteredResData.Categories = [];
            creditWatch.filteredResData.PlotLines = [];
            creditWatch.filteredResData.SeriesLine = [];
            creditWatch.filteredResData.SeriesWeak = [];
            creditWatch.filteredResData.SeriesLessGood = [];
            creditWatch.filteredResData.SeriesGood = [];
            creditWatch.filteredResData.SeriesVeryGood = [];
            creditWatch.filteredResData.SeriesExcellent = [];
            var i = 0;
            var oldVal = {};

            for (i = 0; i < creditWatch.clonedResData.Categories.length; i++) {
                if (i <= creditWatch.chartIndexMax && i >= creditWatch.chartIndexMin)
                    creditWatch.filteredResData.Categories.splice(creditWatch.filteredResData.Categories.length, 0, creditWatch.clonedResData.Categories[i]);
            }

            // PlotLines
            for (i = 0; i < creditWatch.clonedResData.PlotLines.length; i++) {
                if (i <= creditWatch.chartIndexMax && i >= creditWatch.chartIndexMin) {
                    oldVal = creditWatch.clonedResData.PlotLines[i];
                    oldVal.value = oldVal.value - creditWatch.chartIndexMin;
                    creditWatch.filteredResData.PlotLines.splice(creditWatch.filteredResData.PlotLines.length, 0, oldVal);
                }
            }

            // SeriesLine
            for (i = 0; i < creditWatch.clonedResData.SeriesLine.length; i++) {
                if (i <= creditWatch.chartIndexMax && i >= creditWatch.chartIndexMin) {
                    oldVal = creditWatch.clonedResData.SeriesLine[i];
                    oldVal.x = oldVal.x - creditWatch.chartIndexMin;
                    creditWatch.filteredResData.SeriesLine.splice(creditWatch.filteredResData.SeriesLine.length, 0, oldVal);
                }
            }

            // SeriesWeak
            for (i = 0; i < creditWatch.clonedResData.SeriesWeak.length; i++) {
                if (creditWatch.clonedResData.SeriesWeak[i].x <= creditWatch.chartIndexMax && creditWatch.clonedResData.SeriesWeak[i].x >= creditWatch.chartIndexMin) {
                    oldVal = creditWatch.clonedResData.SeriesWeak[i];
                    oldVal.x = oldVal.x - creditWatch.chartIndexMin;
                    creditWatch.filteredResData.SeriesWeak.splice(creditWatch.filteredResData.SeriesWeak.length, 0, oldVal);
                }
            }

            // SeriesLessGood
            for (i = 0; i < creditWatch.clonedResData.SeriesLessGood.length; i++) {
                if (creditWatch.clonedResData.SeriesLessGood[i].x <= creditWatch.chartIndexMax && creditWatch.clonedResData.SeriesLessGood[i].x >= creditWatch.chartIndexMin) {
                    oldVal = creditWatch.clonedResData.SeriesLessGood[i];
                    oldVal.x = oldVal.x - creditWatch.chartIndexMin;
                    creditWatch.filteredResData.SeriesLessGood.splice(creditWatch.filteredResData.SeriesLessGood.length, 0, oldVal);
                }
            }

            // SeriesGood
            for (i = 0; i < creditWatch.clonedResData.SeriesGood.length; i++) {
                if (creditWatch.clonedResData.SeriesGood[i].x <= creditWatch.chartIndexMax && creditWatch.clonedResData.SeriesGood[i].x >= creditWatch.chartIndexMin) {
                    oldVal = creditWatch.clonedResData.SeriesGood[i];
                    oldVal.x = oldVal.x - creditWatch.chartIndexMin;
                    creditWatch.filteredResData.SeriesGood.splice(creditWatch.filteredResData.SeriesGood.length, 0, oldVal);
                }
            }
            // SeriesVeryGood
            for (i = 0; i < creditWatch.clonedResData.SeriesVeryGood.length; i++) {
                if (creditWatch.clonedResData.SeriesVeryGood[i].x <= creditWatch.chartIndexMax && creditWatch.clonedResData.SeriesVeryGood[i].x >= creditWatch.chartIndexMin) {
                    oldVal = creditWatch.clonedResData.SeriesVeryGood[i];
                    oldVal.x = oldVal.x - creditWatch.chartIndexMin;
                    creditWatch.filteredResData.SeriesVeryGood.splice(creditWatch.filteredResData.SeriesVeryGood.length, 0, oldVal);
                }
            }

            // SeriesExcellent
            for (i = 0; i < creditWatch.clonedResData.SeriesExcellent.length; i++) {
                if (creditWatch.clonedResData.SeriesExcellent[i].x <= creditWatch.chartIndexMax && creditWatch.clonedResData.SeriesExcellent[i].x >= creditWatch.chartIndexMin) {
                    oldVal = creditWatch.clonedResData.SeriesExcellent[i];
                    oldVal.x = oldVal.x - creditWatch.chartIndexMin;
                    creditWatch.filteredResData.SeriesExcellent.splice(creditWatch.filteredResData.SeriesExcellent.length, 0, oldVal);
                }
            }
        },
        updateUCScoreChartNavigator: function () {
            let creditWatch = this.creditWatch;
            if (creditWatch.resData.MonthNames.length > 1) {
                //$(".usergraph__hc-current").html(creditWatch.resData.MonthNames[creditWatch.chartIndexMin] + " 2020 - " + creditWatch.resData.MonthNames[creditWatch.chartIndexMax] + " 2021");
                $(".usergraph__hc-current").html(creditWatch.resData.MonthNames[creditWatch.chartIndexMin] + " - " + creditWatch.resData.MonthNames[creditWatch.chartIndexMax]);
                $(".usergraph__hc-nav").show();
            } else {
                $(".usergraph__hc-nav").hide();
            }

            if (creditWatch.chartIndexMax < creditWatch.resData.Categories.length - 1)
                $(".usergraph__hc-navnext").show();
            else
                $(".usergraph__hc-navnext").hide();

            if (creditWatch.chartIndexMin > 0)
                $(".usergraph__hc-navprev").show();
            else
                $(".usergraph__hc-navprev").hide();
        },
        chartRedraw: function (update) {
            let creditWatch = this.creditWatch;
            creditWatch.ucScoreChartMaxItems = 6;

            if ($(window).width() < 480 || $(window).width() > 768) {
                creditWatch.chartMaxItems = 5;
                creditWatch.markerRadius = this.markerRadius;
                creditWatch.showAxis = false;
                creditWatch.reportChartMaxItems = 3;
            }
            else {
                creditWatch.chartMaxItems = 10;
                if (this.size === "lg") {
                    creditWatch.markerRadius = this.markerRadius;
                }
                else {
                    creditWatch.markerRadius = this.markerRadius + 10;
                }
                creditWatch.showAxis = true;
                creditWatch.reportChartMaxItems = 6;
            }

            if (creditWatch.ucscoreChart !== undefined && update) {

                creditWatch.chartIndexMax = creditWatch.resData.Categories.length - 1;
                if (creditWatch.resData.Categories.length > creditWatch.ucScoreChartMaxItems) {
                    creditWatch.chartIndexMin = creditWatch.chartIndexMax - creditWatch.ucScoreChartMaxItems + 1;
                } else {
                    creditWatch.chartIndexMin = 0;
                }

                creditWatch.filterNewChartData();
                creditWatch.updateUCScoreChartNavigator();
                creditWatch.ucscoreChart.update(creditWatch.getNewChartOptions());
            }
        },
        updateUCScoreChartNext: function (i) {
            let creditWatch = this.creditWatch;
            creditWatch.chartIndexMax = creditWatch.chartIndexMax + i;
            creditWatch.chartIndexMin = creditWatch.chartIndexMin + i;

            // blockera att man går längre än max
            if (creditWatch.chartIndexMax > creditWatch.resData.Categories.length - 1) {
                creditWatch.chartIndexMax = creditWatch.resData.Categories.length - 1;
                creditWatch.chartIndexMin = creditWatch.chartIndexMax - creditWatch.chartMaxItems + 1;
            }

            this.filterNewChartData();
            this.updateUCScoreChartNavigator();
            this.ucscoreChart.update(this.getNewChartOptions());
        },
        updateUCScoreChartPrev: function (i) {
            const creditWatch = this.creditWatch;
            creditWatch.chartIndexMax = creditWatch.chartIndexMax - i;
            creditWatch.chartIndexMin = creditWatch.chartIndexMin - i;

            // blockera att man går längre än min
            if (creditWatch.chartIndexMin < 0) {
                creditWatch.chartIndexMin = 0;
                creditWatch.chartIndexMax = creditWatch.chartMaxItems - 1;
            }

            this.filterNewChartData();
            this.updateUCScoreChartNavigator();
            this.ucscoreChart.update(this.getNewChartOptions());
        },
        chartFormatter: function () {
            if (this.y > 10000)
                return (Math.round(this.y / 1000)).toString().replace(/\B(?=(\d{3})+(?!\d))/g, " ") + "k";
            return (this.y).toString().replace(/\B(?=(\d{3})+(?!\d))/g, " ");
        },
        setUCScoreChartData: function (res) {
            for (const category in res) {
                if (Object.prototype.hasOwnProperty.call(res, category)) {
                    $(".category-show-" + category).show();
                }
            }

            this.creditWatch.totalResData = res;
            this.creditWatch.resData = this.creditWatch.totalResData['UC_CREDIT_SCORE'];
            this.creditWatch.chartIndexMax = this.creditWatch.resData.Categories.length - 1;
            if (this.creditWatch.resData.Categories.length > this.creditWatch.ucScoreChartMaxItems) {
                this.creditWatch.chartIndexMin = this.creditWatch.chartIndexMax - this.creditWatch.ucScoreChartMaxItems + 1;
            }

            this.filterNewChartData();
        },
        getNewChartOptions: function () {
            let creditWatch = this.creditWatch;
            return {
                chart: {
                    renderTo: 'graph-uc-score',
                    backgroundColor: 'rgba(0,0,0,0)',
                    styledMode: true,
                    height: this.height
                },
                accessibility: {
                    enabled: false
                },
                title: {
                    text: ''
                },

                style: {
                    fontFamily: 'Gilroy-Bold',
                    filter: 'alpha(opacity=10)',
                    opacity: 10,
                    background: 'transparent'
                },

                xAxis: {
                    categories: creditWatch.filteredResData.Categories,
                    tickmarkPlacement: 'on',
                    startOnTick: true,
                    endOnTick: true,
                    labels: {
                        y: 25
                    },
                    plotLines: creditWatch.filteredResData.PlotLines,
                    tickAmount: 6
                },

                yAxis: {
                    visible: false
                },
                tooltip: {
                    enabled: false
                },
                plotOptions: {
                    series: {
                        states: {
                            hover: {
                                enabled: false
                            }
                        }
                    }
                },
                series: [
                    {
                        zIndex: 10,
                        dateFormat: 'YYYY-mm-dd',
                        className: 'series-line',
                        type: 'line',
                        lineWidth: 2,
                        tooltip: false,
                        marker: {
                            enabled: false
                        },
                        states: {
                            hover: {
                                enabled: false
                            }
                        },
                        dataLabels: {
                            enabled: true,
                            useHTML: true,
                            verticalAlign: 'middle',
                            padding: 0,
                            y: -4,
                            formatter: function () {
                                if (this.y > 10000)
                                    return (Math.round(this.y / 1000)).toString().replace(/\B(?=(\d{3})+(?!\d))/g, " ") + "k";
                                else if (this.y === 0)
                                    return ("<span class='position-absolute' style='top:-13px; left:-4px;'>0</span>")
                                return (this.y).toString().replace(/\B(?=(\d{3})+(?!\d))/g, " ");
                            }
                        },
                        data: creditWatch.filteredResData.SeriesLine

                    }, {
                        zIndex: 20,
                        className: 'series-weak',
                        type: 'scatter',
                        marker: {
                            enabled: true,
                            symbol: 'circle',
                            radius: creditWatch.markerRadius
                        },
                        states: {
                            hover: {
                                enabled: false
                            }
                        },
                        data: creditWatch.filteredResData.SeriesWeak
                    }, {
                        zIndex: 20,
                        className: 'series-less-good',
                        type: 'scatter',
                        marker: {
                            enabled: true,
                            symbol: 'circle',
                            radius: creditWatch.markerRadius
                        },
                        states: {
                            hover: {
                                enabled: false
                            }
                        },
                        data: creditWatch.filteredResData.SeriesLessGood
                    }, {
                        zIndex: 20,
                        className: 'series-good',
                        type: 'scatter',
                        marker: {
                            enabled: true,
                            symbol: 'circle',
                            radius: creditWatch.markerRadius
                        },
                        states: {
                            hover: {
                                enabled: false
                            }
                        },
                        data: creditWatch.filteredResData.SeriesGood
                    }, {
                        zIndex: 20,
                        className: 'series-very-good',
                        type: 'scatter',
                        marker: {
                            enabled: true,
                            symbol: 'circle',
                            radius: creditWatch.markerRadius
                        },
                        states: {
                            hover: {
                                enabled: false
                            }
                        },
                        data: creditWatch.filteredResData.SeriesVeryGood
                    }, {
                        zIndex: 20,
                        className: 'series-excellent',
                        type: 'scatter',
                        marker: {
                            enabled: true,
                            symbol: 'circle',
                            radius: creditWatch.markerRadius
                        },
                        states: {
                            hover: {
                                enabled: false
                            }
                        },
                        data: creditWatch.filteredResData.SeriesExcellent
                    }
                ]

            };
        }
    }
}
</script>
