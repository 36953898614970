import $ from 'jquery';
import { showErrorToast } from './_toastrhandling.js';
var disableDigitalMailboxModal = $('#disable-digitalmailbox-warning');
var digitalMailboxEnabled = $('.isActivated').data("is-activated");

function initDigitalMailbox() {
    if (digitalMailboxEnabled) {
        $(".btn-digitalmailbox-off").click(function () { 
            disableDigitalMailboxModal.modal('show');
        });
    } 

    if (!digitalMailboxEnabled) {
        $(".btn-digitalmailbox-on").click(function () {
            //ENABLE DIGITALMAILBOX 
            $.ajax({
                url: 'SetActive',
                type: 'POST',
                data: {
                    SetActive: "ON"
                },
                success: function () {  
                    $(".btn-digitalmailbox-on").addClass("btn-digitalmailbox-on-active");
                    $(".btn-digitalmailbox-off").removeClass("btn-digitalmailbox-off-active");
                    location.reload();
                },
                error: function (){
                    showErrorToast("Kunde inte aktivera Digital Brevlåda. Försök igen senare eller kontakta kundtjänst.");
                }
            });
        });
    }
}

// My Messages setting 
initDigitalMailbox();

$(".cancel-digitalmailbox-modal").click(function () {
    initDigitalMailbox();
});

$(".disable-digitalmailbox-warning-okbutton").click(function () {
    $.ajax({
        url: 'SetActive',
        type: 'POST',
        data: {
            SetActive: "OFF"
        },
        success: function () {
            $(".btn-digitalmailbox-on").removeClass("btn-digitalmailbox-on-active");
            $(".btn-digitalmailbox-off").addClass("btn-digitalmailbox-off-active");
            location.reload();
        },
        error: function (){
            showErrorToast("Kunde inte avvaktivera Digital Brevlåda. Försök igen senare eller kontakta kundtjänst.");
        }
    });
    initDigitalMailbox();
    disableDigitalMailboxModal.modal('hide');
});