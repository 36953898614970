<template>
  <div
    class="alert alert-dismissible position-relative"
    :class="alertType"
    role="alert"
  >
    <i
      class="alert-icon far fa-2x position-absolute"
      :class="iconClass"
    />
    <strong>{{ title }}</strong>
    <p v-html="description" />
    <button
      type="button"
      class="close"
      aria-label="Close"
      @click="emit('close')"
    >
      <i class="fas fa-times" />
    </button>
  </div>
</template>
<script setup>
import { computed } from 'vue';

// TODO add typings when ts is introduced supported types are: success, error
const props = defineProps({
    title: String,
    description: String,
    type: String,
});
const emit = defineEmits(['close']);

const iconClass = computed(() => {
    if(props.type === 'error') {
        return 'fa-exclamation-triangle';
    }
    // default success
    return 'fa-check-circle';
})

const alertType = computed(() => {
  if(props.type === 'error') {
        return 'alert-danger';
    }
    // default success
    return 'alert-success';
  
})

</script>
<style lang="scss" scoped>

.alert-icon {
  left: 15px;
}


</style>