export default {
    personId: {
        required: true,
        ssn: true
    },
    email: {
        required: true,
        email: true
    },
    phone: {
        required: true,
        phone: true
    }
}