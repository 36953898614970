<template>
  <div
    class="row justify-content-center"
    data-testid="reloader"
  >
    <div
      class="d-block w-100 text-center"
      :class="theme != null ? 'dark' : ''"
    >
      <div class="d-flex justify-content-center">
        <span class="icon-circle input-group-text justify-content-center">
          <i class="far fa-2x fa-exclamation-triangle" /></span>
      </div>
      <p class="color-nightrider font-weight-bold mt-3">
        Innehållet kunde inte hämtas
      </p>
      <button
        class="btnReload btn btn-enento-secondary"
        @click="reload"
      >
        Hämta igen
      </button>
    </div>
  </div>
</template>
<script setup>
defineProps({
    color: String,
    theme: String
});
const emit = defineEmits(['reload'])

const reload = () => {
    emit('reload');
}
</script>
<style lang="scss" scoped>
@import "@/../../styles/abstracts/_variables.scss";

.icon-circle {
    background-color: $beach;
    width: 70px;
    height: 70px;
    border-radius: 50%;
    color: $apricot;
}

.dark {
    span.icon-circle {
        background: none;
        border-color: $white;

        i {
            color: $white;
        }
    }

    p {
        color: $white;
    }

    .btnReload {
        background: none;
        border-color: $white;
        color: $white;
    }

    color: $white;
}
</style>