import $ from "jquery";

export const prepareProductPackageData = ({ name, id, price, owner, category, recurringPayment }) => {
    return {
        name,
        id,
        price,
        quantity: 1,
        category: `${category}${recurringPayment ? "/SUBSCRIPTION" : "/ONDEMAND"}`,
        variant: owner ? owner.name : "",
    };
}

export const trackProductClick = ({ product: { productPackage}, eventCallback }) => {
    const productFieldObject = prepareProductPackageData(productPackage);
    const listKey = $("#listKey").val() === "" ? "Beställningsfönster" : $("#listKey").val();
    window.dataLayer = window.dataLayer || [];
    window.dataLayer.push({
        'event': 'productClick',
        'ecommerce': {
            'currencyCode': 'SEK',
            'click': {
                'actionField': { 'list': listKey },      // Optional list property.
                'products': [productFieldObject]
            }
        },
        'eventCallback': eventCallback ? eventCallback : () => {
        }
    });
}

export const trackAddToCart = ({ product: { productPackage}, eventCallback }) => {
    const productFieldObject = prepareProductPackageData(productPackage);
    window.dataLayer = window.dataLayer || [];
    window.dataLayer.push({
        'event': 'addToCart',
        'ecommerce': {
            'currencyCode': 'SEK',
            'add': {
                'products': [productFieldObject]
            }
        },
        'eventCallback': eventCallback ? eventCallback : () => {
        }
    });
}

export const trackRemoveFromCart = ({ product: { productPackage}, eventCallback }) => {
    const productFieldObject = prepareProductPackageData(productPackage);
    window.dataLayer = window.dataLayer || [];
    window.dataLayer.push({
        'event': 'removeFromCart',
        'ecommerce': {
            'currencyCode': 'SEK',
            'remove': {
                'products': [productFieldObject]
            }
        },
        'eventCallback': eventCallback ? eventCallback : () => {
        }
    });
}

export const trackOnCheckout = ({ product: { productPackage}, eventCallback }) => {
    const productFieldObject = prepareProductPackageData(productPackage);
    window.dataLayer = window.dataLayer || [];
    window.dataLayer.push({
        'event': 'checkout',
        'ecommerce': {
            'currencyCode': 'SEK',
            'checkout': {
                'actionField': { 'step': 1 },
                'products': [productFieldObject]
            }
        },
        'eventCallback': eventCallback ? eventCallback : () => {
        }
    });
}

export const trackOnPurchase = ({ paymentId, product: { productPackage}, eventCallback }) => {
    const productFieldObject = prepareProductPackageData(productPackage);
    window.dataLayer = window.dataLayer || [];
    window.dataLayer.push({
        'event': 'purchase',
        "ecommerce": {
            "currencyCode": 'SEK',
            'purchase': {
                'actionField': { "id": paymentId },
                'products': [productFieldObject]
            }
        },
        'eventCallback': eventCallback ? eventCallback : () => {
        }
    });
}