import Forms from '../validation/forms/index';
import { personalIdentityNumberRule } from '../src/utils/personalIdentityNumber';
import  { isValidSwedishCellPhone, emailRule, postalCodeRule } from '../src/utils/validators';
import $ from 'jquery';
import 'jquery-validation';

// is used in mySettingsForm
const Validation = (($) => {
    const Attrs = {
        VALIDATE: 'data-validate',
        SUBMIT: 'data-validate-submit',
        CONFIG: 'data-validate-config',
    }

    class Validation {
        constructor(form) {
            this._form = form;
            this._rules = this.getRules();
            this._validator = null;
            this.init();
        }

        getRules() {
            const formPath = this._form.attr(Attrs.VALIDATE);
            return Forms[formPath];
        }

        handleSubmit(form) {
            this.onValidationCompleted(form);
        }

        onValidationCompleted(form) {
            const $form = $(form);
            const shouldSubmit = !($form.attr(Attrs.SUBMIT) === 'false');
            if (shouldSubmit) {
                return form.submit();
            }
            var event = new CustomEvent("validated", {
                detail: {
                    form: form,
                }
            });
            return form.dispatchEvent(event);
        }

        getExtendedConfiguration() {
            const config = this._form.attr(Attrs.CONFIG);
            if (!config) {
                return {};
            }
            return JSON.parse(config);
        }

        errorPlacement(error, element) {
            if(element.attr("custom-validation-placement")) {
                error.insertAfter($('#' + element.attr("custom-validation-placement")));
                return;
            }
            if (element.attr("name") === "policyCheck") {
                error.addClass('checkbox-error-placement');
                error.insertAfter($('.checkbox-label'));
            } else {
                error.insertAfter(element);
            }
        }

        getErrorElement($input) {
            return $input.closest('.input-group').length > 0
                ? $input.closest('.input-group')
                : $input;
        }

        onHighlight(element, errorClass, validClass) {
            this.getErrorElement($(element))
                .addClass(errorClass)
                .removeClass(validClass);
        }

        onUnhighlight(element, errorClass, validClass) {
            this.getErrorElement($(element))
                .removeClass(errorClass)
                .addClass(validClass);
        }
        init() {
            this._validator = this._form.validate({
                //...this.getExtendedConfiguration(),
                rules: this._rules,
                errorElement: 'small',
                errorClass: 'is-invalid',
                submitHandler: (form) => this.handleSubmit(form),
                errorPlacement: (error, element) => this.errorPlacement(error, element),
                highlight: (element, errorClass, validClass) => this.onHighlight(element, errorClass, validClass),
                unhighlight: (element, errorClass, validClass) => this.onUnhighlight(element, errorClass, validClass),
            });
        }
    }

    $.validator.addMethod('ssn', (value) => personalIdentityNumberRule(value));
    $.validator.addMethod('optional_ssn', (value) => personalIdentityNumberRule(value) || value == '');
    $.validator.addMethod('phone', (value) => isValidSwedishCellPhone(value) || value == '');
    $.validator.addMethod('postal_code', (value) => postalCodeRule(value));
    $.validator.addMethod('email', (value) => emailRule(value));
    $(`[${Attrs.VALIDATE}]`).each((_index, form) => {
        new Validation($(form));
    });
})($)
//export default Validation