<template>
  <ul
    v-if="!showSideBar && sideBarMenuLoaded && isLoggedin"
    class="dashboard-side-bar sidebar menu pl-0"
    data-testid="dashboard-side-bar"
  >
    <li :class="'/mina-tjanster' === router.currentRoute.value.path ? 'active w-100' : 'w-100'">
      <RouterLink
        class="d-flex"
        to="/mina-tjanster"
      >
        <span class="input-group-text justify-content-center border-0 bg-transparent color-white pr-0"><i
          class="far fa-home"
        /></span>
        <span
          class="menu-item-name"
          data-testid="oversikt-btn"
        >Översikt</span>
      </RouterLink>
    </li>
    <hr>
    <li
      v-if="customerEssentials?.displayCreditWatchProduct"
      class="py-1 px-2 mb-4 w-100"
      data-testid="Kreditkollen"
    >
      <a
        href="#"
        class="d-flex"
        @click="toggleMenu"
      >
        <span class="input-group-text justify-content-center circle-product"><i
          class="fas fa-analytics fa-fw"
        /></span>
        <span class="menu-item-name">Kreditkollen <i class="far fa-chevron-right" /></span>
      </a>
    </li>
    <li
      v-for="(item, index) in sideBarMenu"
      :key="`dashboard_side_bar_menu_item${index}`"
      class="py-1 px-2 mb-4 w-100"
      :class="(item.Url === router.currentRoute.value.path ? ' active ' : '')"
      :data-testid="item.Name"
    >
      <RouterLink
        :to="item.Url"
        :class="'d-flex menuCategory'"
      >
        <span :class="item.IconContainerClass + ' input-group-text justify-content-center'">
          <i :class="item.FontAwesomeClass + ' color-gallery'" />
        </span>
        <span class="menu-item-name">{{ item.Title }}</span>
      </RouterLink>
    </li>
    <hr>
    <li
      class="px-2 w-100"
      :class="'/mina-installningar' === router.currentRoute.value.path ? 'active' : ''"
    >
      <RouterLink
        class="d-flex"
        to="/mina-installningar"
      >
        <span class="input-group-text justify-content-center border-0 bg-transparent color-white pr-0">
          <i class="far fa-cog" />
        </span>
        <span class="menu-item-name">Inställningar</span>
      </RouterLink>
    </li>
  </ul>
</template>
<script setup>
import { useStore } from 'vuex';
import { useRouter } from 'vue-router';
import { computed, onMounted } from 'vue';
import { APP_TOGGLE_SIDEBAR, APP_FETCH_SIDEBAR_MENU } from '../Scripts/store/modules/appContext';

const store = useStore();
const router = useRouter();
const customerEssentials = computed(() => store.state.customerInfoContex.customerEssentials);
const isLoggedin = computed(() => store.state.appContext.isLoggedin);
const showSideBar = computed(() => store.state.appContext.showSideBar);
const sideBarMenu = computed(() => store.state.appContext.sideBarMenu);
const sideBarMenuLoaded = computed(() => store.state.appContext.sideBarMenuLoaded);

onMounted(() => {
  if (!store.state.appContext.sideBarMenuLoaded) {
    store.dispatch(APP_FETCH_SIDEBAR_MENU);
  }
});

const toggleMenu = () => {
  store.dispatch(APP_TOGGLE_SIDEBAR)
  router.push({ path: '/mina-tjanster/kreditkollen/min-ekonomi/krediter' })
}
</script>
<style lang="scss" scoped>
@import "@/../../styles/abstracts/_mixins.scss";
@import "@/../../styles/abstracts/_variables.scss";
@import "@/../../styles/abstracts/_breakpoints.scss";

.dashboard-side-bar {
  list-style-type: none;
  background-color: $night-rider;

  @include breakpoint(max-tablet-portrait) {
    display: none;
  }

  hr {
    border-color: $emperor;
  }

  li {
    position: relative;

    a {
      color: $gallery;

      @include attention() {
        text-decoration: none;
      }

    }

    &:not(.active) {
      @include attention() {
        border-radius: 8px;
        background-color: $emperor-hover;
      }
    }

    .has-notification:after {
      content: "";
      display: inline-block;
      margin-left: 15px;
      width: 8px;
      height: 8px;
      background: $feedback-error;
      border-radius: 50%;
    }

    &.active {
      background-color: $emperor;
      border-radius: 8px;

      >a {
        .menu-item-name {
          text-decoration: none;
        }

        .circle-product {
          background-color: $ocean !important;

          i {
            color: #fff !important;
          }
        }

        i {
          color: $orangegold;
        }
      }
    }
  }

  .sidebar-submenu {
    font-size: 0.875rem;
    list-style-type: none;

    li:first-child {
      margin-top: 1.5rem;
    }

    li:last-child {
      padding-bottom: 0;
    }
  }
}
</style>