<template>
  <div
    class="row"
    data-testid="checkout-select-product"
  >
    <div class="col-12">
      <h2 class="text-center text-md-left mb-4 mb-md-5">
        {{ model.checkoutSelectProductTitle }}
      </h2>
    </div>
    <div class="col-md-6">
      <div
        v-for="(product, index) in products"
        :key="index"
      >
        <div
          class="card mb-4"
          :class="{ 'highlighted': product.productPackage.id === selectedProduct?.productPackage?.id }"
        >
          <label
            class="mb-0"
            :for="`radio-enento-${index}`"
          >
            <div
              class="card-body d-flex align-items-center position-relative"
              @change="updateSelectedProduct(product)"
            >
              <div
                v-if="product.checkoutSelectProductTagText"
                class="card-label"
              >
                {{ product.checkoutSelectProductTagText }}
              </div>
              <input
                :id="`radio-enento-${index}`"
                class="checkout-select-radio-input flex-shrink-0 mr-3"
                type="radio"
                :checked="product.productPackage.id == selectedProduct?.productPackage?.id"
                name="product"
              >
              <div class="d-flex flex-column">
                <p class="font-weight-medium mb-0">
                  <i
                    v-if="product.checkoutSelectProductFACode"
                    :class="product.checkoutSelectProductFACode"
                  />
                  {{ product.productPackage.name }}
                </p>
                <p
                  v-if="product.checkoutSelectProductProductDescription"
                  class="body-small color-emperor mt-1 mb-0 mr-3"
                >
                  {{ product.checkoutSelectProductProductDescription }}</p>
              </div>
              <div class="d-flex flex-column ml-auto flex-shrink-0">
                <h4 class="color-darkocean gilroy-bold mb-0">{{ product.productPackage.price }},00 kr</h4>
                <p class="body-small color-emperor mb-0 text-right">{{ product.productPackagePriceDescription }}</p>
              </div>
            </div>
          </label>
        </div>
      </div>
      <p
        v-if="model.checkoutSelectProductExtraButtonText"
        class="pt-4 mb-2"
      >
        {{ model.checkoutSelectProductExtraButtonText }}
      </p>
      <button
        v-if="model.checkoutSelectProductButtonText"
        type="button"
        class="btn btn-default btn-lg btn-wide w-100-md"
        data-testid="checkout-CheckoutSelectProduct-continue-button"
        @click="goToCheckoutEnterInformation"
      >
        {{ model.checkoutSelectProductButtonText }}
      </button>
    </div>
    <div class="col-md-6">
      <img
        class="d-none d-md-block"
        :src="model.checkoutSelectProductImage"
      >
    </div>
  </div>
</template>
<script setup>
import { useStore } from 'vuex';
import { computed, onMounted } from 'vue';
import {
  CHECKOUT_SELECT_PRODUCT,
  CHECKOUT_SELECT_STEP,
  CHECKOUT_STEP,
} from '../../Scripts/store/modules/checkoutContext';

const props = defineProps({
  model: Object,
  products: Object
});

const store = useStore();
const selectedProduct = computed(() => store.state.checkoutContext.selectedProduct);

onMounted(() => {
  const firstProduct = props.products.length > 0 ? props.products[0] : null;
  if (!selectedProduct.value && firstProduct) {
    store.dispatch(CHECKOUT_SELECT_PRODUCT, { product: firstProduct });
  }
});

const updateSelectedProduct = (product) => {
  store.dispatch(CHECKOUT_SELECT_PRODUCT, { product });
};

const hasSelectedUppsellProduct = () => {
  const firstProduct = props.products.length > 0 ? props.products[0] : null;
  return firstProduct?.productPackage?.id !== selectedProduct.value?.productPackage?.id;
}
const goToCheckoutEnterInformation = () => {
  store.dispatch(CHECKOUT_SELECT_STEP, { step: CHECKOUT_STEP.ENTER_INFORMATION, isContinuingWithUpsellProduct: hasSelectedUppsellProduct() });
};

</script>
<style lang="scss" scoped>
@import "@/../../styles/abstracts/_mixins.scss";
@import "@/../../styles/abstracts/_variables.scss";
@import "@/../../styles/abstracts/_breakpoints.scss";

.checkout-select-radio-input {
  accent-color: $dark-ocean;
}

.card {
  border-radius: 8px;

  &.highlighted {
    background: $beach;
  }

  .card-body {
    padding: 16px;

    @include attention() {
      cursor: pointer;
    }

    input[type=radio] {
      width: 24px;
      height: 24px;
    }

    i {
      font-size: 19px;
    }
  }

  .card-label {
    position: absolute;
    top: -15px;
    left: 25px;
    padding: 2px 8px;
    border-radius: 6px;
    background-color: $violet;
    color: $white;
  }
}
</style>