<template>
  <div
    class="row"
    data-testid="checkout-payment"
  >
    <div class="col-md-6 offset-md-3">
      <h2 class="text-center">
        Slutför beställning
      </h2>
      <div
        id="checkout-container-div"
        ref="netsIdContainer"
        class="mb-4"
      />
      <div
        v-if="model.checkoutPaymentCheckoutDescriptionBlocks"
        class="checkout-payment-wrapper rounded-border fade-in three mt-4 pt-4 px-4"
      >
        <template
          v-for="(block, index) in model.checkoutPaymentCheckoutDescriptionBlocks"
          :key="`checkout_description_block_${index}`"
        >
          <CheckoutDescriptionBlock :model="block" />
        </template>
      </div>
    </div>
  </div>
  <ModalsContainer />
</template>

<script setup>
import { useStore } from 'vuex';
import { computed, onMounted, onUpdated, ref } from 'vue';
import { ModalsContainer, useModal } from 'vue-final-modal';
import ErrorModal from '../modals/ErrorModal.vue'
import { defaultGenericErrorModel } from '../modals/modalsUtils';
import CheckoutDescriptionBlock from '../../components/blocks/CheckoutDescriptionBlock.vue';
import { personalIdentityNumberToAge } from '../../utils/personalIdentityNumber';
import { postFreePurchase, postCreatePayment } from '../../Scripts/providers/customerApiProvider';
import { getMessagesForErrorStatus } from '../../Scripts/providers/viewApiProviders';
import { trackOnPurchase, trackOnCheckout } from '../../utils/ecommercetrackingUtils';
import {
  CHECKOUT_SELECT_STEP,
  CHECKOUT_STEP,
} from '../../Scripts/store/modules/checkoutContext';

defineProps({
  model: Object,
});

const store = useStore();

const selectedProduct = computed(() => store.state.checkoutContext.selectedProduct);
const externalCustomerId = computed(() => store.state.checkoutContext.externalCustomerId);
const customer = computed(() => store.state.checkoutContext.customer);
const isLoggedin = computed(() => store.state.appContext.isLoggedin);
const errorModel = ref(defaultGenericErrorModel);
const netsIdContainer = ref(null);
const onCloseModal = () => {
  store.dispatch(CHECKOUT_SELECT_STEP, { step: CHECKOUT_STEP.ENTER_INFORMATION });
}
const { open, close } = useModal({
  component: ErrorModal,
  attrs: {
    model: errorModel,
    onConfirm() {
      close().then(() => onCloseModal())
    },
  },
});


const handlePaymentError = ({ statusCode }) => {
  close();
  getMessagesForErrorStatus({ statusCode }).then((response) => {
    const {
      errorTitle,
      errorText,
      errorTextLoggedIn,
      errorAlreadyBoughtCtaText,
      errorAlreadyBoughtCtaUrl,
    } = response.data;
    errorModel.value = {
      errorTitle,
      errorText,
      errorTextLoggedIn,
      errorAlreadyBoughtCtaText: errorAlreadyBoughtCtaText || 'Stäng',
      errorAlreadyBoughtCtaUrl,
    };

    return open();
  }).catch(() => {
    errorModel.value = defaultGenericErrorModel;
    return open();
  });
}

const createPayment = () => {
  // TODO: Return state to complete to test localy
  // store.dispatch(CHECKOUT_SELECT_STEP, { step: CHECKOUT_STEP.COMPLETE, isLoggedin: isLoggedin.value });

  const freeUnder21 = selectedProduct.value.freeForUnder21;
  const age = personalIdentityNumberToAge(customer.value.ssn);
  if (freeUnder21 && age < 21) {
    postFreePurchase({
      customerId: externalCustomerId.value,
      productPackageIds: selectedProduct.value.productPackage.id
    }).then(() => {
      // 
      store.dispatch(CHECKOUT_SELECT_STEP, { step: CHECKOUT_STEP.ENTER_INFORMATION_COMPLETE });
    }).catch((error) => {
      const ppi = selectedProduct.value.productPackage.id;
      handlePaymentError({ ppi, statusCode: error.status });
    });
  } else {
    postCreatePayment({
      externalCustomerId: externalCustomerId.value,
      productPackageIds: selectedProduct.value.productPackage.id,
    }).then((response) => {
      if (netsIdContainer.value && netsIdContainer.value.innerHTML !== '') {
        netsIdContainer.value.innerHTML = '';
      }
      const paymentId = response.data?.paymentId;
      const checkoutKey = document.getElementById('netscheckoutkey')?.value;
      if (!checkoutKey || checkoutKey === '' || !paymentId || paymentId === '') {
        // TODO handle error better when netsid is down
        return handlePaymentError({ statusCode: 400 });
      }
      const checkoutOptions = {
        checkoutKey,
        paymentId,
        containerId: 'checkout-container-div',
        language: 'sv-SE'
      };
      trackOnCheckout({ product: selectedProduct.value });
      const checkout = new window.Dibs.Checkout(checkoutOptions);
      checkout.on('payment-completed', () => {
        trackOnPurchase({ paymentId, product: selectedProduct.value });
        store.dispatch(CHECKOUT_SELECT_STEP, { step: CHECKOUT_STEP.COMPLETE, isLoggedin: isLoggedin.value });
      });
    }).catch((error) => {
      const statusCode = error.response?.status;
      if (statusCode) {
        return handlePaymentError({ statusCode });
      }
      return handlePaymentError({ statusCode: 400 });
    });
  }
}

const attemptCreatePayment = () => {
  if (externalCustomerId.value) {
    createPayment();
  }
};

onMounted(() => {
  attemptCreatePayment();
});

onUpdated(() => {
  attemptCreatePayment();
});

</script>
<style lang="scss" scoped>
@import "@/../../styles/abstracts/_variables.scss";

.checkout-payment-wrapper {
  background: $beach-secondary;
}
</style>