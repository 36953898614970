import $ from 'jquery';
var $product = $('.product');

$(document.body).on("change", "input[name=productradio]", function () {
    calcProduct();
    calcOrderPrice();
    $(".product").removeClass("active");
    $(this).closest(".product").addClass("active");

    $('.productpackage').removeClass('active');
    $(this).parent().addClass('active');

    //$('#productPackagePriceLabel').text($(this).attr('price') + " " + $(this).attr('description'));
    if ($(this).attr("data-numberOffFreeMonths") === "0" || $(this).attr("data-numberOffFreeMonthsText") === "") {
        $('#productPackagePriceLabel').text($(this).attr('price') + " " + $(this).attr('description'));
    }
    else {
        $('#productPackagePriceLabel').text($(this).attr("data-numberOffFreeMonthsText"));
    }

    $('#productPackageSubLabel').text('');
    $('#productPackageSubLabel').text($(this).attr('subdescription'));

});

export function closestOffer(that) {
    $(that).closest($product).toggleClass('active');
}

$(document.body).on("change", ".offers [type=checkbox]", function () {
    calcExtras();
    closestOffer(this);
});

//CALC PRICE
var productPrice = 0;
export function calcProduct() {
    productPrice = parseInt($('input[name=productradio]:checked').attr('price'));
}

var extrasPrice = 0;
export function calcExtras() {
    extrasPrice = 0;
    $('.offers input[type=checkbox]:checked').each(function () {
        var offerPrice = parseInt($(this).attr('price'));
        extrasPrice = extrasPrice + offerPrice;
    });
    calcOrderPrice();
}

export function calcOrderPrice() {
    var orderPrice = extrasPrice + productPrice;
    $('#orderPrice').html(orderPrice);
}
calcProduct();
calcExtras();