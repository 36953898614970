import $ from 'jquery';
import { showErrorToast } from './_toastrhandling.js';
$(document).ready(function () {

    $(document.body).on("click",
        "#approveConditionsBtn",
        function (event) {
            event.preventDefault();

            var csrfToken = $("input[name=__RequestVerificationToken]").val();

            $.ajax({
                type: "POST",
                url: '/ajax/CustomerApi/UpdateCustomerApproveConditions',
                data: {__RequestVerificationToken: csrfToken},
                dataType: "json",
                success: function (msg) {
                    //Redirect
                    window.location.href = msg;
                },
                error: function (xhr) {
                    showErrorToast(xhr.responseText.replace('""', ''));
                }
            });
        });


    $('#my-settings-form').on('validated', 
        function (e) {
            e.preventDefault();

            var customerEmail = $("#email").val();
            var customerPhoneNumber = $("#phone").val();
            var customerSmsNotification = $("#cbSmsNotification").is(":checked");
            var customerMailNotification = $("#cbMailNotification").is(":checked");
            var customerNewsLetterSubscription = $("#cbNewsLetter").is(":checked");
            var csrfToken = $("input[name=__RequestVerificationToken]").val();

            $.ajax({
                type: "POST",
                url: '/ajax/CustomerApi/UpdateCustomerSettings',
                data: {
                    email: customerEmail,
                    phoneNumber: customerPhoneNumber,
                    smsNotification: customerSmsNotification,
                    mailNotification: customerMailNotification,
                    newsLetterSubscription: customerNewsLetterSubscription,
                    __RequestVerificationToken: csrfToken
                },
                dataType: "json",
                success: function () {
                    // Display success message
                    $("#SuccessMessage").addClass("in");
                    setTimeout(function () {
                            $("#SuccessMessage").removeClass("in");
                        },
                        2000);
                },
                error: function (xhr) {
                    // $("#ErrorMsg").html(xhr.responseText);
                    showErrorToast(xhr.responseText.replace('""', ''));
                }
            });
        });
});

export function uploadProtectionReport(filesToUpload, okCallback, failedCallback) {
    const data = new FormData();
    $.each(filesToUpload, function (key, value) {
        data.append(key, value);
    });

    var csrfToken = $("input[name=__RequestVerificationToken]").val();
    data.append("__RequestVerificationToken", csrfToken);

    $.ajax({
        url: '/ajax/CustomerApi/UploadProtectionReport',
        type: 'POST',
        data,
        cache: false,
        dataType: 'json',
        processData: false, // Don't process the files
        contentType: false, // Set content type to false as jQuery will tell the server its a query string request
        success: function (response) {
            okCallback(response);
        },
        error: function (_, textStatus) {
            failedCallback(textStatus);
        }
    });
}

export function changeProtectionStatus(newStatus, okCallback, failedCallback) {
    var csrfToken = $("input[name=__RequestVerificationToken]").val();
    $.post("/ajax/CustomerApi/ChangeProtectionStatus", {newStatus: newStatus, __RequestVerificationToken: csrfToken})
        .done(okCallback)
        .fail(failedCallback);
}

export function registerDigitalMailbox(newStatus, okCallback, failedCallback) {
    var csrfToken = $("input[name=__RequestVerificationToken]").val();
    $.post("/ajax/CustomerApi/RegisterDigitalMailbox", {newStatus: newStatus, __RequestVerificationToken: csrfToken})
        .done(okCallback)
        .fail(failedCallback);
}

export function changeGroupName(groupId, newName, okCallback, failedCallback) {
    var csrfToken = $("input[name=__RequestVerificationToken]").val(); 
    $.post("/ajax/CustomerApi/ChangeGroupName", {
        groupId: groupId,
        newName: newName,
        __RequestVerificationToken: csrfToken
    })
        .done(okCallback)
        .fail(failedCallback);
}

export function sendGroupInvite(groupId, name, email, okCallback, failedCallback) {
    var csrfToken = $("input[name=__RequestVerificationToken]").val();
    $.post("/ajax/CustomerApi/SendGroupInvite", {
        groupId: groupId,
        name: name,
        email: email,
        __RequestVerificationToken: csrfToken
    })
        .done(okCallback)
        .fail(failedCallback);
}

export function deleteGroupInvite(groupId, name, okCallback, failedCallback) {
    var csrfToken = $("input[name=__RequestVerificationToken]").val();
    $.post("/ajax/CustomerApi/DeleteGroupInvite", {groupId: groupId, name: name, __RequestVerificationToken: csrfToken})
        .done(okCallback)
        .fail(failedCallback);
}

export function deleteGroupMembership(memberId, ownerId, okCallback, failedCallback) {
    var csrfToken = $("input[name=__RequestVerificationToken]").val();
    $.post("/ajax/CustomerApi/DeleteGroupMembership", {
        memberId: memberId,
        ownerId: ownerId,
        __RequestVerificationToken: csrfToken
    })
        .done(okCallback)
        .fail(failedCallback);
}