<template>
  <VueFinalModal class="delete-invite-modal-container"
    content-class="delete-invite-modal-content flex flex-col bg-white dark:bg-black rounded border border-gray-100 dark:border-gray-800"
    data-testid="delete-invite-modal">
    <div class="delete-invite-modal-header d-flex align-items-baseline">
      <h3 class="delete-invite-modal-title">
        <i class="far fa-user-times" />Ta bort inbjudan
      </h3>
      <NdsIconButton theme="enento" variant="text" prefix="far" iconName="times" @click="emit('cancel')"
        aria-label="Close" />
    </div>
    <div class="modal-body">
      <p>
        Vill du ta bort inbjudan för <strong>{{ selectedInvite.name }}</strong>?
      </p>
    </div>
    <div class="delete-invite-modal-footer">
      <NdsButton theme="enento" variant="secondary" @click="emit('cancel')">
        Avbryt
      </NdsButton>
      <NdsButton theme="enento" variant="critical" @click="deleteGroupInvite()">
        Ta bort inbjudan
      </NdsButton>
    </div>
  </VueFinalModal>
</template>
<script setup>
import { VueFinalModal } from 'vue-final-modal';
import { NdsButton, NdsIconButton } from '@nds/vue';
import { getRequestVerificationToken } from '../../utils/authUtils';
import { postDeleteGroupInvite } from '../../Scripts/providers/customerApiProvider';

const props = defineProps({
  selectedInvite: Object,
});

const emit = defineEmits(['cancel', 'deletedGroupInvite', 'error']);

const deleteGroupInvite = () => {
  const csrfToken = getRequestVerificationToken();
  postDeleteGroupInvite({ groupId: props.selectedInvite.groupId, name: props.selectedInvite.name, csrfToken }).then(({ data }) => {
    if (data?.success) {
      return emit('deletedGroupInvite');
    }
    return emit('error');
  }).catch(() => {
    return emit('error');
  });
}
</script>
<style lang="scss">
@import "@/../../styles/abstracts/_mixins.scss";
@import "@/../../styles/abstracts/_variables.scss";
@import "@/../../styles/abstracts/_breakpoints.scss";

.delete-invite-modal-container {
  display: flex;
  justify-content: center;
  align-items: center;
}

.delete-invite-modal-content {
  background-color: #fff;
  width: 100%;
  position: relative;
  display: flex;
  flex-direction: column;
  pointer-events: auto;
  margin: 1.25rem auto;

  @include breakpoint(small-phone) {
    max-width: 346px;
  }

  @include breakpoint(tablet-portrait) {
    max-width: 450px;
  }

  @include breakpoint(large-desktop) {
    max-width: 528px;
  }

  .delete-invite-modal-header {
    display: flex;
    align-items: flex-start;
    justify-content: space-between;
    padding: 1rem 1rem;

    .delete-invite-modal-title {
      display: flex;
      gap: 1rem;

      i {
        font-size: 24px;
        color: #333;
      }

    }
  }

  .delete-invite-modal-footer {
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    justify-content: flex-end;
    padding: 1rem;
    gap: 1rem;
  }
}
</style>