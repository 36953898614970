import { ref } from "vue";
export default function useDropZone() {
    const dragActive = ref(false);
    const droppedFile = ref(null);

    const toggle_active = () => {
        if (droppedFile.value == null) {
            dragActive.value = !dragActive.value;
        }
    };

    const drop = (event) => {
        if (droppedFile.value != null) {
            var filteredFiles = [];
            for (const item of droppedFile.value) {

                filteredFiles.push(item);
            }
            for (const item of event.dataTransfer.files) {

                filteredFiles.push(item);
            }
            droppedFile.value = filteredFiles;
        }
        else {
            droppedFile.value = event.dataTransfer.files;
        }
    };

    const selectedFile = (event) => {
        if (droppedFile.value != null) {
            var filteredFiles = [];
            for (const item of droppedFile.value) {

                filteredFiles.push(item);
            }
            for (const item of event.target.files) {

                filteredFiles.push(item);
            }
            droppedFile.value = filteredFiles;
        }
        else {
            droppedFile.value = event.target.files;
        }
        dragActive.value = true;
    };

    const clearDropped = () => {
        droppedFile.value = null;
        dragActive.value = false;
    };

    return {
        dragActive,
        droppedFile,
        toggle_active,
        drop,
        selectedFile,
        clearDropped,
    }
}