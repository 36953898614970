<template>
  <figure
    v-if="mountedComplete"
    class="hero-img"
    data-testid="hero-block"
  >
    <div class="container">
      <div class="row">
        <div :class="heroColumn">
          <div
            class="heroblock__text-wrapper"
            :class="`${model.PaddingTopBot}  ${heroSectionTextAlign}`"
          >
            <template v-if="model.Heading">
              <h1
                :class="model.HeadingColor == context.HeroHeadingColorTheme.black ? 'color-black' : 'color-white'"
              >
                {{ model.Heading }}
              </h1>
              <hr>
            </template>
            <div
              v-if="model.Text"
              class="heroblock__text enento-tinymce"
              v-html="model.Text"
            />
            <slot :class="heroSectionTextAlign" />
          </div>
        </div>
      </div>
    </div>
  </figure>
</template>
<script setup>
import { computed, ref, onMounted } from 'vue';

const props = defineProps({
    model: Object,
    context: Object,
})

const mountedComplete = ref(false);

onMounted(() => {
    mountedComplete.value = true;
});

const heroColumn = computed(() => {
    if (props.model.TextAlign === props.context.ContentHorizontalAlignment.center) {
        return "col-md-8 offset-md-2";
    }
    if (props.model.TextAlign === props.context.ContentHorizontalAlignment.right) {
        return "col-md-8 offset-md-4";
    }
    return "col-md-8";
});
const heroSectionTextAlign = computed(() => {
    if (props.model.TextAlign === props.context.ContentHorizontalAlignment.center) {
        return "text-center";
    }
    if (props.model.TextAlign === props.context.ContentHorizontalAlignment.right) {
        return "text-right";
    }
    return "";
});
const backgroundImageDesktop = computed(() => `url(${props.model?.backgroundImageDesktopUrl}) center center / cover no-repeat`);
const backgroundImageMobile = computed(() => `url(${props.model?.backgroundImageMobileUrl}) center center / cover no-repeat`);
</script>
<style lang="scss" scoped>
@media (min-width: 768px) {
    .hero-img {
        background: v-bind(backgroundImageDesktop);
    }
}

@media (max-width: 767px) {
    .hero-img {
        background: v-bind(backgroundImageMobile);
    }
}

.text-center {
    text-align: center;
}

.text-right {
    text-align: right;
}
</style>