<template>
    <VueFinalModal class="confirm-modal-container"
        content-class="confirm-modal-content flex flex-col bg-white dark:bg-black rounded border border-gray-100 dark:border-gray-800"
        data-testid="confirm-modal">

        <div class="confirm-modal-header d-flex align-items-baseline">
            <h3 class="confirm-modal-title">
                <i v-if="icon" :class="icon" />{{ title }}
            </h3>
            <NdsIconButton theme="enento" variant="text" prefix="far" iconName="times" @click="emit('cancel')"
                aria-label="Close" />
        </div>
        <div class="confirm-modal-body" v-html="body" />
        <div class="confirm-modal-footer">
            <NdsButton theme="enento" variant="primary" @click="emit('confirm')">
                Stäng
            </NdsButton>
        </div>
    </VueFinalModal>
</template>
<script setup>
import { VueFinalModal } from 'vue-final-modal';
import { NdsButton, NdsIconButton } from '@nds/vue';

defineProps({
    title: String,
    body: String,
    icon: String
});

const emit = defineEmits(['confirm', 'cancel']);

</script>
<style lang="scss">
@import "@/../../styles/abstracts/_mixins.scss";
@import "@/../../styles/abstracts/_variables.scss";
@import "@/../../styles/abstracts/_breakpoints.scss";

.confirm-modal-container {
    display: flex;
    justify-content: center;
    align-items: center;
}

.confirm-modal-content {
    background-color: #fff;
    width: 100%;
    position: relative;
    display: flex;
    flex-direction: column;
    pointer-events: auto;
    margin: 1.25rem auto;

    @include breakpoint(small-phone) {
        max-width: 346px;
    }

    @include breakpoint(tablet-portrait) {
        max-width: 450px;
    }

    @include breakpoint(large-desktop) {
        max-width: 528px;
    }

    .confirm-modal-header {
        display: flex;
        align-items: flex-start;
        justify-content: space-between;
        padding: 1rem 1rem;

        .confirm-modal-title {
            display: flex;
            gap: 1rem;

            i {
                font-size: 24px;
                color: #333;
            }

        }
    }

    .confirm-modal-body {
        position: relative;
        flex: 1 1 auto;
        padding: 1rem;
    }

    .confirm-modal-footer {
        display: flex;
        flex-wrap: wrap;
        align-items: center;
        justify-content: flex-end;
        padding: 1rem;
    }
}
</style>