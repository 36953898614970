<template>
  <div class="dashboard col-md-8 col-lg-9" data-testid="dashboard-page">
    <div class="DashboardPage row" scoped>
      <div class="col-12 mb-4" data-testid="heading-welcome">
        <h2>Välkommen {{ customerEssentials.customerFullName }}</h2>
      </div>
      <div class="col-lg-6 col-xl-5 col-12">
        <div v-if="customerEssentials.displayIdProtectionProduct ||
          customerEssentials.displayCreditWatchProduct ||
          customerEssentials.displayDigitalMailbox" class="dashboard__card eventlist p-0 pt-3">
          <div class="d-flex mb-2">
            <h3 class="px-3">
              Händelser
            </h3>
          </div>
          <ul class="mb-0">
            <RouterLink v-if="customerEssentials.displayIdProtectionProduct" to="/mina-tjanster/id-skydd"
              :class="customerEssentials.newEventsCount > 0 ? 'not-read' : ''"
              class="eventlist__item input-group-text position-relative border-0">
              <li>
                <i class="far fa-user-shield fa-fw" />
                <span class="position-relative">ID-Skydd:
                  <span v-if="customerEssentials.newEventsCount > 0" class="font-weight-medium">{{
                    customerEssentials.newEventsCount }} nya händelser</span>
                  <span v-else class="font-weight-medium">0 nya händelser</span>
                </span>
                <i class="far fa-chevron-right" />
              </li>
            </RouterLink>
            <RouterLink v-if="customerEssentials.displayCreditWatchProduct"
              to="/mina-tjanster/kreditkollen/min-ekonomi/krediter"
              :class="customerCreditNotifications.length > 0 ? 'not-read' : ''"
              class="eventlist__item input-group-text position-relative border-0">
              <li>
                <i class="far fa-analytics fa-fw" />
                <span class="position-relative">Kreditkollen:
                  <span v-if="customerCreditNotifications.length > 0" class="font-weight-medium">{{
                    customerCreditNotifications.length }} nya händelser</span>
                  <span v-else class="font-weight-medium">0 nya händelser</span>
                </span>
                <i class="far fa-chevron-right" />
              </li>
            </RouterLink>
            <RouterLink v-if="customerEssentials.displayDigitalMailbox" to="/mina-tjanster/digital-brevlada"
              :class="customerEssentials.numberOfNewItems > 0 ? 'not-read' : ''"
              class="eventlist__item input-group-text position-relative border-0">
              <li>
                <i class="far fa-envelope fa-fw" /><span class="position-relative">Digital Brevlåda:<span
                    class="font-weight-medium">{{ customerEssentials.numberOfNewItems }} nya händelser</span>
                </span> <i class="far fa-chevron-right" />
              </li>
            </RouterLink>
          </ul>
        </div>
        <div class="dashboard__card my-services">
          <div class="d-flex align-items-center mb-3">
            <h3 class="mb-0">
              Mina Tjänster
            </h3>
            <!--Fix link below-->
            <RouterLink to="/mina-installningar#payments-tab" class="btn btn-sm btn-enento-text ml-auto mr-n2">
              Gå till
              betalningar
            </RouterLink>
          </div>
          <div class="row" data-testid="dashboard-product-link-ball-cards">
            <div class="col-6 d-flex justify-content-center ">
              <div v-if="customerEssentials.displayIdProtectionProduct"
                class="product__circle position-relative d-flex justify-content-center align-items-center"
                data-testid="dashboard-ball-bought-idskydd-button">
                <RouterLink to="/mina-tjanster/id-skydd">
                  <span><i class="far fa-user-shield fa-2x" /></span>
                  <span class="body-small color-emperor">ID-Skydd</span>
                </RouterLink>
              </div>
              <div v-else
                class="product__circle position-relative d-flex justify-content-center align-items-center disabled"
                data-testid="dashboard-ball-not-bought-idskydd-button">
                <a href="/id-skydd">
                  <span><i class="far fa-user-shield fa-2x" /></span>
                  <span class="body-small color-emperor">ID-Skydd</span>
                  <button class="btn btn-sm btn-enento-primary position-absolute border-0">Läs mer</button>
                </a>
              </div>
            </div>
            <div class="col-6 d-flex justify-content-center ">
              <div v-if="customerEssentials.displayCreditWatchProduct"
                class="product__circle position-relative d-flex justify-content-center align-items-center"
                data-testid="dashboard-ball-bought-kreditkollen-button">
                <RouterLink to="/mina-tjanster/kreditkollen/min-ekonomi/krediter">
                  <span><i class="fas fa-analytics fa-fw fa-2x" /></span>
                  <span class="body-small color-emperor">Kreditkollen</span>
                </RouterLink>
              </div>
              <div v-else
                class="product__circle position-relative d-flex justify-content-center align-items-center disabled"
                data-testid="dashboard-ball-not-bought-kreditkollen-button">
                <a href="/kreditkollen">
                  <span><i class="fas fa-analytics fa-fw fa-2x" /></span>
                  <span class="body-small color-emperor">Kreditkollen</span>
                  <button class="btn btn-sm btn-enento-primary position-absolute border-0">Läs mer</button>
                </a>
              </div>
            </div>
            <div class="col-6 pt-4 pt-md-4 d-flex justify-content-center">
              <div v-if="customerEssentials.displayCustomerReportProduct"
                class="product__circle position-relative d-flex justify-content-center align-items-center"
                data-testid="dashboard-ball-bought-min-upplysning-button">
                <div
                  v-if="customerEssentials.purchasedProductPackages !== null && !customerEssentials.displayCreditWatchProduct"
                  class="daysLeftLabel position-absolute color-white text-center">
                  <span class="body-xsmall" data-testid="days-left-text">
                    {{ getEndDateForProduct({
                      productName: 'Min Upplysning',
                      purchasedProductPackages: customerEssentials.purchasedProductPackages
                    }) }}
                    dagar
                  </span>
                </div>
                <RouterLink to="/mina-tjanster/kreditkollen/min-upplysning">
                  <span><i class="far fa-lightbulb fa-fw fa-2x" /></span>
                  <span class="body-small color-emperor">Min Upplysning</span>
                </RouterLink>
              </div>
              <div v-else
                class="product__circle position-relative d-flex justify-content-center align-items-center disabled"
                data-testid="dashboard-ball-not-bought-min-upplysning-button">
                <a href="/min-upplysning">
                  <span><i class="far fa-lightbulb fa-fw fa-2x" /></span>
                  <span class="body-small color-emperor">Min Upplysning</span>
                  <button class="btn btn-sm btn-enento-primary position-absolute border-0">Läs mer</button>
                </a>
              </div>
            </div>
            <div class="col-6 pt-4 pt-md-4 d-flex justify-content-center">
              <div v-if="customerEssentials.displayCreditScoreProduct"
                class="product__circle position-relative d-flex justify-content-center align-items-center"
                data-testid="dashboard-ball-bought-mitt-kreditbetyg-button">
                <RouterLink to="/mina-tjanster/mitt-kreditbetyg">
                  <span><i class="far fa-tachometer fa-fw fa-2x" /></span>
                  <span class="body-small color-emperor">Mitt Kreditbetyg</span>
                </RouterLink>
              </div>
              <div v-else
                class="product__circle position-relative d-flex justify-content-center align-items-center disabled"
                data-testid="dashboard-ball-not-bought-mitt-kreditbetyg-button">
                <a href="/mitt-kreditbetyg">
                  <span><i class="far fa-tachometer fa-fw fa-2x" /></span>
                  <span class="body-small color-emperor">Mitt Kreditbetyg</span>
                  <button class="btn btn-sm btn-enento-primary position-absolute border-0">Läs mer</button>
                </a>
              </div>
            </div>
          </div>
          <div class="row no-gutters">
            <div class="col-6 col-lg-12">
              <h4 class="mt-4 mb-3">
                Gratistjänster
              </h4>
              <RouterLink to="/mina-tjanster/bedragerisparr" class="btn btn-enento-text ml-n2"
                data-testid="dashboard-gratistjanster-bedragerisparr-btn">
                <i class="far fa-shield-alt fa-fw pr-4" />
                <span class="label">Bedrägerispärr</span>
              </RouterLink>
              <div v-if="customerEssentials.displayDigitalMailboxRegistered">
                <RouterLink to="/mina-tjanster/digital-brevlada" class="btn btn-enento-text ml-n2"
                  data-testid="dashboard-gratistjanster-digital-brevlada-btn">
                  <i class="far fa-envelope fa-fw pr-4" />
                  <span class="label">Digital Brevlåda</span>
                </RouterLink>
              </div>
              <div v-else>
                <a href="/digital-brevlada" class="btn btn-enento-text ml-n2"
                  data-testid="dashboard-gratistjanster-digital-brevlada-btn">
                  <i class="far fa-envelope fa-fw pr-4" />
                  <span class="label">Digital Brevlåda</span>
                </a>
              </div>
            </div>
            <div class="col-6 col-lg-12">
              <h4 class="mt-4 mb-3">
                Engångsköp
              </h4>
              <a href="/forlustanmalan" class="btn btn-enento-text ml-n2">
                <i class="far fa-exclamation-circle fa-fw pr-4" />Förlustanmälan
              </a>
            </div>
          </div>
        </div>
      </div>
      <div class="col-lg-6 col-xl-7 col-12">
        <div v-if="customerEssentials.displayIdProtectionProduct" class="dashboard__card">
          <div class="d-flex align-items-center mb-3">
            <i class="far fa-eye fa-fw color-nightrider fa-h3-size mr-2" />
            <h3 class="mb-0">
              UC Bevakning
            </h3>
            <RouterLink to="/mina-tjanster/id-skydd" class="btn btn-sm btn-enento-text ml-auto mr-n2">
              Gå till ID-Skydd
            </RouterLink>
          </div>
          <CustomerEvents :items-per-page="5" :paging-enabled="false" :search-enabled="false" />
          <div v-if="customerEvents !== null && customerEvents.length > 0" class="mt-3 text-center">
            <RouterLink to="/mina-tjanster/id-skydd?all=true" class="btn btn-enento-text">
              Visa alla händelser
            </RouterLink>
          </div>
        </div>

        <div v-if="customerEssentials.displayCreditWatchProduct" class="dashboard__card dashboard__card-blue"
          :class="{ 'credit-watch-report-loaded-style': showCreditWatchReportLoadedStyle }">
          <div class="d-flex align-items-center mb-3">
            <h3 class="color-white mb-0">
              UC Score
            </h3>
            <RouterLink to="/mina-tjanster/kreditkollen/min-ekonomi/krediter"
              class="kk-nav-link body-small color-white ml-auto">
              Gå till Kreditkollen
            </RouterLink>
          </div>

          <UcScore v-if="customerCreditInfo.creditReport?.creditwatchScoreList && !failed" :marker-radius="15" :height="260"
            :report="customerCreditInfo" />

          <RouterLink v-else v-slot="{ href, navigate, isActive }" :to="creditWatchRelativeUrl" custom>
            <NdsButton :href="href" :active="isActive" variant="secondary" onBg="dark" size="large" width="full"
              class="kk-nav-link" @click="navigate">
              {{ props?.model?.lockedCreditWatch?.linkTextLoggedIn?.value }}
            </NdsButton>
          </RouterLink>
        </div>

        <!-- IF both products isnt available, this order -->
        <template
          v-if="!customerEssentials.displayIdProtectionProduct && !customerEssentials.displayCreditWatchProduct">
          <div v-if="!customerEssentials.displayCreditWatchProduct">
            <ProductLockedBlock :model="model.lockedCreditWatch" />
          </div>
          <div v-if="!customerEssentials.displayIdProtectionProduct">
            <ProductLockedBlock :model="model.lockedIdProtection" />
          </div>
        </template>
        <!-- else, this order-->
        <template v-else>
          <div v-if="!customerEssentials.displayIdProtectionProduct">
            <ProductLockedBlock :model="model.lockedIdProtection" />
          </div>
          <div v-if="!customerEssentials.displayCreditWatchProduct">
            <ProductLockedBlock :model="model.lockedCreditWatch" />
          </div>
        </template>
      </div>
    </div>
  </div>
  <ModalsContainer />
</template>
<script setup>
import { computed, ref, watch, onMounted } from 'vue';
import { useStore } from 'vuex';
import { NdsButton } from '@nds/vue';
import { ModalsContainer, useModal } from 'vue-final-modal';
import UcScore from '../components/charts/UcScore.vue';
import ProductLockedBlock from '../components/ProductLockedBlock.vue';
import CustomerEvents from '../components/CustomerEvents.vue';
import { getEndDateForProduct } from '../utils/productUtils';
import { CUSTOMER_CREDIT_NOTIFICATIONS_FETCH } from '../Scripts/store/modules/customerCreditNotificationsContex';
import { APP_HIDE_SIDEBAR } from '../Scripts/store/modules/appContext';
import { getPathnameFromUrl } from '../components/checkout/checkoutUtils';
import ErrorModal from '../components/modals/ErrorModal.vue';
import { CUSTOMER_CREDIT_INFO_ON_CLOSE_ERROR_MODAL } from '../Scripts/store/modules/customerCreditInfoContex';
import { creditReportError } from '../components/modals/modalsUtils';

const props = defineProps(['model']);
const store = useStore();

const errorModel = ref(creditReportError);

const customerEssentials = computed(() => store.state.customerInfoContex.customerEssentials);
const customerEvents = computed(() => store.state.customerInfoContex.customerEvents);
const customerCreditInfo = computed(() => store.state.customerCreditInfoContex.customerCreditInfo);
const failed = computed(() => store.state.customerCreditInfoContex.failed);
const showErrorModal = computed(() => store.state.customerCreditInfoContex.showErrorModal);
const customerCreditNotifications = computed(() => store.state.customerCreditNotificationsContex.customerCreditNotifications);

const creditWatchRelativeUrl = computed(() => props.model.lockedCreditWatch?.linkLoggedIn?.value?.url ? getPathnameFromUrl(props.model.lockedCreditWatch?.linkLoggedIn?.value?.url) : '');
const creditWatchBackgroundImageLoggedIn = computed(() => props.model.lockedCreditWatch?.loggedInBackgroundImage?.value?.url ? `url(${props.model.lockedCreditWatch?.loggedInBackgroundImage?.value?.url}) center center / cover no-repeat` : 'linear-gradient(180deg, #004755 659px, #E5E5E5 20px, #E5E5E5 40px)');

const showCreditWatchReportLoadedStyle = computed(() => props.model.lockedCreditWatch?.loggedInBackgroundImage?.value?.url && !customerCreditInfo.value.creditReport?.creditwatchScoreList);

watch(showErrorModal, (value) => {
  if(value) {
    openErrorModal();
  }
})

const { open: openErrorModal, close: closeErrorModal } = useModal({
  component: ErrorModal,
  attrs: {
    model: errorModel,
    onConfirm() {
      closeErrorModal();
      store.dispatch(CUSTOMER_CREDIT_INFO_ON_CLOSE_ERROR_MODAL);
    },
  },
});

onMounted(() => {
  document.title = props.model.name || "Mina tjänster";
  store.dispatch(CUSTOMER_CREDIT_NOTIFICATIONS_FETCH);
  store.dispatch(APP_HIDE_SIDEBAR);
  if(store.state.customerCreditInfoContex.showErrorModal) {
    openErrorModal();
  }
});
</script>
<style scoped lang="scss">
@import "@/../../styles/abstracts/_variables.scss";
@import "@/../../styles/abstracts/_breakpoints.scss";
@import "@/../../styles/abstracts/_mixins.scss";

.daysLeftLabel {
  background-color: $gray;
  border-radius: 6px;
  padding: 0 5px;
  top: 20px;
  right: -15px;
}

.dashboard__card-blue {
  background: linear-gradient(180deg, #004755 659px, #E5E5E5 20px, #E5E5E5 40px);
}

.credit-watch-report-loaded-style {
  background: v-bind(creditWatchBackgroundImageLoggedIn);
  display: flex;
  flex-direction: column;
  gap: 160px;
}

.my-services {
  .services {
    list-style-type: none;
    padding-left: 0;
  }
}

.coi-help {
  @include breakpoint(max-phone) {
    bottom: 82px !important;
  }
}

.product__circle {
  width: 124px;
  height: 124px;
  text-align: center;
  border-radius: 50%;
  background-color: $beach-secondary;
  filter: drop-shadow(0px 1px 1px rgba(0, 0, 0, 0.07)) drop-shadow(0px 2px 1px rgba(0, 0, 0, 0.06)) drop-shadow(0px 1px 3px rgba(0, 0, 0, 0.1));

  a:hover {
    button {
      background-color: $darkorange;
    }
  }

  button {
    border-radius: 6px;
    bottom: -5px;
    left: calc(50% - 35px);
  }

  &.disabled {
    background-color: $gallery;

    i {
      color: $silver;
    }
  }

  span {
    display: block;
    width: 100%;
    text-align: center;

    i {
      color: $orangegold;
    }
  }
}

.eventlist {
  ul {
    list-style-type: none;
    padding-left: 0;

    a {
      background-color: transparent;
      color: $black;
      padding: 0.75rem;

      @include attention() {
        background-color: $light-smoke;
        text-decoration: none;
      }

      &.not-read {
        background-color: $rodeo-dust;

        @include attention() {
          background-color: $rodeo-dust-hover;
        }
      }

      li {
        span {
          padding: 4px 6px;
        }

        i {
          color: $orangegold;

          &:first-child {
            margin-right: 5px;
          }

          &:last-child {
            position: absolute;
            top: calc(50% - 8px);
            right: 16px;
            color: $dark-ocean;
          }
        }
      }
    }
  }
}
</style>
