import { NumberFormat } from '../../_helpers.js';

const formatToSek = (nr) => `${NumberFormat(nr)} kr`;
const getDisplayedMissingValue = (showMissingValue) => showMissingValue ? 'Uppgift saknas*' : '0 kr';
const formatIncome = ({income, showMissingValue}) => income === 0 || !income ? getDisplayedMissingValue(showMissingValue) : formatToSek(income);

export const getIncomeAndTaxationModel = ({incomeAndTaxation, showMissingValue }) => {
    return {
      incomeYearDate: new Date(incomeAndTaxation.incomeYear.timeStamp.value).getFullYear(),
      fields: {
        employmentIncome: {
          value: formatIncome({income: incomeAndTaxation.employmentIncome, showMissingValue }),
          label: 'Överskott av tjänst',
        },
        activeBusinessActivityIncome: {
          value: formatIncome({income: incomeAndTaxation.activeBusinessActivityIncome, showMissingValue }),
          label: 'Överskott av aktiv näringsverksamhet',
        },
        passiveBusinessActivityIncome: {
          value: formatIncome({income: incomeAndTaxation.passiveBusinessActivityIncome, showMissingValue }),
          label: 'Överskott av passiv näringsverksamhet',
        },
        assessedEarnedIncome: {
          value: formatToSek(incomeAndTaxation.assessedEarnedIncome),
          label: 'Taxerad förvärvsinkomst',
        },
        capitalIncome: {
          value: formatToSek(incomeAndTaxation.capitalIncome),
          label: 'Överskott av kapital',
        },
        capitalDeficit: {
          value: formatToSek(incomeAndTaxation.capitalDeficit),
          label: 'Underskott av kapital',
        },
        aggregateIncomeAfterDeductionBeforeTax: {
          value: formatToSek(incomeAndTaxation.aggregateIncomeAfterDeductionBeforeTax),
          label: 'Sammanräknad netto efter avdrag före skatt',
        },
        finalTax: {
          value: formatToSek(incomeAndTaxation.finalTax),
          label: 'Slutlig skatt',
        },
        passiveBusinessIncome: {
          value: formatIncome({income: incomeAndTaxation.passiveBusinessIncome, showMissingValue: false }),
          label: 'Underskott av aktiv näringsverksamhet',
        },
        activeBusinessActivityDeficiency: {
          value: formatIncome({income: incomeAndTaxation.activeBusinessActivityDeficiency, showMissingValue: false }),
          label: 'Underskott av passiv näringsverksamhet',
        },
      },
    }
  }