<template>
  <div data-testid="invite-member-form">
    <h4 class="font-weight-medium mb-2">
      Bjud in ny person
    </h4>
    <p>
      Inbjudan skickas via e-post.
    </p>
    <div class="invite-member-form-container">
      <div class="form-group">
        <label for="email">{{ formModel.email.label }}</label>
        <input id="email" v-model="v$.email.$model" type="email" class="form-control"
          :placeholder="formModel.email.placeholder" :class="v$.email.$errors.length > 0 ? 'is-invalid' : ''"
          :aria-label="formModel.email.ariaLabel">
        <template v-if="v$.email.$errors.length > 0">
          <small v-if="v$.email.email.$invalid" class="is-invalid">{{ v$.email.email.$message }}</small>
          <small v-else-if="v$.email.required.$invalid" class="is-invalid">{{ v$.email.required.$message }}</small>
        </template>
      </div>
      <div class="form-group">

        <label for="name">{{ formModel.name.label }}</label>
        <input id="name" v-model="v$.name.$model" type="text" class="form-control"
          :placeholder="formModel.name.placeholder" :class="v$.name.$errors.length > 0 ? 'is-invalid' : ''"
          :aria-label="formModel.name.ariaLabel">
        <template v-if="v$.name.$errors.length > 0">
          <small v-if="v$.name.required.$invalid" class="is-invalid">{{ v$.name.required.$message }}</small>
          <small v-else-if="v$.name.validName.$invalid" class="is-invalid">
            {{ v$.name.validName.$message }}
          </small>
          <small v-else-if="v$.name.duplicate.$invalid" class="is-invalid">
            {{ v$.name.duplicate.$message }}
          </small>
          <small v-else-if="v$.name.maxLength.$invalid" class="is-invalid">{{ v$.name.maxLength.$message }}</small>
        </template>
      </div>
      <div>
        <NdsButton theme="enento" variant="primary" @click="sendGroupInvite()" :width="isMobile ? 'full' : ''">
          Bjud in medlem
        </NdsButton>
      </div>
    </div>
    <ModalsContainer />
  </div>
</template>
<script setup>
import { useVuelidate } from '@vuelidate/core';
import { reactive, computed, ref } from 'vue';
import { useStore } from 'vuex';
import { ModalsContainer, useModal } from 'vue-final-modal';
import { NdsButton } from '@nds/vue';
import { required, email, helpers, maxLength, not } from '@vuelidate/validators';
import { isValidName } from '../utils/validators';
import { postSendGroupInvite } from '../Scripts/providers/customerApiProvider';
import helperTexts from '../../../json/helperTexts.json';
import { defaultGenericErrorModel } from '../components/modals/modalsUtils';
import { getRequestVerificationToken } from '../utils/authUtils';
import ErrorModal from './modals/ErrorModal.vue';
import ConfirmModal from './modals/ConfirmModal.vue';
import { UMBRELLA_MEMBERS_FETCH } from '../Scripts/store/modules/umbrellaMembersContext';
import { useSiteBreakpoints } from '../utils/breakpointsUtils';

const props = defineProps({
  groupId: Number,
  invitations: Array,
});

const store = useStore();

const errorModel = ref(defaultGenericErrorModel);

const invitedNames = computed(() => props.invitations.map(({ name }) => name.trim()));

const { isMobile } = useSiteBreakpoints();

const formModel = {
  email: {
    label: 'E-post',
    placeholder: helperTexts.placeholderEmail,
    ariaLabel: 'E-post fält',
  },
  name: {
    label: 'Namn',
    ariaLabel: 'Namn fält',
  },
};

const form = reactive({
  email: '',
  name: ''
});

const isDuplicate = (value) => {
  return invitedNames.value.includes(value.trim())
};

const rules = computed(() => {
  return {
    email: {
      required: helpers.withMessage(helperTexts.validationMessageRequired, required),
      email: helpers.withMessage(helperTexts.validationMessageEmail, email),
    },
    name: {
      validName: {
        $validator: isValidName,
        $message: helperTexts.validationMessageName,
      },
      duplicate: helpers.withMessage(helperTexts.validationMessageUniqie, not(isDuplicate)),
      maxLength: helpers.withMessage(helperTexts.validationMessageNameMaxLegth, maxLength(30)),
      required: helpers.withMessage(helperTexts.validationMessageRequired, required),
    },
  }
});

const v$ = useVuelidate(rules, form);

const { open: openInvitationSentModal, close: closeInvitationSentModal } = useModal({
  component: ConfirmModal,
  attrs: {
    title: 'Inbjudan skickad',
    icon: 'far fa-check-circle',
    body: 'Inbjudan är skickad! Om mottagaren inte får ett mejl, be dem kolla sin skräppost.',
    onConfirm() {
      return closeInvitationSentModal();
    },
    onCancel() {
      return closeInvitationSentModal();
    },
  },
});

const { open: openErrorModal, close: closeErrorModal } = useModal({
  component: ErrorModal,
  attrs: {
    model: errorModel,
    onConfirm() {
      return closeErrorModal();
    },
  },
});

const sendGroupInvite = () => {
  v$.value.$validate();
  if (!v$.value.$error) {
    const csrfToken = getRequestVerificationToken();
    postSendGroupInvite({ groupId: props.groupId, name: form.name, email: form.email, csrfToken }).then((response) => {
      if (response.data?.success) {
        store.dispatch(UMBRELLA_MEMBERS_FETCH, { umbrellaProductId: store.state.customerInfoContex.customerEssentials.customerProducts.umbrellaProductId });
        return openInvitationSentModal();
      }
      errorModel.value = {
        errorTitle: 'Inbjudan kunde inte skickas',
        errorText: `Kontrollera om ett mail redan har skickats till ${form.email}`,
        errorTextLoggedIn: '',
        errorAlreadyBoughtCtaText: '',
        errorAlreadyBoughtCtaUrl: '',
      };
      return openErrorModal();
    }).catch(() => {
      errorModel.value = defaultGenericErrorModel;
      return openErrorModal();
    })
  }
}

</script>
<style lang="scss" scoped>
@import "@/../../styles/abstracts/_breakpoints.scss";

.invite-member-form-container {
  max-width: 50%;

  @include breakpoint(max-tablet-landscape) {
    max-width: 100%;
  }
}
</style>
