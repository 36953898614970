import { useBreakpoints } from '@vueuse/core';

export const breakpointsConfig = {
    mobile: 0,
    tablet: 768,
    desktop: 1440,
};  

export const useSiteBreakpoints = () => {
  const breakpoints = useBreakpoints(breakpointsConfig);
  const isMobile = breakpoints.between('mobile', 'tablet');

  return { breakpoints, isMobile }
}