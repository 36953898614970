/**
 * The module responsible to handling Episerver specific state that is relevant
 * when editing content in edit mode.
 */

// mutation for the epiContext module
const EPI = 'EPI';
export const EPI_UPDATE = `${EPI}/UPDATE`;

const state = {
    inEditMode: false,
    isEditable: false
};

const mutations = {
    [EPI_UPDATE](state, newContext) {
        state.isEditable = newContext.isEditable;
        state.inEditMode = newContext.inEditMode;
    }
};

export const epiContext = {
    state,
    mutations
};
