<template>
  <div class="dashboard col-md-8 col-lg-9 py-0 py-lg-5 px-0 px-lg-3" data-testid="dashboard-csn-page">
    <MobileMenu />
    <div class="col-12">
      <div class="dashboard__card">
        <div class="row">
          <div class="col-12 mt-2">
            <h2>{{ model.name }}</h2>
            <p v-html="model.description.value" />
            <h4>Ditt/dina CSN-lån</h4>
          </div>
          <div v-if="pending" class="align-items-center justify-content-center d-flex flex-fill">
            <NdsLoader />
          </div>
          <div v-else-if="failed" class="col-12">
            <Reloader @reload="getCreditInfo" />
          </div>
          <template v-else>
            <div class="col-12 d-none d-md-block">
              <Csn v-if="creditReport.ctiCsnLoan" :cti-csn-loan="creditReport.ctiCsnLoan" />
            </div>
            <div class="col-12 d-block d-md-none">
              <CsnMobile v-if="creditReport.ctiCsnLoan != null" :cti-csn-loan="creditReport.ctiCsnLoan" />
            </div>
            <div
              v-if="creditReport.ctiCsnLoan === null || (!creditReport.ctiCsnLoan.hasInstallmentLoan && !creditReport.ctiCsnLoan.hasStudentLoan && !creditReport.ctiCsnLoan.hasStudentMeans)"
              class="col-12 mt-5">
              <p v-html="model.noCsnLoan.value" />
            </div>
          </template>
        </div>
      </div>
    </div>
    <RelatedArticles v-if="model.relatedArticles.length > 0" :articles="model.relatedArticles.expandedValue" />
    <MobileBottomMenu />
  </div>
</template>
<script setup>
import { useStore } from 'vuex';
import { computed, onMounted } from 'vue';
import { NdsLoader } from '@nds/vue';
import MobileMenu from '../components/MobileMenu.vue';
import MobileBottomMenu from '../components/MobileBottomMenu.vue';
import Csn from '../components/Csn.vue';
import CsnMobile from '../components/CsnMobile.vue';
import RelatedArticles from '../components/RelatedArticles.vue';
import { CUSTOMER_CREDIT_INFO_FETCH } from '../Scripts/store/modules/customerCreditInfoContex';
import { APP_SHOW_SIDEBAR } from '../Scripts/store/modules/appContext';
import Reloader from '../components/Reloader.vue';

const props = defineProps({
  model: Object
});

const store = useStore();
const creditReport = computed(() => store.state.customerCreditInfoContex.customerCreditInfo.creditReport);
const failed = computed(() => store.state.customerCreditInfoContex.failed);
const pending = computed(() => store.state.customerCreditInfoContex.pending);

onMounted(() => {
  document.title = props.model.name || "Mina tjänster";
  if (!store.state.customerCreditInfoContex.loaded) {
    store.dispatch(CUSTOMER_CREDIT_INFO_FETCH);
  }
  if (!store.state.appContext.showSideBar) {
    store.dispatch(APP_SHOW_SIDEBAR);
  }
});
const getCreditInfo = () => {
  store.dispatch(CUSTOMER_CREDIT_INFO_FETCH);
}
</script>
