import $ from 'jquery';
var $tipsarr = [];
var $randoms;
var $tipsListUlLi = $('.tiplist > ul > li');
var $tipsListResponsive = $('.tiplist--responsive');
var $tipsListResponsiveUl = $('.tiplist--responsive > ul');
var $tipsListResponsiveUlLi = $('.tiplist--responsive > ul > li');
var $tipsIdAttr = 'data-tipsid';
var $shuffleAttr = 'data-shuffle';

$tipsListUlLi.each(function () {
    $tipsarr.push($(this).attr($tipsIdAttr));
});

function pushtipsarr() {
    $tipsarr = [];
    $tipsListUlLi.each(function () {
        $tipsarr.push($(this).attr($tipsIdAttr));
    });
}

function shuffletipsarr($tipsarr) {
    pushtipsarr();

    var tipsarr_counter = $tipsarr.length,
        tipsarr_temp, tipsarr_index;

    while (tipsarr_counter--) {

        tipsarr_index = (Math.random() * tipsarr_counter) | 0;

        tipsarr_temp = $tipsarr[tipsarr_counter];
        $tipsarr[tipsarr_counter] = $tipsarr[tipsarr_index];
        $tipsarr[tipsarr_index] = tipsarr_temp;
    }

    return $tipsarr;
}

function switchTips() {
    if ($tipsListResponsive.length) {
        $randoms = shuffletipsarr($tipsarr);
        $randoms.length = $tipsListResponsive.attr($shuffleAttr);

        $($tipsListResponsiveUl, $tipsListResponsiveUlLi).hide();

        for (var i in $randoms) {
            $("[data-tipsid=" + $randoms[i] + "]").show();
        }
        $tipsListResponsiveUl.show();
    }

}

export default function tipsLenght() {
    if ($(window).width() < 768 && $tipsListResponsive.length) {
        $tipsListResponsive.attr($shuffleAttr, 2);
    } else if ($tipsListResponsive.length) {
        var tipsDefaultLength = $tipsListResponsive.attr($shuffleAttr);
        $tipsListResponsive.attr(tipsDefaultLength);
    }

    switchTips();
}

switchTips();

$(".js-tiplist__shuffle").click(function (e) {
    switchTips();
    e.preventDefault();
});