// Adjust ImageCard height to max height column
/*(function ($) {*/
import $ from 'jquery';
    function ImageCard() {
        this.articleBlockContainer = $('.block.articlecontentcontainerblock');
    }

    ImageCard.consts = {
        MARGIN_BOTTOM: 20
    };

    ImageCard.prototype = {
        refresh: function () {
            var self = this;
            this.articleBlockContainer.map(function (i, element) {
                var el = $(element);
                if (self.isResizableHeight(el)) {
                    self.resizeHeight(el);
                }
            });
        },

        resizeHeight: function (el) {
            var blockContainer = el.find(".row .block-container");

            this.adjustBlockContainerHeight(blockContainer);
        },

        isResizableHeight: function (el) {
            return el.find('.article-page.autoresize-height').length > 0;
        },

        getMarginAndHeightProps: function (cards) {
            return cards.map(function (i, card) {
                return {
                    height: $(card).height(),
                    marginBottom: parseInt($(card).parent().css('margin-bottom'))
                };
            });
        },

        adjustArticleBlockHeight: function (block) {
            var blockHeight = $(block).height();
            var blockParentHeight = $(block).closest('.block.articlecontentcontainerblock').height();

            var articleBlocks = $(block).find('.article-block');
            var orderFormBlocks = $(block).find('.orderformblock');

            if (blockHeight < blockParentHeight) {
                var blockCount = articleBlocks.length + orderFormBlocks.length;
                var diffHeight = blockCount > 0 ? (blockParentHeight - blockHeight) / blockCount : 0;
                articleBlocks.map(function (i, block) {
                    var h = $(block).find('.background-cover').height();
                    $(block).find('.background-cover').height(h + diffHeight);
                })
            }
        },

        adjustBlockContainerHeight: function (blockContainer) {
            var self = this;
            blockContainer.map(function (i, block) {
                self.adjustArticleBlockHeight(block);
            });
        },

        getSum: function (list, prop) {
            return list.length > 0 ? list.reduce(function (accumulator, currentValue) {
                return accumulator + currentValue[prop];
            }, 0) : 0;
        }
    };

    var imageCard = new ImageCard();
    imageCard.refresh();
/*}(jQuery));*/