export const getEndDateForProduct = ({ productName, purchasedProductPackages }) => {
    // TODO cleanup
    if (purchasedProductPackages && !!purchasedProductPackages.length && productName && productName !== '') {
        for (const product of purchasedProductPackages) {
            if (product.productPackage.name === productName) {
                const difference = product.endDate - new Date();
                return Math.ceil(difference / (1000 * 3600 * 24));
            }
        }
    }
    return '';
};

const PRODUCT_CATEGORIES = {
    ID_SKYDD: 'ID_SKYDD',
    KREDITBETYG: 'KREDITBETYG',
    UPPLYSNING: 'UPPLYSNING',
}

export const getIncludedProductFromPackage = (productPackage) => {
    return {
        isCreditWatchProduct: productPackage.category.toLowerCase().includes(PRODUCT_CATEGORIES.KREDITBETYG.toLowerCase()),
        isCustomerReportProduct: productPackage.category.toLowerCase().includes(PRODUCT_CATEGORIES.UPPLYSNING.toLowerCase()),
        isIdProtectionProduct: productPackage.category.toLowerCase().includes(PRODUCT_CATEGORIES.ID_SKYDD.toLowerCase()),
    }
};