<template>
  <div data-testid="minupplysning-remarks-tab">
    <div class="py-4">
      <h3 class="mb-4">
        <i class="far fa-comment-exclamation fa-18 mr-2" />Anmärkningar
      </h3>
      <div
        v-if="introText"
        v-html="introText.value"
      />
      <p
        v-if="report.creditReport.ctiConsumerPaymentInformation && report.creditReport.ctiConsumerPaymentInformation.historicalComplaintsSpecified && report.creditReport.ctiConsumerPaymentInformation.historicalComplaintsSpecified.length > 0"
      >
        Det finns <span
          class="font-weight-medium"
          data-testid="min-upplysning-inquiry-noTotal"
        >{{
          report.creditReport.ctiConsumerPaymentInformation.historicalComplaintsSpecified.length
        }} st</span>
        anmärkningar på totalt <span
          class="font-weight-medium"
          data-testid="min-upplysning-inquiry-noTotal-amount"
        >
          {{ complaintAmount }}</span>
        registrerade.
      </p>
    </div>
    <Remarks
      v-if="report.creditReport.ctiConsumerPaymentInformation != null"
      :show-end-date="false"
      :complaints="report.creditReport.ctiConsumerPaymentInformation.historicalComplaintsSpecified"
    />
    <RemarksKfm
      v-if="report.creditReport.ctiConsumerPaymentInformation != null"
      :complaints="report.creditReport.ctiConsumerPaymentInformation"
      :model="model"
    />
    <ZeroState
      v-else
      icon="far fa-comment-exclamation"
      text="Ingen anmärkning registrerad"
    />
    <span class="body-xsmall mb-0"><i
      class="far fa-book mr-1"
      data-testid="min-upplysning-remarks-src"
    />Källa:
      Kronofogden, tingsrätt och kreditgivare</span>
  </div>
</template>
<script setup>
import { computed } from 'vue';
import Remarks from '../../components/Remarks.vue';
import RemarksKfm from '../../components/RemarksKfm.vue';
import ZeroState from '../../components/myinformation/ZeroState.vue';
import { formatToSek } from '../../_helpers.js';

const props = defineProps({
  report: Object,
  introText: String,
  model: Object
});

const complaintAmount = computed(() => {
  if (props.report.creditReport?.ctiConsumerPaymentInformation?.historicalComplaintsSpecified) {
    return formatToSek(props.report.creditReport.ctiConsumerPaymentInformation.historicalComplaintsSpecified.map(({ complaintAmount }) => complaintAmount).reduce((a, b) => a + b, 0))
  }
  return formatToSek(0);
});

</script>