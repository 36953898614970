import axios from 'axios';

const basePath = '/onlineMonitoringApi';
const getRedirectUrlPath = `${basePath}/getRedirectUrl`;
const enrollPath = `${basePath}/enroll`;

const get = ({ url, headers, params }) => {
    return axios({
        method: 'get',
        url,
        params,
        headers,
    });
};

const post = ({ url, data, headers }) => {
    return axios({
        method: 'post',
        url,
        data,
        headers
    });
};

export const postEnroll = ({ monitoringBlockId, email }) => post(
    {
        url: enrollPath,
        data: {
            monitoringBlockId,
            email,
        },
        headers: { Accept: 'application/json' }
    });

export const getRedirectUrl = ({ monitoringBlockId }) => get({url: getRedirectUrlPath, params: { monitoringBlockId }, headers: { Accept: 'application/json'}});
