<template>
  <button
    class="badge-button"
    @click="handleOnClick"
  >
    <i :class="icon" />
    <span
      v-if="hasBadge"
      class="iconBadge"
    />
  </button>
</template>
<script setup>

const props = defineProps({
    icon: String,
    hasBadge: Boolean,
    onClick: Function,
});

const handleOnClick = () => {
  if(props.onClick) {
    props.onClick();
  }
}
</script>
<style scoped lang="scss">
@import "../../../../styles/abstracts/_variables";
@import "../../../../styles/abstracts/_mixins";
@import "../../../../styles/abstracts/_breakpoints";

.badge-button {
  background-color: transparent; 
  border: none; 
  color: white;
  min-width: 30px;
  cursor: pointer;

  i {
    .fa-bell {
      background-color: $night-rider;
      color: $white;
      font-size: 18px;
      right: 0;
      top: 0;
    }
  }
  .iconBadge {
    position: absolute;
    top: 0.625rem;
    right: 0.4rem;
    font-size: 0.5em;
    width: 8px;
    height: 8px;
    border-radius: 50%;
    background: $feedback-error;
  }

  @include breakpoint(max-tablet-portrait) {
    padding-top: 1rem;
    .iconBadge {
      top: 1.1rem;
      right: 1.2rem;
    }
  } 
}
</style>
