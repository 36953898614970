<template>
  <div
    id="remarks-table"
    class="col-12 dashboard__table"
    data-testid="min-upplysning-remarks-table"
  >
    <div class="row dashboard__table-header">
      <div class="col-2 dashboard__table-column">
        <h5 class="mb-0">
          Datum
        </h5>
      </div>
      <div class="col-3 dashboard__table-column">
        <h5 class="mb-0">
          Typ
        </h5>
      </div>
      <div
        class="dashboard__table-column"
        :class="showEndDate ? 'col-3':'col-5'"
      >
        <h5 class="mb-0">
          Fordringsägare, övrig info
        </h5>
      </div>
      <div class="col-2 dashboard__table-column text-right">
        <h5 class="mb-0">
          Belopp
        </h5>
      </div>
      <div
        v-if="showEndDate"
        class="col-2 dashboard__table-column text-right"
      >
        <h5 class="mb-0">
          Försvinner
        </h5>
      </div>
    </div>
    <div
      v-for="(complaint, index) in complaintsChunk"
      :key="`remarks_complaints_${index}`"
      class="row"
    >
      <div class="col-2 dashboard__table-column">
        <span
          v-if="complaint.complaintDate"
          class="d-block"
          data-testid="min-upplysning-remarks-table-date"
        >{{ FormatDate(complaint.complaintDate.timeStamp.value) }}</span>
        <span
          v-else
          class="d-block"
        >-</span>
      </div>
      <div
        class="col-3 dashboard__table-column"
        data-testid="min-upplysning-remarks-table-type"
      >
        {{ complaint.complaintTypeText }}
      </div>
      <div
        class="dashboard__table-column"
        :class="showEndDate ? 'col-3':'col-5'"
      >
        <span
          v-if="complaint.creditor"
          class="d-block"
          data-testid="min-upplysning-remarks-table-creditor"
        >
          {{ complaint.creditor }}
        </span>
        <span
          v-else
          class="d-block"
        >-</span>
      </div>
      <div class="col-2 dashboard__table-column font-weight-medium text-right">
        <span
          v-if="complaint.complaintAmount"
          class="d-block"
          data-testid="min-upplysning-remarks-table-amount"
        >
          {{ NumberFormat(complaint.complaintAmount) }} kr
        </span>
        <span
          v-else
          class="d-block"
        >-</span>
      </div>
      <div
        v-if="showEndDate"
        class="col-2 dashboard__table-column text-right"
      >
        <span
          v-if="complaint.complaintEndDate"
          class="d-block"
        >
          {{ FormatDate(complaint.complaintEndDate.timeStamp.value) }}
        </span>
        <span
          v-else
          class="d-block"
        >-</span>
      </div>
    </div>
    <Paging
      :margin-bottom="'mb-3'"
      :items="complaints"
      :items-per-page="10"
      :paging-enabled="true"
      @chunk="ShowSpan"
    />
  </div>
</template>
<script>
import {FormatDate, NumberFormat} from '../_helpers.js';
import Paging from "./Paging.vue";

export default {
  name: 'Remarks',
  components: {Paging},
  props: {
      complaints: Array,
      showEndDate: Boolean
  },
  data: function () {
    return {
      complaintsChunk: []
    }
  },
  methods: {
    ShowSpan(chunk) {
      this.complaintsChunk = chunk;
    },
    FormatDate: function (timestamp) {
      return FormatDate(timestamp);
    },
    NumberFormat: function (num) {
      return NumberFormat(num);
    }
  }
}
</script>