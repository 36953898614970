<template>
  <div
    v-if="showSideBar"
    data-testid="side-bar"
  >
    <ul class="sidebar creditwatch menu pl-0">
      <li class="no-hover sidebar-notifications-container">
        <a
          class="btn-enento-icon-light"
          href="#"
          data-testid="toggle-sidabar-button"
          @click="onToggleSideBar"
        >
          <i class="far fa-arrow-left fa-fw" />
        </a>
        <Notifications />
      </li>
      <li
        class="py-1 px-2 mt-3 mb-4 w-100 active"
        data-testid="SideBarCreditWatch"
      >
        <RouterLink
          to="/mina-tjanster/kreditkollen/min-ekonomi/krediter"
          class="d-flex "
        >
          <span class="input-group-text justify-content-center circle-product border-0">
            <i class="fas fa-analytics fa-fw" />
          </span>
          <span class="menu-item-name">Kreditkollen</span>
        </RouterLink>
      </li>
      <li>
        <hr style="border-bottom:solid 1px #555;">
      </li>
      <li
        v-for="(menuItem, index) in menu"
        :key="`side_bar_menu_item_${index}`"
        :class="(menuItem.Url === router.currentRoute.value.path && menuItem.ChildPages.length === 0) ? 'active p-2' : 'p-2' + (menuItem.Url === '/mina-tjanster/kreditkollen/min-ekonomi/krediter' ? ' no-hover' : '')"
      >
        <RouterLink
          :to="menuItem.Url"
          class="menuCategory d-block"
          :data-testid="menuItem.Name"
        >
          <i :class="menuItem.FontAwesomeClass + ' fa-fw'" />
          <span class="menu-item-name">{{ menuItem.Name }}</span>
        </RouterLink>
        <ul class="sidebar-submenu pl-0">
          <li
            v-for="(childPage, index) in menuItem.ChildPages"
            :key="`side_bar_router_link_${index}`"
            :class="(childPage.Url === router.currentRoute.value.path) ? 'active py-2' : 'py-2'"
          >
            <RouterLink
              :to="childPage.Url"
              class="menuCategory d-block pl-4"
              :data-testid="childPage.Name"
            >
              <i :class="childPage.FontAwesomeClass + ' fa-fw'" />
              <span class="menu-item-name">{{ childPage.Name }}</span>
            </RouterLink>
          </li>
        </ul>
      </li>
    </ul>
  </div>
</template>
<script setup>
import { useStore } from 'vuex';
import { useRouter } from 'vue-router';
import { computed } from 'vue';
import Notifications from './Notifications.vue';
import { APP_TOGGLE_SIDEBAR } from '../Scripts/store/modules/appContext';

const store = useStore();
const router = useRouter();
const showSideBar = computed(() => store.state.appContext.showSideBar);
const menu = computed(() => store.state.appContext.menu.mobileMenu);

const onToggleSideBar = () => {
  store.dispatch(APP_TOGGLE_SIDEBAR);
}

</script>
<style lang="scss" scoped>
@import "@/../../styles/abstracts/_mixins.scss";
@import "@/../../styles/abstracts/_variables.scss";
@import "@/../../styles/abstracts/_breakpoints.scss";

.sidebar {
  list-style-type: none;
  background-color: $night-rider;

  @include breakpoint(max-tablet-portrait) {
    display: none;
  }

  hr {
    border-color: $emperor;
  }

  li {
    position: relative;

    a {
      color: $gallery;

      @include attention() {
        text-decoration: none;
      }

    }

    &:not(.active) {
      @include attention() {
        border-radius: 8px;
        background-color: $emperor-hover;
      }
    }

    .has-notification:after {
      content: "";
      display: inline-block;
      margin-left: 15px;
      width: 8px;
      height: 8px;
      background: $feedback-error;
      border-radius: 50%;
    }

    &.active {
      background-color: $emperor;
      border-radius: 8px;

      >a {
        .menu-item-name {
          text-decoration: none;
        }

        .circle-product {
          background-color: $ocean !important;

          i {
            color: #fff !important;
          }
        }

        i {
          color: $orangegold;
        }
      }
    }
  }

  .sidebar-submenu {
    font-size: 0.875rem;
    list-style-type: none;

    li:first-child {
      margin-top: 1.5rem;
    }

    li:last-child {
      padding-bottom: 0;
    }
  }
}
</style>
<style scoped lang="scss">
.no-hover:hover {
  background-color: unset !important;
}
</style>