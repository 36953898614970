<template>
  <div>
    <div
      id="credits-table"
      class="col-12 dashboard__table"
      data-testid="min-upplysning-inquiry-table"
    >
      <div class="row dashboard__table-header">
        <div class="col-4 dashboard__table-column">
          <h5 class="mb-0">
            Datum
          </h5>
        </div>
        <div class="col-5 dashboard__table-column">
          <h5 class="mb-0">
            Frågeställare
          </h5>
        </div>
        <div class="col-3 dashboard__table-column text-right">
          <h5 class="mb-0">
            Belopp
          </h5>
        </div>
      </div>
      <div
        v-for="(inquiry, index) in consumerInquiriesChunk"
        :key="`consumer_inquires_${index}`"
        class="row"
      >
        <div
          class="col-4 col-lg-4 dashboard__table-column"
          :data-testid="`min-upplysning-inquiry-table-date-${index}`"
        >
          {{ new Date(inquiry.dateOfInquiry.timeStamp.value).toLocaleDateString('sv-SE') }}
        </div>
        <div
          class="col-4 col-lg-5 dashboard__table-column"
          :data-testid="`min-upplysning-inquiry-table-inquirer-${index}`"
        >
          {{ inquiry.nameInquirer }}
        </div>
        <div
          class="col-4 col-lg-3 dashboard__table-column font-weight-medium text-right"
          :data-testid="`min-upplysning-inquiry-table-amount-${index}`"
        >
          {{ inquiry.creditAmount == 0 ? "-" : NumberFormat(inquiry.creditAmount) + " kr" }}
        </div>
      </div>
    </div>
    <Paging
      :items="consumerInquiries.inquiriesMinucOnly"
      :items-per-page="itemsPerPage"
      :paging-enabled="true"
      @chunk="ShowSpan"
    />
  </div>
</template>
<script>
import {NumberFormat} from '../_helpers.js';
import Paging from './Paging.vue';

export default {
  name: 'ConsumerInquires',
  components: {
    Paging
  },
  props: {
    consumerInquiries: {},
  },
  data: function () {
    return {
      itemsPerPage: 5,
      activeSpan: 1,
      consumerInquiriesChunk: []
    }
  },
  methods: {
    ShowSpan(chunk) {
      this.consumerInquiriesChunk = chunk;
    },
    NumberFormat: function (sum) {
      return NumberFormat(sum);
    }
  }
}
</script>