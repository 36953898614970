<template>
  <div
    v-if="meta"
    class="order-form-block"
    :class="isLoggedin && 'order-form--ua'"
    data-testid="order-form-block"
  >
    <div
      v-if="meta.priceInformation?.isSplash"
      class="price-splash"
    >
      <div class="price-number">
        {{ meta.priceInformation.price }}
      </div>
      <div class="price-description">
        {{ meta.priceInformation.priceSuffix }}
      </div>
    </div>
    <div class="order-form-header">
      <h3>{{ meta.header }}</h3>
      <h3
        v-if="meta.priceInformation?.isInline"
        class="price-inline"
      >
        {{ `${meta.priceInformation.price} ${meta.priceInformation.priceSuffix}` }}
      </h3>
      <p>{{ meta.description }}</p>
    </div>
    <div
      v-if="isLoggedin"
      class="loggedin-text"
    >
      <hr style="width: 50%;">
      <p>Du är inloggad som<br><strong>{{ customerEssentials?.customerFullName }}</strong></p>
      <a
        :href="meta.url"
        class="btn btn-default btn-block btn-standard btn-lg"
      >{{ meta.buttonLabel }}</a>
    </div>
    <form
      v-else
      role="form"
      method="POST"
    >
      <div class="order-form-group">
        <label for="email">{{ formModel.email.label }}</label>
        <input
          id="email"
          v-model="form.email"
          type="text"
          class="form-control"
          :class="v$.email.$error ? 'is-invalid' : ''"
          :placeholder="formModel.email.placeholder"
          :aria-describedby="formModel.email.ariaLabel"
          @keyup="onDataChange"
        >
        <small
          v-if="form.email !== '' && v$.email.email.$invalid && dataChanged"
          class="is-invalid"
        >
          {{ v$.email.email.$message }}
        </small>
      </div>
      <div class="order-form-group">
        <label for="ssn">{{ formModel.ssn.label }}</label>
        <input
          id="ssn"
          v-model="form.ssn"
          type="text"
          class="form-control"
          :class="v$.ssn.$error ? 'is-invalid' : ''"
          :placeholder="formModel.ssn.placeholder"
          :aria-describedby="formModel.ssn.ariaLabel"
          @keyup="onDataChange"
        >
        <small
          v-if="form.ssn !== '' && v$.ssn.valid_cord.$invalid && dataChanged"
          class="is-invalid"
        >
          {{ v$.ssn.valid_cord.$message }}
        </small>
        <small
          v-else-if="form.ssn !== '' && v$.ssn.valid_social.$invalid && dataChanged"
          class="is-invalid"
        >
          {{ v$.ssn.valid_social.$message }}
        </small>
      </div>
      <div class="order-form-group">
        <label for="phoneNumber">{{ formModel.phoneNumber.label }}</label>
        <input
          id="phoneNumber"
          v-model="form.phoneNumber"
          type="text"
          class="form-control"
          :class="v$.phoneNumber.$error ? 'is-invalid' : ''"
          :placeholder="formModel.phoneNumber.placeholder"
          :aria-describedby="formModel.phoneNumber.ariaLabel"
          @keyup="onDataChange"
        >
        <small
          v-if="form.phoneNumber !== '' && v$.phoneNumber.valid_cellphone.$invalid && dataChanged"
          class="is-invalid"
        >
          {{ v$.phoneNumber.valid_cellphone.$message }}
        </small>
      </div>
      <button
        type="submit"
        class="btn btn-default btn-block btn-standard btn-lg"
        @click="onSubmit"
      >
        {{ meta.buttonLabel }}
      </button>
    </form>
  </div>
</template>
<script setup>
import { computed, reactive, watch, ref } from 'vue';
//import { useRouter } from 'vue-router';
import { useVuelidate } from '@vuelidate/core';
import { useStore } from 'vuex';
import { email, helpers } from '@vuelidate/validators';
import { CHECKOUT_SET_CUSTOMER } from '../../Scripts/store/modules/checkoutContext';
import { isValidSwedishCellPhone, isValidSocialSecNumber, isValidCoordinationNumber } from '../../utils/validators';
import { getIncludedProductFromPackage } from '../../utils/productUtils';
import helperTexts from '../../../../json/helperTexts.json';

const props = defineProps({
    model: Object,
});

const store = useStore();
//const router = useRouter();
const isLoggedin = computed(() => store.state.appContext.isLoggedin);
const loaded = computed(() => store.state.customerInfoContex.loaded);
const customerEssentials = computed(() => store.state.customerInfoContex.customerEssentials);
const headerTextAlignCSS = computed(() => props.model.isCenteredHeader ? 'center' : 'right');


const getMeta = () => {
    const includedProductInPackage = getIncludedProductFromPackage(props.model?.productPackage);
    const priceInformation = {
        isSplash: props.model.showPriceInSplash && !props.model.hidePrice,
        isInline: !props.model.showPriceInSplash && !props.model.hidePrice,
        price: props.model.productPackage.price,
        priceSuffix: props.model.priceSuffix,
    }
    if (store.state.appContext.isLoggedin) {
        if (store.state.customerInfoContex.customerEssentials.displayCustomerReportProduct && includedProductInPackage.isCustomerReportProduct) {
            return {
                header: props.model.headerProductOwned || props.model.productPackage.name,
                description: props.model.descriptionProductOwned,
                url: '/mina-tjanster/kreditkollen/min-upplysning',
                buttonLabel: props.model?.linkToProductText ? props.model.linkToProductText.replace('{x}', props.model?.productPackage.name) : 'Gå till Min Upplysning',
            };
        }
        if (store.state.customerInfoContex.customerEssentials.displayCreditWatchProduct && includedProductInPackage.isCreditWatchProduct) {
            return {
                header: props.model.headerProductOwned || props.model.productPackage.name,
                description: props.model.descriptionProductOwned,
                url: '/mina-tjanster/kreditkollen',
                buttonLabel: props.model?.linkToProductText ? props.model.linkToProductText.replace('{x}', props.model?.productPackage.name) : 'Gå till Kreditkollen',
            };
        }
        if (store.state.customerInfoContex.customerEssentials.displayIdProtectionProduct && includedProductInPackage.isIdProtectionProduct) {
            return {
                header: props.model.headerProductOwned || props.model.productPackage.name,
                description: props.model.descriptionProductOwned,
                url: '/mina-tjanster/id-skydd',
                buttonLabel: props.model?.linkToProductText ? props.model.linkToProductText.replace('{x}', props.model?.productPackage.name) : 'Gå till ID skydd',
            };
        }
        return {
            header: props.model.header,
            description: props.model.description,
            url: props.model.checkoutUrl,
            buttonLabel: props.model.submitButtonText,
            priceInformation,
        };
    }
    return {
        header: props.model.header,
        description: props.model.description,
        url: props.model.checkoutUrl,
        buttonLabel: props.model.submitButtonText,
        priceInformation,
    };
}

const meta = reactive(getMeta());

const dataChanged = ref(false);
const formModel = {
    email: {
        label: 'E-post',
        placeholder: helperTexts.placeholderEmail,
        ariaLabel: 'emailHelp',
    },
    ssn: {
        label: 'Personnummer',
        placeholder: helperTexts.placeholderSSN,
        ariaLabel: 'ssnHelp',
    },
    phoneNumber: {
        label: 'Mobiltelefon',
        placeholder: helperTexts.placeholderPhone,
        ariaLabel: 'phoneHelp',
    },
};
const form = reactive({
    ssn: customerEssentials.value?.customerSocSecNo || '',
    email: customerEssentials.value?.customerEmail || '',
    phoneNumber: customerEssentials.value?.customerPhone || ''
});

const rules = computed(() => {
    return {
        phoneNumber: {
            valid_cellphone: {
                $validator: isValidSwedishCellPhone,
                $message: helperTexts.validationMessageMobileNumber
            },
        },
        ssn: {
            valid_social: {
                $validator: isValidSocialSecNumber,
                $message: helperTexts.validationMessageSSN
            },
            valid_cord: {
                $validator: isValidCoordinationNumber,
                $message: helperTexts.validationMessageSSNCoordination
            },
        },
        email: {
            email: helpers.withMessage(helperTexts.validationMessageEmail, email)
        },
    }
})

const v$ = useVuelidate(rules, form);

watch(isLoggedin, () => {
    meta.value = getMeta();
});

watch(loaded, () => {
    meta.value = getMeta();
});

const onDataChange = () => {
    if (!v$.value.$error) {
        dataChanged.value = true;
    } else {
        dataChanged.value = false;
    }
};

const onSubmit = (e) => {
    e.preventDefault();
    store.dispatch(CHECKOUT_SET_CUSTOMER, {
        email: form.email,
        ssn: form.ssn,
        phoneNumber: form.phoneNumber,
    });
    // router.push({ path: meta.url })
    window.location.href = meta.url;
}

</script>
<style lang="scss" scoped>
@import "@/../../styles/abstracts/_breakpoints.scss";
@import "@/../../styles/abstracts/_mixins.scss";
@import "@/../../styles/abstracts/_variables.scss";

.order-form-block {
    background: $night-rider;
    position: relative;
    margin-bottom: 20px;
    padding: 20px;
    background: rgba(51, 51, 51, 0.8);
    border-radius: 4px;

    .order-form-header {
        text-align: v-bind(headerTextAlignCSS);

        h3 {
            color: $white;
            margin: 0 0 6px;
            padding: 0;
        }

        p {
            color: $white;
            margin: 0 0 16px;
        }

    }

    .price-inline {
        color: $tahiti-gold;
    }

    .price-splash {
        width: 65px;
        height: 65px;
        border-radius: 50%;
        background-color: $tahiti-gold;
        position: absolute;
        top: -16px;
        right: -14px;
        color: $white;
        text-align: center;

        .price-number {
            font-size: 24px;
            font-family: Gilroy-SemiBold, sans-serif, sans-serif;
            line-height: 24px;
            display: inline-block;
            margin-top: 13px;
        }

        .price-description {
            font-size: 10px;
            line-height: 10px;
        }
    }

    button {
        border: 0;
        outline: 0;
        width: 100%;

        @include breakpoint(max-tablet-portrait) {
            margin-top: 8px;
        }
    }

    .order-form-group {
        label {
            color: $white;
            font-size: 12px;
        }

        margin: 0 0 15px;

        input {
            @include placeholder {
                font-style: italic;
                color: $gray;
            }

            font-size: 16px;
            margin: 0;
            outline: 0 none;
            padding: 8px;
            width: 100%;
        }

        .is-invalid {
            color: $feedback-error;
        }
    }

    .loggedin-text {
        color: $white;
        text-align: v-bind(headerTextAlignCSS);
    }
}
</style>
