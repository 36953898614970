const hasNoEntries = (entries) => entries.map(({ usedCredits }) => !usedCredits).every(Boolean);
const sortSummaryByTimestamp = (summary) => (summary.sort((a, b) => a.date.timeStamp.value - b.date.timeStamp.value));
const unitOfAmount = "SEK"
const formatChartDataReturnValue = (entries) => {
    const isEmpty = hasNoEntries(entries);
    return { entries: isEmpty ? [] : entries, isEmpty: isEmpty };
}
const emptyEntriesReturnValue = { entries: [], isEmpty: true };


export const getCreditChartData = (creditReport) => {
    if (creditReport?.ctiUnsecuredCreditSummary?.unsecureCreditSummaries) {
        return formatChartDataReturnValue(sortSummaryByTimestamp(creditReport.ctiUnsecuredCreditSummary.unsecureCreditSummaries).map((chartData) => ({
            timestamp: chartData.date.timeStamp.value,
            usedCredits: chartData.usedCreditOnAccountCredits,
            grantedCredits: chartData.limitAccountCredits,
            unitOfAmount,
        })));
    }
    return emptyEntriesReturnValue;
}

export const getMortgagesChartData = (creditReport) => {
    if (creditReport?.ctiMortgagesSummary?.mortgagesSummaries) {
        return formatChartDataReturnValue(sortSummaryByTimestamp(creditReport.ctiMortgagesSummary.mortgagesSummaries).map((chartData) => ({
            timestamp: chartData.date.timeStamp.value,
            usedCredits: chartData.usedCreditPropertyMortgage + chartData.usedCreditTenantOwnershipMortgage,
            grantedCredits: chartData.limitPropertyMortgage + chartData.limitTenantOwnershipMortgage,
            unitOfAmount,
        })));
    }
    return emptyEntriesReturnValue;
}

export const getHirePurchaseData = (creditReport) => {
    if (creditReport?.ctiUnsecuredCreditSummary?.unsecureCreditSummaries) {
        return formatChartDataReturnValue(sortSummaryByTimestamp(creditReport.ctiUnsecuredCreditSummary.unsecureCreditSummaries).map((chartData) => ({
            timestamp: chartData.date.timeStamp.value,
            usedCredits: chartData.usedCreditHirePurchase,
            grantedCredits: chartData.limitHirePurchaseCredits,
            unitOfAmount,
        })));
    }
    return emptyEntriesReturnValue;
}


export const getUnsecuredLoansChartData = (creditReport) => {
    if (creditReport?.ctiUnsecuredCreditSummary?.unsecureCreditSummaries) {
        return formatChartDataReturnValue(sortSummaryByTimestamp(creditReport.ctiUnsecuredCreditSummary.unsecureCreditSummaries).map((chartData) => ({
            timestamp: chartData.date.timeStamp.value,
            usedCredits: chartData.usedCreditUnsecured,
            grantedCredits: chartData.limitUnsecuredCredits,
            unitOfAmount,
        })));
    }
    return emptyEntriesReturnValue;
}