<template>
  <div
    :id="id"
    class="modal fade"
    tabindex="-1"
    role="dialog"
    aria-labelledby="info-modal-label"
    aria-hidden="true"
    data-testid="info-modal"
  >
    <div
      class="modal-dialog modal-dialog-centered"
      role="document"
    >
      <div class="modal-content">
        <div class="modal-header d-flex align-items-baseline">
          <h3
            id="info-modal-label"
            class="modal-title"
          >
            <i
              v-if="icon"
              :class="icon"
            />{{ title }}
          </h3>
          <i
            class="far fa-times hover-pointer"
            data-dismiss="modal"
            aria-label="Close"
          />
        </div>
        <div
          class="modal-body"
          v-html="body"
        />
        <div class="modal-footer">
          <button
            type="button"
            class="btn btn-enento-blue"
            data-dismiss="modal"
          >
            Stäng
          </button>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
export default {
    name: 'InfoModal',
    props: {
        title: String,
        body: String,
        id: String,
        icon: String
    }
}
</script>
<style lang="scss">
@import "@/../../styles/abstracts/_variables.scss";
@import "@/../../styles/abstracts/_breakpoints.scss";

.form-group label,
.modal-title {
    color: #000;
}

.modal {
    padding-right: 0 !important;

    .modal-content {
        .modal-body {
            p {
                a {
                    text-decoration: underline !important;
                }
            }
        }
    }
}

@include breakpoint(max-phone) {
    .modal-footer {
        display: flex;
        flex-direction: row;

        button {
            width: 100%;

            &:last-child {
                margin-left: 0 !important;
                margin-top: 1rem;
            }
        }
    }
}
</style>