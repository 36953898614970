<template>
  <div class="container-fluid site-header position-md-sticky" data-testid="site-header">
    <div class="site-header__top row">
      <div class="container-xl">
        <div class="row">
          <div class="col-12 d-flex align-items-center px-xl-0">
            <div class="d-md-none">
              <a href="/" @click="onCloseCascadeMenu()" title="Min UC" class="navbar-brand site-header__logo" aria-label="home">Min UC</a>
            </div>

            <div v-if="!isLoggedin" class="site-header__top-switch-mobile d-md-none">
              <a href="https://www.uc.se" @click="onCloseCascadeMenu()" class="color-gray" aria-label="companies">
                <span class="body-xsmall">Företag</span>
              </a>
              <span class="color-gray px-1">|</span>
              <a href="/" @click="onCloseCascadeMenu()" class="color-white" aria-label="private">
                <span class="body-xsmall">Privatperson</span>
              </a>
            </div>

            <div class="d-none d-md-inline-flex">
              <a href="https://www.uc.se" @click="onCloseCascadeMenu()" class="site-header__switch-link" aria-label="companies">Företag</a>
              <a href="/" @click="onCloseCascadeMenu()"
                class="site-header__switch-link site-header__switch-link--active" aria-label="private">Privatperson</a>
            </div>

            <div class="ml-auto py-1">
              <div v-if="isLoggedin && isMobile" class="button-spacing-wrapper">
                <NdsButton size="small" variant="secondary" onBg="dark"
                  :prefix="{ prefix: 'far', iconName: 'cog' }" data-testid="installningar-heading-btn" aria-label="my settings"
                  @click="goTo('/mina-installningar')" />
                <NdsButton :theme="theme" variant="primary-pill" onBg="dark" size="small"
                  :prefix="{ prefix: 'far', iconName: 'bars' }" @click="onToggleCascadeMenu()" aria-label="open menu"
                  data-testid="hamburger-menu-mobile-button" />
              </div>

              <div v-else-if="isLoggedin" class="button-spacing-wrapper">
                <NdsButton size="small" variant="primary" theme="minuc" onBg="dark"
                  :prefix="{ prefix: 'fal', iconName: 'user' }" data-testid="mina-tjanster-btn" aria-label="my services"
                  @click="goTo('/mina-tjanster')">
                  Mina tjänster
                </NdsButton>
                <NdsButton size="small" variant="secondary" onBg="dark"
                  :prefix="{ prefix: 'far', iconName: 'cog' }" data-testid="installningar-heading-btn" aria-label="my settings"
                  @click="goTo('/mina-installningar')" />
                <NdsButton size="small" variant="secondary" onBg="dark" data-testid="logout-button" aria-label="logout"
                  @click="onLogoutClicked">
                  Logga ut
                </NdsButton>
              </div>

              <div v-else-if="!isLoggedin && isMobile" class="button-spacing-wrapper">
                <NdsButton :href="loginLink" @click="onCloseCascadeMenu()" size="small" variant="primary" aria-label="login"
                  theme="minuc" onBg="dark" :prefix="{ prefix: 'far', iconName: 'user-lock' }"
                  data-testid="mobile-login-button" />
                <NdsButton :theme="theme" variant="primary-pill" onBg="dark" size="small"
                  :prefix="{ prefix: 'far', iconName: 'bars' }" @click="onToggleCascadeMenu()" aria-label="open menu"
                  data-testid="hamburger-menu-mobile-button" />
              </div>

              <NdsButton v-else :href="loginLink" @click="onCloseCascadeMenu()" size="small" aria-label="login"
                variant="primary" theme="minuc" data-testid="login-button">
                Logga in
              </NdsButton>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script setup>
import { computed } from 'vue';
import { useStore } from 'vuex';
import { useRouter } from 'vue-router';
import { NdsButton } from '@nds/vue';
import { clearPersistedStorage } from '../Scripts/store/storeUtils';
import { isSpaRoute } from '../utils/routesUtils';
import { APP_TOGGLE_CASCADE_MENU, APP_CLOSE_CASCADE_MENU } from '../Scripts/store/modules/appContext';
import { useSiteBreakpoints } from '../utils/breakpointsUtils';

const store = useStore();
const router = useRouter();

const isLoggedin = computed(() => store.state.appContext.isLoggedin);
const loginLink = computed(() => store.state.appContext.menu.desktopMenu?.loginLink || 'logga-in');
const theme = computed(() => isLoggedin.value ? 'minuc' : 'enento');
const { isMobile } = useSiteBreakpoints();

const onLogoutClicked = () => {
  clearPersistedStorage();
  window.location.href = '/Logout';
}
const onToggleCascadeMenu = () => {
  store.dispatch(APP_TOGGLE_CASCADE_MENU);
}
const onCloseCascadeMenu = () => {
  store.dispatch(APP_CLOSE_CASCADE_MENU);
}
const goTo = (url) => {
  store.dispatch(APP_CLOSE_CASCADE_MENU);
  if(router) {
    if(isSpaRoute(router.currentRoute.value.fullPath)) {
      return router.push({ path: url });
    }
  }  
  return location.href = url;
}

</script>
<style scoped>
.button-spacing-wrapper {
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 1rem;
}
</style>