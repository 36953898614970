export default {

    emaileryMail: {
        required: true,
        email: true
    },
    policyCheck: {
        required: true
    }
}
