import { getUmbrellaMembers } from '../../providers/frontendApiControllerProviders';

const UMBRELLA_MEMBERS = 'UMBRELLA_MEMBERS';
export const UMBRELLA_MEMBERS_SET_RESET = `${UMBRELLA_MEMBERS}/SET_RESET`;
export const UMBRELLA_MEMBERS_FETCH = `${UMBRELLA_MEMBERS}/FETCH`;
export const UMBRELLA_MEMBERS_SET_ID = `${UMBRELLA_MEMBERS}/SET_ID`;
const UMBRELLA_MEMBERS_RESET = `${UMBRELLA_MEMBERS}/RESET`;
const UMBRELLA_MEMBERS_UPDATE = `${UMBRELLA_MEMBERS}/UPDATE`;
const UMBRELLA_MEMBERS_FAILED = `${UMBRELLA_MEMBERS}/FAILED`;
const UMBRELLA_MEMBERS_PENDING = `${UMBRELLA_MEMBERS}/PENDING`;
const UMBRELLA_MEMBERS_UPDATE_ID = `${UMBRELLA_MEMBERS}/UPDATE_ID`;

const emptyUmbrellaMembers = {
    invitations: [],
    members: [],
    umbrella: {},
    purchasedProductPackage: {}
};

const emptyState = {
    umbrellaMembers: emptyUmbrellaMembers,
    ownedUmbrellas: null,
    isInGroup: false,
    loaded: false,
    failed: false,
    pending: false,
    umbrellaProductId: null,
};

const state = emptyState;

const mutations = {
    [UMBRELLA_MEMBERS_UPDATE](state, {
        umbrellaMembers,
        ownedUmbrellas,
        isInGroup,
    }) {
        state.umbrellaMembers = umbrellaMembers;
        state.ownedUmbrellas = ownedUmbrellas;
        state.isInGroup = isInGroup;
        state.loaded = true;
        state.failed = false;
        state.pending = false;
    },
    [UMBRELLA_MEMBERS_PENDING](state) {
        state.umbrellaMembers = emptyUmbrellaMembers;
        state.loaded = false;
        state.failed = false;
        state.pending = true;
    },
    [UMBRELLA_MEMBERS_FAILED](state) {
        state.umbrellaMembers = emptyUmbrellaMembers;
        state.loaded = true;
        state.failed = true;
        state.pending = false;
    },
    [UMBRELLA_MEMBERS_RESET](state) {
        // eslint-disable-next-line no-unused-vars
        state = emptyState;
    },
    [UMBRELLA_MEMBERS_UPDATE_ID](state, newModel) {
        state.umbrellaProductId = newModel.umbrellaProductId
    },
};

const actions = {
    [UMBRELLA_MEMBERS_SET_ID]({ commit }, { umbrellaProductId }) {
        return commit(UMBRELLA_MEMBERS_UPDATE_ID, { umbrellaProductId });
    },
    [UMBRELLA_MEMBERS_SET_RESET]({ commit }) {
        return commit(UMBRELLA_MEMBERS_RESET);
    },
    [UMBRELLA_MEMBERS_FETCH]({ commit }, { umbrellaProductId }) {
        commit(UMBRELLA_MEMBERS_PENDING);
        return getUmbrellaMembers({ umbrellaProductId }).then(response => {
            if (response.data) {
                commit(UMBRELLA_MEMBERS_UPDATE, {
                    umbrellaMembers: response.data.UmbrellaMembers,
                    ownedUmbrellas: response.data.OwnedUmbrellas,
                    isInGroup: response.data.IsInGroup,
                });
            }
        }).catch(() => {
            commit(UMBRELLA_MEMBERS_FAILED);
        });
    },
};

export const umbrellaMembersContext = {
    state,
    mutations,
    actions
};
