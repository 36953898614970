<template>
  <div class="widget-bottomsticky fixed-bottom bg-white p-4 d-md-none">
    <div class="d-flex row align-items-center">
      <div class="col-8 pl-2">
        <p
          v-if="model.title"
          class="body-large font-weight-medium gilroy-bold text-left mb-0"
        >
          {{ model.title }}
        </p>
        <p
          v-if="model.price"
          class="text-left color-darkocean font-weight-medium mb-3"
        >
          {{ model.price }}
        </p>
      </div>
      <div class="col-4 pr-2">
        <a
          v-if="model.url"
          :href="model.url"
          class="btn btn-enento-primary w-100 font-weight-bold"
        >
          {{ model.urlLabel }}
        </a>
      </div>
    </div>
  </div>
</template>
<script setup>
import { ref, onMounted, watch } from 'vue';
import { useWindowSize, useWindowScroll } from '@vueuse/core'

defineProps({
    model: Object,
})

const { y: windowY } = useWindowScroll();
const { width: windowWidth } = useWindowSize();

const displayWidgetStyle = ref('none');

onMounted(() => {
    //mountedComplete.value = true;
});

watch(windowY, () => {
    checkDisplayWidget();
});

watch(windowWidth, (newValue, oldValue) => {
    if(newValue !== oldValue) {
        checkDisplayWidget();
    }

});

const checkDisplayWidget = () => {
    // we want to show the widget as user scrolls down but not on the bottom on the page so it hides the footer
    if (windowY.value >= 500 && windowY.value <= document.body.scrollHeight - 1000) {
        if (displayWidgetStyle.value !== 'block') {
            return displayWidgetStyle.value = 'block';
        }
    } else if (displayWidgetStyle.value !== 'none') {
        return displayWidgetStyle.value = 'none';
    }
}

</script>
<style lang="scss" scoped>
.widget-bottomsticky {
    display: v-bind(displayWidgetStyle);
    z-index: 15;
    box-shadow: 0px 3px 6px 0px rgba(0, 0, 0, 0.15), 0px -2px 24px 0px rgba(0, 0, 0, 0.15);
}
</style>