import orderForm from './orderForm'
import orderLayerForm from './orderLayerForm'
import paymentForm from './paymentForm'
import mySettingsForm from './mySettingsForm'
import signupForm from './signupForm'

export default {
    "order-form": orderForm,
    "order-layer-form": orderLayerForm,
    "payment-form": paymentForm,
    "my-settings-form": mySettingsForm,
    "signup-form": signupForm
}  