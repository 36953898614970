<template>
  <div
    class="dashboard col-md-8 col-lg-9 py-0 py-lg-5 px-0 px-lg-3"
    data-testid="dashboard-financial-skills-article-page"
  >
    <div class="financial-article">
      <div class="breadcrumbs-row col-12">
        <div
          class="breadcrumbs"
          style="margin-left:10px;"
        >
          <span class="first-level-page-title">
            <RouterLink
              to="/mina-tjanster/kreditkollen/Ekonomikunskap"
              class="article-back-link"
            >
              <i class="fal fa-arrow-left" />
            </RouterLink>
          </span>
        </div>
      </div>
      <div class="dashboard__card col-12 pb-2">
        <div class="top-image">
          <img :src="model.topImage.expandedValue.url">
        </div>
        <div class="col-12 large-padding mobile mt-3">
          <a
            href="#"
            class="article-back-link"
            @click="router.back()"
          >
            <i class="far fa-arrow-left" />
          </a>
        </div>
        <div class="col-12 large-padding">
          <h1>{{ model.headline.value }}</h1>
          <div
            v-if="model.selectedTags != null"
            class="tag-label-container"
          >
            <span
              v-for="(tag, index) in model.selectedTags.value.split(',')"
              :key="`financial_skill_tag_${index}`"
              class="tag-label"
            >
              {{ tag }}
            </span>
          </div>
          <div
            class="article-ingress"
            v-html="model.description.value"
          />
        </div>
      </div>
      <RelatedArticles :articles="model.relatedArticles.expandedValue" />
    </div>
    <MobileBottomMenu />
  </div>
</template>
<script setup>
import { useStore } from 'vuex';
import { onMounted } from 'vue';
import { useRouter } from 'vue-router';
import RelatedArticles from '../components/RelatedArticles.vue';
import MobileBottomMenu from '../components/MobileBottomMenu.vue';
import { APP_SHOW_SIDEBAR } from '../Scripts/store/modules/appContext';

const props = defineProps({ model: Object });
const store = useStore();
const router = useRouter();

onMounted(() => {
    document.title = props.model.name || "Mina tjänster";
    if (!store.state.appContext.showSideBar) {
        store.dispatch(APP_SHOW_SIDEBAR);
    }
});
</script>
