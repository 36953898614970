const pattern = /^\d{6}(\d{2})?[+-]?\d{4}$/;
const hasCorrectChecksum = (input) => {
    const sum = input
        .split('')
        .reverse()
        .map(Number)
        .map((x, i) => i % 2 ? x * 2 : x)
        .map((x) => x > 9 ? x - 9 : x)
        .reduce((x, y) => x + y);
    return sum % 10 === 0;
}
const hasValidDate = (input) => {
    let [, yearStr, monthStr, dayStr] = /^(\d{2})(\d{2})(\d{2})/.exec(input);
    const year = Number(yearStr);
    const month = Number(monthStr) - 1;
    let day = Number(dayStr);
    if (day > 60) {
        day -= 60;
    }
    const date = new Date(year, month, day);
    const yearIsValid = String(date.getFullYear()).substr(-2) === yearStr;
    const monthIsValid = date.getMonth() === month;
    const dayIsValid = date.getDate() === day;
    return yearIsValid && monthIsValid && dayIsValid;
}
const hasValidLength = (input) => {
    return input.length >= 12;
}
export const personalIdentityNumberRule = (value) => {
    const cleaned = value.replace(/[+-]/, '').slice(-10);
    return hasValidLength(value)
        && hasCorrectChecksum(cleaned)
        && hasValidDate(cleaned)
        && pattern.test(value);
};

export const personalIdentityNumberToAge = (personalIdentityNumber) => {
    const birthDate = personalIdentityNumber.substr(0, 8);
    const normalizedBirthDate = `${birthDate.substr(0, 4)}-${birthDate.substr(4, 2)}-${birthDate.substr(6, 2)}`;
    const normalizedBirthDateObj = new Date(normalizedBirthDate);
    const now = new Date();

    const ageDifMs = now - normalizedBirthDateObj.getTime();
    const ageDate = new Date(ageDifMs);

    return Math.abs(ageDate.getUTCFullYear() - 1970);
}