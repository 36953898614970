<template>
  <div>
    <VuePopper arrow :placement="isMobile ? 'left' : 'right'">
      <BadgeButton :icon="ICONS.BELL" :has-badge="customerCreditNotifications.length > 0" />
      <template #content="{ close }">
        <div class="creditwatch-notification-content notifications noselect" :class="notificationsPopOverStyle">
          <template v-if="customerCreditNotifications != null && customerCreditNotifications.length > 0">
            <div class="creditwatch-notification-title">
              <i class="fas fa-analytics fa-fw" />
              <h4>Händelser Kreditkollen</h4>
            </div>
            <ul>
              <li v-for="( notification, i ) in customerCreditNotifications" :key="i">
                <RouterLink :to="notification.Url" class="notification-link" @click="close">
                  <i :class="getNotificationIcon(notification.Category)" class="fa-fw" />
                  <div class="notification-center-container">
                    <h5>{{ notification.Message }}</h5>
                    <p v-if="notification.MessageTitle != ''" class="creditwatch-notification-category body-small">
                      {{ notification.MessageTitle }}
                    </p>
                  </div>
                  <div class="notification-left-container">
                    <i class="fas fa-xs fa-chevron-right fa-fw" />
                  </div>
                </RouterLink>
              </li>
            </ul>
          </template>
          <template v-else>
            <div class="creditwatch-notification-title">
              <i class="fas fa-analytics fa-fw mr-2" />
              <span class="font-weight-bold color-nightrider">Du har inga nya notifieringar</span>
              <i class="fas fa-xs fa-chevron-right fa-fw" style="color: transparent;" />
            </div>
          </template>
        </div>
      </template>
    </VuePopper>
  </div>
</template>
<script setup>
import { useStore } from 'vuex';
import VuePopper from "vue3-popper";
import { computed, onMounted } from 'vue';
import { CUSTOMER_CREDIT_NOTIFICATIONS_FETCH } from '../Scripts/store/modules/customerCreditNotificationsContex';
import BadgeButton from './buttons/BadgeButton.vue';
import { ICONS } from './buttons/iconsUtils';
import { useSiteBreakpoints } from '../utils/breakpointsUtils';

const store = useStore();
const { isMobile } = useSiteBreakpoints();
const customerCreditNotifications = computed(() => store.state.customerCreditNotificationsContex.customerCreditNotifications);
const notificationsPopOverStyle = computed(() => {
  if (store.state.customerCreditNotificationsContex.customerCreditNotifications.length === 1) {
    return "one";
  }
  if (store.state.customerCreditNotificationsContex.customerCreditNotifications.length > 1) {
    return "multiple";
  }
  return "none";
});

onMounted(() => {
  store.dispatch(CUSTOMER_CREDIT_NOTIFICATIONS_FETCH);
});

const getNotificationIcon = (category) => {
  if (category === "UC_SCORE") {
    return "far fa-star";
  }
  return "far fa-map-marked-alt";
};

</script>
<style scoped lang="scss">
@import "../../../styles/abstracts/_variables";
@import "../../../styles/abstracts/_mixins";
@import "../../../styles/abstracts/_breakpoints";

.creditwatch-notification-content {
  min-width: 280px;
  background-color: white;
  border-radius: 6px;

  i {
    color: $orangegold;
    padding-top: 4px;
  }

  .fa-chevron-right {
    color: black;
  }

  h4,
  h5,
  p {
    color: $night-rider;
  }

  a {
    color: $night-rider;
    text-decoration: none;
  }

  .creditwatch-notification-title {
    h4 {
      font-family: Gilroy-Bold;
      margin-bottom: 0;
    }

    display: flex;
    flex-direction: row;
    gap: 6px;
    padding: 10px;
    border-radius: 6px;
  }

  ul {
    padding-left: 0;
    margin-bottom: 0;
    list-style-type: none;
    background-color: $beach;
    border-radius: 6px;

    li {
      padding: 4px 10px 0 10px;

      &:hover {
        border-radius: 0 !important;
        background-color: #F5EAE3 !important;
      }

      .notification-link {
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        gap: 6px;
        color: $night-rider;

        .creditwatch-notification-category {
          color: $emperor;
        }

        .notification-center-container {
          flex-grow: 1;
        }

        .notification-left-container {
          display: flex;
          align-items: center;
        }
      }
    }
  }

  @include breakpoint(max-phone) {
    max-width: 280px;
  }
}
</style>