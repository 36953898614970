<template>
  <div class="group-invite-container d-flex flex-column">
    <h4>
        Medlemmar
    </h4>
    <p>
        Här kan du se vilka som är medlemmar i ditt ID-Skydd, bjuda in nya och säga upp befintliga medlemmar. Du kan
        bjuda in <strong>{{ numberOfInvitationsLeft }} personer</strong> till.
    </p>
    <template v-if="hasSentInvetationsOrHasMembers">
      <h4>
        Inbjudna medlemmar ({{ numberOfMembersAndInvites }} st)
      </h4>
      <table class="table d-none d-lg-table">
        <thead>
          <tr>
            <td style="width: 25%">Namn</td>
            <td style="width: 35%">Mail</td>
            <td>Status</td>
            <td style="width: 25%" />
          </tr>
        </thead>
        <tbody>
          <tr v-for="(invite, index) in invitations" :key="`group_invite_invetation_${index}`"
            :class="oddOrEven(index + 1)">
            <td>{{ invite.name }}</td>
            <td>{{ invite.email }}</td>
            <td>
              <NdsStatusBadge variant="not_good">
                {{ getStatusTextWithDate({ status: STATUS.INVITED, date: invite.inviteTime }) }}
              </NdsStatusBadge>
            </td>
            <td class="float-right">
              <NdsIconButton theme="enento" variant="text" prefix="far" iconName="trash-alt" aria-label="delete member"
                @click="deleteGroupInvite({ name: invite.name })">
              </NdsIconButton>
            </td>
          </tr>
          <tr v-for="(member, index) in members" :key="`group_invite_memeber_${index}`"
            :class="oddOrEven(index + invitations.length + 1)">
            <td>{{ member.name }}</td>
            <td>{{ member.email }}</td>
            <td v-if="!member.zombie">
              <NdsStatusBadge variant="excellent">
                {{ getStatusTextWithDate({ status: STATUS.ACCEPTED, date: member.acceptTime }) }}
              </NdsStatusBadge>
            </td>
            <td v-else>
              <NdsStatusBadge variant="warning">
                {{ getStatusTextWithDate({ status: STATUS.DELETED, date: member.endDate }) }}
              </NdsStatusBadge>
            </td>
            <td class="float-right">
              <NdsIconButton :disabled="member.zombie" theme="enento" variant="text" prefix="far" iconName="trash-alt" aria-label="delete member"
                @click="deleteGroupMembership({ memberId: member.purchasedProductPackageId, ownerId: umbrella.purchasedProductPackageId })">
              </NdsIconButton>
            </td>
          </tr>
        </tbody>
      </table>
    </template>
    <div v-if="hasSentInvetationsOrHasMembers" class="mobile__group__invite-wrapper mt-2 d-lg-none">
      <div v-for="(invite, index) in invitations" :key="`group_invite_list_${index}`"
        class="mobile__group__invite__member mb-4 bg-lightsmoke" :class="oddOrEven(index + 1)">
        <div class="py-2 px-3 border-bottom">
          <span class="d-block font-weight-medium mb-1">Namn</span>
          <span class="color-gray">{{ invite.name }}</span>
        </div>
        <div class="py-2 px-3 border-bottom">
          <span class="d-block font-weight-medium mb-1">E-post</span>
          <span class="color-gray">{{ invite.email }}</span>
        </div>
        <div class="position-relative py-2 px-3 border-bottom">
          <span class="d-block font-weight-medium mb-1">Status</span>
          <div class="d-flex align-items-center justify-content-between">
            <div>
              <NdsStatusBadge variant="not_good">
                {{ getStatusTextWithDate({ status: STATUS.INVITED, date: invite.inviteTime }) }}
              </NdsStatusBadge>
            </div>
            <NdsIconButton theme="enento" variant="text" prefix="far" iconName="trash-alt"
              @click="deleteGroupInvite({ name: invite.name })">
            </NdsIconButton>
          </div>
        </div>
      </div>
      <div v-for="(member, index) in members" :key="`group_invite_memeber_info_${index}`"
        class="mobile__group__invite__member mb-4 bg-lightsmoke" :class="oddOrEven(index + invitations.length + 1)">
        <div class="py-2 px-3 border-bottom">
          <span class="d-block font-weight-medium mb-1">Namn</span>
          <span class="color-gray">{{ member.name }}</span>
        </div>
        <div class="py-2 px-3 border-bottom">
          <span class="d-block font-weight-medium mb-1">E-post</span>
          <span class="color-gray">{{ member.email }}</span>
        </div>
        <div class="py-2 px-3 border-bottom">
          <span class="d-block font-weight-medium mb-1">Status</span>
          <div class="d-flex align-items-center justify-content-between">
            <div v-if="!member.zombie">
              <NdsStatusBadge variant="excellent">
                {{ getStatusTextWithDate({ status: STATUS.ACCEPTED, date: member.acceptTime }) }}
              </NdsStatusBadge>
            </div>
            <div v-else>
              <NdsStatusBadge variant="warning">
                {{ getStatusTextWithDate({ status: STATUS.DELETED, date: member.endDate }) }}
              </NdsStatusBadge>
            </div>
            <NdsIconButton theme="enento" variant="text" prefix="far" iconName="trash-alt" :disabled="member.zombie"
              @click="deleteGroupMembership({ memberId: member.purchasedProductPackageId, ownerId: umbrella.purchasedProductPackageId, name: member.name })">
            </NdsIconButton>
          </div>
        </div>
      </div>
    </div>
    <div v-if="canInviteMoreMembers">
      <hr v-if="hasSentInvetationsOrHasMembers" class="pb-3">
      <InviteMember :groupId="props.groupId" :invitations="invitations" />
    </div>
    <ModalsContainer />
  </div>
</template>
<script setup>
import { computed, ref } from 'vue';
import { NdsStatusBadge, NdsIconButton } from '@nds/vue';
import { useStore } from 'vuex';
import { ModalsContainer, useModal } from 'vue-final-modal';
import ErrorModal from './modals/ErrorModal.vue';
import ConfirmModal from './modals/ConfirmModal.vue';
import InviteMember from './InviteMember.vue';
import DeleteInviteModal from './modals/DeleteInviteModal.vue';
import DeleteMemberModal from './modals/DeleteMemberModal.vue';
import { defaultGenericErrorModel } from '../components/modals/modalsUtils';
import { UMBRELLA_MEMBERS_FETCH } from '../Scripts/store/modules/umbrellaMembersContext';

const props = defineProps({
  invitations: Array,
  members: Array,
  groupId: Number,
  umbrella: Object,
});

const STATUS = {
  INVITED: 'INVITED',
  ACCEPTED: 'ACCEPTED',
  DELETED: 'DELETED',
};

const store = useStore();
const selectedInvite = ref({
  name: '',
  groupId: props.groupId,
});
const selectedMember = ref({
  memberId: 0,
  ownerId: 0,
  name: ''
});
const errorModel = ref(defaultGenericErrorModel);
const numberOfInvitationsLeft = computed(() => (props.umbrella.size - (props.members.length + props.invitations.length)));
const numberOfMembersAndInvites = computed(() => (props.members.length + props.invitations.length));
const canInviteMoreMembers = computed(() => props.umbrella.size != null && (props.members.length + props.invitations.length) < props.umbrella.size);
const hasSentInvetationsOrHasMembers = computed(() => (props.members.length + props.invitations.length) !== 0);

const oddOrEven = (index) => (index % 2) > 0 ? "odd" : "even";

const { open: openDeleteMemberModal, close: closeDeleteMemberModal } = useModal({
  component: DeleteMemberModal,
  attrs: {
    member: selectedMember,
    onCancel() {
      closeDeleteMemberModal();
    },
    onDeletedGroupMembership() {
      closeDeleteMemberModal();
      store.dispatch(UMBRELLA_MEMBERS_FETCH, { umbrellaProductId: store.state.customerInfoContex.customerEssentials.customerProducts.umbrellaProductId });
      openCancelMemberModal();
    },
    onError() {
      closeDeleteMemberModal();
      openErrorModal();
    }
  },
});

const { open: openCancelMemberModal, close: closeCancelMemberModal } = useModal({
  component: ConfirmModal,
  attrs: {
    title: 'Medlem uppsagd',
    icon: 'far fa-check-circle',
    body: 'Medlemen är nu uppsagd och kommer få ett mejl att tjänsten avslutas.',
    onConfirm() {
      closeCancelMemberModal();
    },
    onCancel() {
      closeCancelMemberModal();
    },
  },
});

const { open: openDeleteInviteModal, close: closeDeleteInviteModal } = useModal({
  component: DeleteInviteModal,
  attrs: {
    selectedInvite: selectedInvite,
    onCancel() {
      closeDeleteInviteModal();
    },
    onDeletedGroupInvite() {
      closeDeleteInviteModal();
      store.dispatch(UMBRELLA_MEMBERS_FETCH, { umbrellaProductId: store.state.customerInfoContex.customerEssentials.customerProducts.umbrellaProductId });
      openInvitationDeletedModal();
    },
    onError() {
      closeDeleteInviteModal();
      openErrorModal();
    }
  },
});

const { open: openInvitationDeletedModal, close: closeInvitationDeletedModal } = useModal({
  component: ConfirmModal,
  attrs: {
    title: 'Injbudan borttagen',
    icon: 'far fa-check-circle',
    body: 'Medlemen är nu uppsagd och kommer få ett mejl att tjänsten avslutas.',
    onConfirm() {
      closeInvitationDeletedModal();
    },
  },
});

const { open: openErrorModal, close: closeErrorModal } = useModal({
  component: ErrorModal,
  attrs: {
    model: errorModel,
    onConfirm() {
      closeErrorModal();
    },
  },
});

const deleteGroupInvite = ({ name }) => {
  selectedInvite.value = { name, groupId: props.groupId };
  openDeleteInviteModal();
};

const deleteGroupMembership = ({ memberId, ownerId, name }) => {
  selectedMember.value = { memberId, ownerId, name };
  openDeleteMemberModal();
};

const getStatusTextWithDate = ({ status, date }) => {
  const dateFormatter = Intl.DateTimeFormat('sv-SE');
  const formatedDate = date ? ` ${dateFormatter.format(new Date(Number(date)))}` : '';
  switch (status) {
    case STATUS.ACCEPTED:
      return `Accepterad${formatedDate}`
    case STATUS.INVITED:
      return `Inbjuden${formatedDate}`
    case STATUS.DELETED:
    default:
      return `Avslutas${formatedDate}`
  }
}

</script>
<style lang="scss" scoped>
@import "@/../../styles/abstracts/_variables.scss";
@import "@/../../styles/abstracts/_breakpoints.scss";

.group-invite-container {
  .mobile__group__invite__member {
    a {
      position: absolute;
      right: 16px;
      top: calc(50% - 15px);
    }
  }

  .status-label {
    background-color: $light-smoke;
    border-radius: 6px;
    padding: 2px 4px;
    text-align: center;

    &.invited {
      color: $orangegold;
      background-color: rgba(242, 201, 76, 0.1);
    }

    &.active {
      color: $green-haze;
      background-color: rgba(38, 153, 82, 0.1);
    }

    &.cancelled {
      color: $monza;
      background-color: $feedback-error-300;
    }
  }

  table {
    .even {
      background-color: $white;
    }

    tr:hover td {
      background: inherit;
      color: inherit;
    }

    td {
      border-top: 0;
      padding: 11px 16px;
      font-size: 14px;
      vertical-align: middle;
    }

    tbody tr {
      background-color: $light-smoke;

      td:first-child {
        width: 18%;

        @include breakpoint(max-tablet-portrait) {
          width: auto;
        }
      }

      td:nth-child(2) {
        width: 20%;
        word-break: break-all;

        @include breakpoint(max-tablet-portrait) {
          width: auto;
        }
      }
    }
  }
}
</style>