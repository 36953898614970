<template>
  <div>
    <div
      id="income-and-taxation-table-year-0"
      class="dashboard__table"
    >
      <div
        id="general-income-accordion-year-0"
        class="block__enento__accordion"
      >
        <div class="panel-group border-0">
          <div class="panel-heading">
            <a
              class="d-block collapsed"
              data-toggle="collapse"
              href="#general-income-accordion-0"
              data-testid="general-income-accordion-year-0"
            >
              <p class="dashboard__table-column color-nightrider font-weight-medium py-3 mb-0">
                {{ currentYearIncomeAndTaxation.incomeYearDate }}
              </p>
            </a>
          </div>
          <div
            id="general-income-accordion-0"
            class="collapse"
            data-parent="#general-income-accordion-year-0"
          >
            <IncomeAndTaxationMobileEntries
              :fields="currentYearIncomeAndTaxation.fields"
              :sole-trader="soleTrader"
            />
          </div>
        </div>
      </div>
      <div
        id="general-income-accordion-year-1"
        class="block__enento__accordion"
      >
        <div class="panel-group border-0">
          <div class="panel-heading">
            <a
              class="d-block collapsed"
              data-toggle="collapse"
              href="#general-income-accordion-1"
              data-testid="general-income-accordion-year-1"
            >
              <p class="dashboard__table-column color-nightrider font-weight-medium py-3 mb-0">
                {{ lastYearIncomeAndTaxation.incomeYearDate }}
              </p>
            </a>
          </div>
          <div
            id="general-income-accordion-1"
            class="collapse"
            data-parent="#general-income-accordion-year-1"
          >
            <IncomeAndTaxationMobileEntries
              :fields="lastYearIncomeAndTaxation.fields"
              :sole-trader="soleTrader"
            />
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script setup>
import { computed } from 'vue';
import { getIncomeAndTaxationModel } from './incomeAndTaxationUtils';
import IncomeAndTaxationMobileEntries from './IncomeAndTaxationMobileEntries.vue';

const props = defineProps({
  ctiConsumerIncomeAndTaxation: Object,
  soleTrader: Boolean,
});

const currentYearIncomeAndTaxation = computed(() => getIncomeAndTaxationModel({incomeAndTaxation: props.ctiConsumerIncomeAndTaxation.incomeAndTaxation[0], showMissingValue: true }));
const lastYearIncomeAndTaxation = computed(() => getIncomeAndTaxationModel({incomeAndTaxation: props.ctiConsumerIncomeAndTaxation.incomeAndTaxation[1], showMissingValue: false }));
</script>