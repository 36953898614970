import $ from 'jquery';
var loader = {};

$(function () {
    loader.loadingOverlay = $(".loading-overlay");

    loader.loadingOverlay.height($(window).height());
    $(window).resize(function () {
        loader.loadingOverlay.height($(window).height());
    });
});

export function show() {
    loader.hideOverlay = false;
    setTimeout(function () {
        if (!loader.hideOverlay)
            loader.loadingOverlay.show();
    }, 200);
}

export function hide() {
    loader.hideOverlay = true;
    loader.loadingOverlay.hide();
}