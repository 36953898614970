<template>
  <div id="mycreditscorepage" class="dashboard col-md-8 col-lg-9" data-testid="dashboard-my-credit-score-page">
    <div class="row">
      <div class="col-12 mb-2 d-md-none mobile-return-overview-btn">
        <RouterLink to="/mina-tjanster" v-slot="{ href, navigate, isActive }" custom>
          <NdsLink :href="href" :active="isActive" @click="navigate"
            :prefix="{ prefix: 'far', iconName: 'chevron-left' }">
            Översikt
          </NdsLink>
        </RouterLink>
      </div>
      <div v-if="pending" class="align-items-center justify-content-center d-flex flex-fill">
        <NdsLoader />
      </div>
      <template v-else>
        <div class="col-lg-6">
          <div class="dashboard__card">
            <div class="information-container">
              <h2>{{ model.name }}</h2>
              <p v-html="model.description.value" />
              <template v-if="customerCreditInfo.creditReport.creditScore && loaded">
                <CreditScoreChart :report="customerCreditInfo" />
                <p v-if="customerCreditInfo.creditReport.creditScore.riskGrade < 0.2"
                  v-html="model.excellentCreditScore.value" />
                <p v-else-if="customerCreditInfo.creditReport.creditScore.riskGrade < 1"
                  v-html="model.veryGoodCreditScore.value" />
                <p v-else-if="customerCreditInfo.creditReport.creditScore.riskGrade < 4.1"
                  v-html="model.goodCreditScore.value" />
                <p v-else-if="customerCreditInfo.creditReport.creditScore.riskGrade < 25.1"
                  v-html="model.lessGoodCreditScore.value" />
                <p v-else-if="customerCreditInfo.creditReport.creditScore.riskGrade > 25"
                  v-html="model.weakCreditScore.value" />
              </template>
            </div>
          </div>
        </div>
        <div class="col-lg-6">
          <div class="dashboard__card">
            <div class="information-container">
              <template v-if="customerCreditInfo.creditReport.creditScore && loaded">
                <p v-if="customerCreditInfo.creditReport.creditScore.riskGrade < 0.2"
                  v-html="model.creditScoreStatisticsUTMARKT.value" />
                <p v-else-if="customerCreditInfo.creditReport.creditScore.riskGrade < 1"
                  v-html="model.creditScoreStatisticsMYCKET_BRA.value" />
                <p v-else-if="customerCreditInfo.creditReport.creditScore.riskGrade < 4.1"
                  v-html="model.creditScoreStatisticsBRA.value" />
                <p v-else-if="customerCreditInfo.creditReport.creditScore.riskGrade < 25.1"
                  v-html="model.creditScoreStatisticsMINDRE_BRA.value" />
                <p v-else-if="customerCreditInfo.creditReport.creditScore.riskGrade > 25"
                  v-html="model.creditScoreStatisticsSVAG.value" />
              </template>
              <p v-else v-html="model.noCreditScoreAvailable.value" />
            </div>
          </div>
        </div>
      </template>
    </div>
    <RelatedArticles v-if="model.relatedArticles.length > 0" :articles="model.relatedArticles.expandedValue" />
  </div>
</template>
<script setup>
import CreditScoreChart from '../components/charts/CreditScoreChart.vue';
import RelatedArticles from '../components/RelatedArticles.vue';
import { NdsLoader, NdsLink } from '@nds/vue';
import { CUSTOMER_CREDIT_INFO_FETCH } from '../Scripts/store/modules/customerCreditInfoContex';
import { APP_HIDE_SIDEBAR } from '../Scripts/store/modules/appContext';
import { useStore } from 'vuex';
import { computed, onMounted } from 'vue';

const props = defineProps({
  model: Object
});
const store = useStore();
const customerCreditInfo = computed(() => store.state.customerCreditInfoContex.customerCreditInfo);
const loaded = computed(() => store.state.customerCreditInfoContex.loaded);
const pending = computed(() => store.state.customerCreditInfoContex.pending);

onMounted(() => {
  document.title = props.model.name || "Mina tjänster";
  if (!store.state.customerCreditInfoContex.loaded) {
    store.dispatch(CUSTOMER_CREDIT_INFO_FETCH);
  }
  if (store.state.appContext.showSideBar) {
    store.dispatch(APP_HIDE_SIDEBAR);
  }
});
</script>
