import { getArticleChildren } from '../../providers/frontendApiControllerProviders';

const ARTICLES = 'ARTICLES';
export const ARTICLES_FETCH = `${ARTICLES}/FETCH`;
const ARTICLES_UPDATE = `${ARTICLES}/UPDATE`;
const ARTICLES_UPDATE_FAILED = `${ARTICLES}/UPDATE_FAILED`;

const state = {
    articles: [],
    loaded: false,
    failed: false,
};

const mutations = {
    [ARTICLES_UPDATE](state, newModel) {
        state.articles = newModel;
        state.loaded = true;
        state.failed = false;
    },
    [ARTICLES_UPDATE_FAILED](state) {
        state.articles = [];
        state.loaded = true;
        state.failed = true;
    },
};

const actions = {
    [ARTICLES_FETCH]({commit}, { id }) {
        return getArticleChildren(id).then(response => {
            commit(ARTICLES_UPDATE, response.data);
        }).catch(()=> {
            commit(ARTICLES_UPDATE_FAILED);
        });
    },
};

export const articlesContex = {
    state,
    mutations,
    actions
};
