<template>
  <div>
    <div
      id="remarks-kfm-table"
      class="col-12 dashboard__table"
    >
      <template v-if="complaints.consumerPaymentDebtWithSESData.debtDate">
        <div class="py-4">
          <h4 class="mb-0">
            Aktuell skuld rapporterad från Kronofogdemyndigheten (KFM)
          </h4>
        </div>
        <div class="d-flex align-items-center">
          <p>Datum:</p>
          <p class="ml-auto">
            {{ FormatDate(complaints.consumerPaymentDebtWithSESData.debtDate.timeStamp.value) }}
          </p>
        </div>
        <div class="d-flex align-items-center">
          <p class="mb-2">
            Allmänna mål ({{ complaints.consumerPaymentDebtWithSESData.numberOfPublicClaims }} st):
          </p>
          <p class="mb-2 font-weight-medium ml-auto">
            {{ NumberFormat(complaints.consumerPaymentDebtWithSESData.publicClaims) }} kr
          </p>
        </div>
        <div class="d-flex align-items-center">
          <p class="mb-0">
            Enskilda mål ({{ complaints.consumerPaymentDebtWithSESData.numberOfPrivateClaims }} st):
          </p>
          <p class="font-weight-medium mb-0 ml-auto">
            {{ NumberFormat(complaints.consumerPaymentDebtWithSESData.privateClaims) }} kr
          </p>
        </div>
        <hr>
        <div class="d-flex align-items-center">
          <p class="mb-2 font-weight-medium">
            Antal mål totalt:
          </p>
          <p class="mb-2 font-weight-medium ml-auto">
            {{ complaints.consumerPaymentDebtWithSESData.sumOfClaims }} st
          </p>
        </div>
        <div class="d-flex align-items-center">
          <p class="font-weight-medium color-ocean">
            Belopp total:
          </p>
          <p class="font-weight-medium ml-auto color-ocean">
            {{ NumberFormat(complaints.consumerPaymentDebtWithSESData.sumClaims) }} kr
          </p>
        </div>
        <div
          v-if="complaints.historicalDebtDetails.length > 0 && complaints.historicalDebtDetails[0].sumDebtText"
          class="align-items-center"
        >
          <p v-html="complaints.historicalDebtDetails[0].sumDebtText" />
          <p>Beloppen ovan avser oreglerade skulder hos kronofogden. Observera att dessa skulder ibland omfattar fordringar som även finns redovisade som betalningsanmärkningar.</p>
        </div>
      </template>
      <template v-if="complaints.historicalDebtDetails && complaints.historicalDebtDetails.length > 0">
        <h4
          :class="[complaints.consumerPaymentDebtWithSESData.debtDate ? '' : 'mt-3']"
          class="mb-3"
        >
          Historiskt skuldsaldo rapporterat från Kronofogdemyndigheten (KFM)
        </h4>
        <p>Skuld hos kronofogden de senaste 24 månaderna. Beloppen i kr visar det högsta beloppet som rapporterats till UC under månaden. För period som är utelämnad, saknar UC kännedom om skuldsaldot.</p>
        <div class="dashboard__table">
          <template
            v-for="(complaint, index) in complaints.historicalDebtDetails"
            :key="index"
          >
            <div
              v-if="index <= maxItems"
              id="remarks-kfm-accordion"
              class="block__enento__accordion"
            >
              <div class="panel-group border-0">
                <div class="panel-heading">
                  <a
                    class="d-block collapsed"
                    data-toggle="collapse"
                    :href="'#remarks-kfm-accordion-'+index"
                    data-testid="remarks-kfm-accordion"
                  >
                    <p class="dashboard__table-column body-small font-weight-bold mb-0">
                      Datum
                      {{ FormatDateRemoveDays(complaint.debtDate.timeStamp.value) }}
                      <span class="font-weight-light color-emperor d-block">
                        Belopp total: {{ NumberFormat(getTotalClaimsOfComplaint(complaint)) }} kr
                      </span>
                    </p>
                  </a>
                </div>
                <div
                  :id="'remarks-kfm-accordion-'+index"
                  class="collapse"
                  data-parent="#remarks-kfm-accordion"
                >
                  <div class="dashboard__table-column">
                    <div class="d-flex align-items-center">
                      <p class="mb-0">
                        Allmänna mål:
                      </p>
                      <p class="mb-0 ml-auto">
                        {{ NumberFormat(complaint.publicClaims) }} kr
                      </p>
                    </div>
                    <div class="d-flex align-items-center">
                      <p class="mb-2">
                        Enskilda mål:
                      </p>
                      <p class="mb-2 ml-auto">
                        {{ NumberFormat(complaint.privateClaims) }} kr
                      </p>
                    </div>
                    <div class="d-flex align-items-center">
                      <p class="mb-0">
                        Antal mål:
                      </p>
                      <p class="mb-0 ml-auto">
                        {{ complaint.sumClaims }} st
                      </p>
                    </div>
                    <div class="d-flex align-items-center">
                      <p class="mb-0">
                        Belopp total:
                      </p>
                      <p class="mb-0 ml-auto">
                        {{ NumberFormat(getTotalClaimsOfComplaint(complaint)) }} kr
                      </p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </template>
          <div
            v-if="complaints.historicalDebtDetails.length > 5 && maxItems < complaints.historicalDebtDetails.length"
            class="text-center py-3"
            @click="maxItems = complaints.historicalDebtDetails.length;"
          >
            <button
              type="button"
              class="btn btn-enento-text"
            >
              Visa alla {{ complaints.historicalDebtDetails.length }} månader
            </button>
          </div>
        </div>
      </template>
    </div>
  </div>
</template>
<script>
    import { FormatDate, FormatDateRemoveDays, NumberFormat } from '../_helpers.js';

    export default {
        name: 'RemarksKfmMobile',
        props: {
            complaints: Object,
            model:Object
        },
        data: function () {
            return {
                maxItems: 5
            }
        },
        methods: {
            getTotalClaimsOfComplaint(complaint) {
                let total = complaint.privateClaims + complaint.publicClaims;
                return total;
            },
            FormatDate: function (timestamp) {
                return FormatDate(timestamp);
            },
            FormatDateRemoveDays: function (timestamp) {
                return FormatDateRemoveDays(timestamp);
            },
            NumberFormat: function (num) {
                return NumberFormat(num);
            }
        }
    }
</script>