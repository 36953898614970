<template>
  <div
    class="dashboard__table"
    data-testid="minupplysning-remarks-tab"
  >
    <div
      v-for="(complaint, index) in complaintsChunk"
      :id="`remarks-accordion-parent-${index}`"
      :key="`remarks_mobile_accordian_${index}`"
      class="block__enento__accordion"
    >
      <div class="panel-group border-0">
        <div class="panel-heading">
          <a
            class="d-block collapsed"
            data-toggle="collapse"
            :href="'#remarks-accordion-'+index"
            data-testid="remarks-accordion-0"
          >
            <p class="dashboard__table-column color-nightrider py-3 mb-0">
              Anmärkning {{ FormatDate(complaint.complaintDate.timeStamp.value) }}
              <span
                v-if="complaint.complaintTypeText"
                class="d-block font-weight-light"
              >{{ complaint.complaintTypeText }}</span>
            </p>
          </a>
        </div>
        <div
          :id="'remarks-accordion-'+index"
          class="collapse"
          :data-parent="'#remarks-accordion-parent-'+index"
        >
          <div class="dashboard__table-column">
            <p class="mb-0">
              <span class="color-emperor font-weight-medium">Datum</span>
              <span
                v-if="complaint.complaintDate"
                class="d-block"
              >
                {{ FormatDate(complaint.complaintDate.timeStamp.value) }}
              </span>
              <span
                v-else
                class="d-block"
              >-</span>
            </p>
          </div>
          <div class="dashboard__table-column">
            <p class="mb-0">
              <span class="color-emperor font-weight-medium">Fordringsägare, övrig info</span>
              <span
                v-if="complaint.creditor"
                class="d-block"
              >
                {{ complaint.creditor }}
              </span>
              <span
                v-else
                class="d-block"
              >-</span>
            </p>
          </div>
          <div class="dashboard__table-column">
            <p class="mb-0">
              <span class="color-emperor font-weight-medium">Belopp</span>
              <span
                v-if="complaint.complaintAmount"
                class="d-block"
              >
                {{ NumberFormat(complaint.complaintAmount) }} kr
              </span>
              <span
                v-else
                class="d-block"
              >-</span>
            </p>
          </div>
          <div
            v-if="showEndDate"
            class="dashboard__table-column"
          >
            <p class="mb-0">
              <span class="color-emperor font-weight-medium">Försvinner</span>
              <span
                v-if="complaint.complaintEndDate"
                class="d-block"
              >
                {{ FormatDate(complaint.complaintEndDate.timeStamp.value) }}
              </span>
              <span
                v-else
                class="d-block"
              >-</span>
            </p>
          </div>
        </div>
      </div>
    </div>
  </div>
  <Paging
    :items="complaints"
    :items-per-page="10"
    :paging-enabled="true"
    @chunk="ShowSpan"
  />
</template>
<script>
import {FormatDate, NumberFormat} from '../_helpers.js';
import Paging from "./Paging.vue";

export default {
    name: 'RemarksMobile',
    components: {Paging},
    props: {
        complaints: Array,
        showEndDate: Boolean
    },
    data: function () {
        return {
            complaintsChunk: []
        }
    },
    methods: {
        ShowSpan(chunk) {
            this.complaintsChunk = chunk;
        },
        FormatDate: function (timestamp) {
            return FormatDate(timestamp);
        },
        NumberFormat: function (num) {
            return NumberFormat(num);
        }
    }
}
</script>