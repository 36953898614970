<template>
  <div>
    <div
      :id="id"
      class="modal fade"
      tabindex="-1"
      role="dialog"
      aria-labelledby="disable-fraud"
      aria-hidden="true"
    >
      <div
        class="modal-dialog modal-dialog-centered"
        role="document"
      >
        <div class="modal-content">
          <div class="modal-header d-flex align-items-baseline">
            <h3
              id="disable-fraud"
              class="modal-title"
            >
              <i class="far fa-exclamation-triangle" />{{ title }}
            </h3>
            <i
              class="far fa-times hover-pointer"
              data-dismiss="modal"
              aria-label="Close"
            />
          </div>
          <div class="modal-body">
            <p v-html="body" />
          </div>
          <div class="modal-footer">
            <button
              type="button"
              class="btn btn-enento-secondary  "
              data-dismiss="modal"
            >
              Avbryt
            </button>
            <button
              type="button"
              class="btn btn-enento-feedback-error "
              data-dismiss="modal"
              @click="setFraudBarrierStatus()"
            >
              Inaktivera
            </button>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script setup>
import { useStore } from 'vuex';
import $ from 'jquery';
import { FRAUD_BARRIER_POST } from '../../Scripts/store/modules/fraudBarrierContex';

defineProps({
    id: String,
    body: String,
    title: String,
});
    
const store = useStore();

const setFraudBarrierStatus = () => {
    const csrfToken = $("input[name=__RequestVerificationToken]").val();
    store.dispatch(FRAUD_BARRIER_POST, {newStatus: 0, csrfToken});
}
</script>