<template>
  <div class="dashboard col-md-8 col-lg-9" data-testid="dashboard-my-settings-page">
    <div id="mysettings-page">
      <div class="col-12 mb-2 d-md-none mobile-return-overview-btn">
        <RouterLink to="/mina-tjanster" v-slot="{ href, navigate, isActive }" custom>
          <NdsLink :href="href" :active="isActive" @click="navigate"
            :prefix="{ prefix: 'far', iconName: 'chevron-left' }">
            Översikt
          </NdsLink>
        </RouterLink>
      </div>
      <div class="dashboard__card">
        <div class="row">
          <div class="col-12">
            <h2 class="mb-3">
              {{ model.name }}
            </h2>
          </div>
          <ul class="nav nav-tabs d-flex flex-nowrap w-100 border-0">
            <li class="d-flex nav-item text-nowrap">
              <a class="active" data-toggle="tab" href="#settings-tab">Mina uppgifter</a>
            </li>
            <li class="d-flex nav-item text-nowrap">
              <a data-toggle="tab" href="#payments-tab" data-testid="mina-installningar-betalningar-tab">Betalningar</a>
            </li>
            <li class="d-flex nav-item text-nowrap">
              <a v-if="ownedUmbrellaProductId" data-toggle="tab" href="#members-tab">Medlemmar</a>
            </li>
            <li class="d-flex flex-fill nav-item">
              <a href="#" class="pe-none flex-fill" />
            </li>
          </ul>
        </div>
        <div class="tab-content py-4">
          <div id="settings-tab" class="tab-pane active">
            <form class="row">
              <div class="col-md-6 form-group order-1">
                <label class="disabled" for="name">Namn</label>
                <input id="name" type="text" class="form-control disabled" disabled
                  :value="customerEssentials.customerFullName">
              </div>
              <div class="col-md-6 form-group order-3 order-md-2">
                <label for="email">E-postadress</label>
                <input id="email" v-model="form.customerEmail" type="text" class="form-control"
                  :class="v$.customerEmail.$error ? 'is-invalid' : ''" placeholder="E-postadress" @keyup="onDataChange">
                <small v-if="v$.customerEmail.email.$invalid" class="is-invalid">
                  {{ v$.customerEmail.email.$message }}
                </small>
                <small v-else-if="v$.customerEmail.required.$invalid" class="is-invalid">
                  {{ v$.customerEmail.required.$message }}
                </small>
              </div>
              <div class="col-md-6 form-group order-2 order-md-3">
                <label class="disabled" for="personId">Personnummer</label>
                <input id="personId" type="text" class="form-control disabled" placeholder="Personnummer" disabled
                  :value="customerEssentials.customerSocSecNo">
              </div>
              <div class="col-md-6 form-group order-4">
                <label for="phone">Telefonnummer</label>
                <input id="phone" v-model="form.customerPhone" type="text" class="form-control"
                  placeholder="Telefonnummer" :class="v$.customerPhone.$error ? 'is-invalid' : ''"
                  @keyup="onDataChange">
                <small v-if="v$.customerPhone.valid_cellphone.$invalid" class="is-invalid">
                  {{ v$.customerPhone.valid_cellphone.$message }}
                </small>
                <small v-else-if="v$.customerPhone.required.$invalid" class="is-invalid">
                  {{ v$.customerPhone.required.$message }}
                </small>
              </div>
              <div class="col-12 order-last">
                <hr class="pb-3">
                <p class="font-weight-medium mb-2">
                  För dig med ID-Skydd
                </p>
                <p>Hur vill du bli meddelad om förändringar i din kreditupplysning?</p>
                <div class="form-check mb-3">
                  <input id="smsCheck" v-model="form.notificationBySms" type="checkbox" class="form-check-input"
                    @change="onDataChange">
                  <label class="form-check-label" for="smsCheck">SMS</label>
                </div>
                <div class="form-check mb-4">
                  <input id="emailCheck" v-model="form.notificationByMail" type="checkbox" class="form-check-input"
                    @change="onDataChange">
                  <label class="form-check-label" for="emailCheck">E-post</label>
                </div>
                <hr class="pb-3">
                <p class="font-weight-medium mb-2">
                  Vill du ha vårt nyhetsbrev?
                </p>
                <p>
                  Vill du få de senaste nyheterna, tips och intressanta artiklar kring ekonomi från
                  oss på UC? Då är
                  vårt nyhetsbrev perfekt för dig!
                </p>
                <div class="form-check mb-5">
                  <input id="newsletterCheck" v-model="form.newsLetterSubscription" type="checkbox"
                    class="form-check-input" @change="onDataChange">
                  <label class="form-check-label" for="newsletterCheck">Ja, tack!</label>
                </div>
                <button :disabled="!dataChanged || customerEssentialsPending" type="button"
                  class="submit-button btn btn-enento-blue btn-lg btn-wide w-100-md mb-3"
                  @click="submitCustomerSettings">
                  <div v-if="customerEssentialsPending && hasSubmitted" class="submit-button-content">
                    <span>Laddar...</span>
                    <NdsLoader size="25" />
                  </div>
                  <div v-else>
                    Spara
                  </div>
                </button>
                <Alert v-if="showSavedAlert" type="success" title="Sparat!" description="Dina ändringar är sparade."
                  @close="onCloseAlert" />
                <Alert v-if="showFailedAlert" type="error" title="Något gick fel!"
                  description="Vänligen ladda om sidan och försök igen." @close="onCloseAlert" />
              </div>
            </form>
          </div>
          <div id="payments-tab" class="tab-pane">
            <p class="mb-4">
              Här kan du se över dina tjänster och betalningsuppgifter
            </p>
            <div v-if="customerEssentialsPending" class="align-items-center justify-content-center d-flex">
              <NdsLoader />
            </div>
            <template v-else-if="recurringProducts.length > 0">
              <template v-for="(product, index) in recurringProducts">
                <div v-if="product.productPackage && product.productPackage.name.toLowerCase() !== 'kreddy'"
                  :key="`product_package_${index}`">
                  <h4>{{ product.productPackage.name }}</h4>
                  <div class="border-gray mb-4">
                    <table class="table mt-0 mb-0">
                      <thead>
                        <tr class="border-bottom-black">
                          <td>
                            <h6 class="mb-0">
                              Pris
                            </h6>
                          </td>
                          <td>
                            <h6 class="mb-0">
                              Nästa betalning
                            </h6>
                          </td>
                          <td>
                            <h6 class="mb-0">
                              Betalsätt
                            </h6>
                          </td>
                          <td />
                        </tr>
                      </thead>
                      <tbody>
                        <tr>
                          <td data-testid="settings-table-price-txt">
                            {{ product.price }} kr/mån
                          </td>
                          <td v-if="product.paymentType == 'UMBRELLA'" />
                          <td v-else-if="product.endDate && !product.isCancellable">
                            <p style="margin-bottom:0;" data-testid="settings-table-terminate-date-txt">
                              Avslutas
                            </p>
                            {{ formatDate(product.endDate) }}
                          </td>
                          <td v-else data-testid="settings-table-nextpayment-txt">
                            {{ formatDate(product.nextPurchaseDate) }}
                          </td>
                          <td data-testid="settings-table-paymnettype-txt">
                            {{ translatePaymentType(product.paymentType)
                            }}
                          </td>
                          <td v-if="product.paymentType == 'INVOICE'" class="d-none d-lg-table-cell" style="width:23%;">
                            <a target="_blank" href="https://customer.horizonafs.com/SE/uc" class="notificationCategory"
                              data-dismiss="popover">
                              <p class="mb-0">Gå till fakturaportal <i class="fas fa-xs fa-external-link fa-fw" /></p>
                            </a>
                          </td>
                          <td v-if="product.paymentType == 'CARD' && product.paymentType != 'RESELLER'"
                            class="d-none d-lg-table-cell" style="width:23%;">
                            <a href="#" class="notificationCategory" data-dismiss="popover"
                              @click="onChangeCard($event, product)">
                              <p class="mb-0" data-testid="settings-table-changecard-btn">Byt kort</p>
                            </a>
                          </td>
                          <td class="d-none d-lg-table-cell" style="width:10%;">
                            <a target="_blank"
                              :href="`/minuc/betalningar/createreceiptpdf?pId=${product.productPackage.id}&pDate=${product.purchasedDate}&orderId=${product.id}`"
                              class="notificationCategory" data-dismiss="popover">
                              <p class="mb-0" data-testid="settings-table-receipt-btn">Kvitto</p>
                            </a>
                          </td>
                          <td v-if="product.isCancellable && product.paymentType != 'RESELLER'"
                            class="d-none d-lg-table-cell">
                            <a href="#" class="" data-dismiss="popover"
                              @click="onTerminateProductClicked($event, product)">
                              <p class="mb-0" data-testid="settings-table-terminate-btn">Avsluta tjänst</p>
                            </a>
                          </td>
                          <td v-else class="d-none d-lg-table-cell pe-none">
                            <p class="mb-0 color-gray font-weight-medium">
                              Avsluta tjänst
                            </p>
                          </td>
                          <td style="width:10%" class="d-lg-none">
                            <PaymentOptionsPopover :product="product" @on-change-card="onChangeCard(null, product)"
                              @on-terminate-product="onTerminateProductClicked(null, product)" />
                          </td>
                        </tr>
                      </tbody>
                    </table>
                  </div>
                </div>
              </template>
            </template>
            <div v-else>
              <h4>Du har inga abonnemang</h4>
            </div>
            <h3 class="mt-5">
              Information om betalningar
            </h3>
            <div id="mysettings-accordion" class="block__enento__accordion">
              <div class="panel-group">
                <div v-for="(faqBlock, index) in model.faqArea.expandedValue"
                  :key="`my_setting_info_about_payment_${index}`">
                  <div class="panel-heading">
                    <a class="collapsed" data-toggle="collapse" :href="'#faq-' + index"
                      :data-testid="'accordion-heading-' + faqBlock.name">
                      <h5 class="font-weight-medium p-3 mb-0">
                        {{ faqBlock.name }}
                      </h5>
                    </a>
                  </div>
                  <div :id="'faq-' + index" class="collapse" data-parent="#mysettings-accordion">
                    <div class="panel-body px-3 my-4">
                      <p v-html="faqBlock.text.value" />
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div id="members-tab" class="tab-pane">
            <Reloader v-if="umbrellaMembersFailed" @reload="reFetchUmbrellaMembers" />
            <div v-if="umbrellaMembersPending" class="align-items-center justify-content-center d-flex">
              <NdsLoader />
            </div>
            <GroupInvite v-else-if="umbrellaMembersLoaded" :invitations="invitations" :members="members"
              :group-id="ownedUmbrellaProductId" :umbrella="umbrella" />
          </div>
        </div>
        <TerminateProduct v-if="selectedProduct.product">
          <div class="modal-content">
            <div class="modal-header d-flex align-items-baseline">
              <h3 id="terminate-product" class="modal-title">
                <i class="far fa-exclamation-triangle" />
                Avsluta abonnemang
              </h3>
              <i class="far fa-times hover-pointer" data-dismiss="modal" aria-label="Close" />
            </div>
            <div class="modal-body">
              <p data-testid="terminatepopup-txt">
                Är du säker på att du vill avsluta {{ selectedProduct.product.productPackage.name }}?<br><br>
                Abonnemanget löper i så fall ut
                <b data-testid="terminatepopup-terminate-date">{{ formatDate(selectedProduct.product.endDate) }}</b>
                , och ingen ny betalning genomförs.
              </p>
            </div>
            <div class="modal-footer">
              <button type="button" class="btn btn-enento-secondary" data-dismiss="modal"
                data-testid="terminatepopup-cancel-btn">
                Avbryt
              </button>
              <button type="button" class="btn btn-enento-feedback-error" :disabled="terminateProductPending"
                @click="onTerminateProduct">
                Avsluta abonnemang
              </button>
            </div>
            <div v-if="terminateProductHasError" class="modal-body">
              <p>
                Något gick fel när vi försökte avsluta {{ selectedProduct.product.productPackage.name }}<br>
                Försök igen eller avbryt och kontakta support.
              </p>
            </div>
          </div>
        </TerminateProduct>
        <InfoModal id="terminateProductId" title="Avsluta abonnemang"
          body="Du har nu avslutat ditt abonnemang. Du kommer att få ett bekräftelsemeddelande till din e-post." />
        <InfoModal id="infoRecentlyPayedInvoice" title="Avsluta abonnemang"
          body="Tråkigt att du vill avsluta din tjänst hos minUC. </br></br> Om du nyligen fått en årsfaktura så är du välkommen att kontakta oss på kundservice via telefon så hjälper vi dig makulera den. Våran kundtjänst har öppet <strong>måndag till fredag kl 10:00-17:00</strong></br></br>Telefon <a href='tel:0771-20 22 77'>0771-20 22 77</a>" />
      </div>
    </div>
  </div>
  <ModalsContainer />
</template>
<script setup>
import { useStore } from 'vuex';
import { computed, reactive, ref, onMounted, watch, nextTick } from 'vue';
import { NdsLink, NdsLoader } from '@nds/vue';
import { ModalsContainer, useModal } from 'vue-final-modal';
import $ from 'jquery';
import { useVuelidate } from '@vuelidate/core';
import { required, email, helpers } from '@vuelidate/validators';
import Alert from '../components/Alert.vue';
import TerminateProduct from '../components/modals/TerminateProduct.vue';
import InfoModal from '../components/modals/InfoModal.vue';
import PaymentOptionsPopover from '../components/PaymentOptionsPopover.vue';
import GroupInvite from '../components/GroupInvite.vue';
import Reloader from '../components/Reloader.vue';
import { FormatDate } from '../_helpers.js';
import { isValidSwedishCellPhone } from '../utils/validators.js';
import ErrorModal from '../components/modals/ErrorModal.vue';
import { defaultGenericErrorModel, changeCardModel, changeCardModelLoading } from '../components/modals/modalsUtils';
import {
  CUSTOMER_INFO_FETCH,
  CUSTOMER_INFO_POST_SETTINGS,
  CUSTOMER_INFO_POST_TERMINATE_PRODUCT,
} from '../Scripts/store/modules/customerInfoContex';
import { UMBRELLA_MEMBERS_FETCH } from '../Scripts/store/modules/umbrellaMembersContext';
import { postNeteasyChangeCardPaymentId } from '../Scripts/providers/customerApiProvider';
import ConfirmModal from '../components/modals/ConfirmModal.vue';

const props = defineProps(['model']);
const store = useStore();
const customerEssentials = computed(() => store.state.customerInfoContex.customerEssentials);
const customerEssentialsLoaded = computed(() => store.state.customerInfoContex.loaded);
const customerEssentialsFailed = computed(() => store.state.customerInfoContex.failed);
const customerEssentialsPending = computed(() => store.state.customerInfoContex.pending);
const ownedUmbrellaProductId = computed(() => store.state.customerInfoContex.customerEssentials.customerProducts.ownedUmbrellaProductId);
const recurringProducts = computed(() => store.state.customerInfoContex.customerEssentials.customerProducts?.recurringProducts);
const umbrellaMembersLoaded = computed(() => store.state.umbrellaMembersContext.loaded);
const umbrellaMembersFailed = computed(() => store.state.umbrellaMembersContext.failed);
const umbrellaMembersPending = computed(() => store.state.umbrellaMembersContext.pending);
const invitations = computed(() => store.state.umbrellaMembersContext.umbrellaMembers?.invitations);
const members = computed(() => store.state.umbrellaMembersContext.umbrellaMembers.members);
const umbrella = computed(() => store.state.umbrellaMembersContext.umbrellaMembers.umbrella);
const terminateProductPending = computed(() => store.state.customerInfoContex.pending);
const terminateProductHasError = computed(() => store.state.customerInfoContex.failed);
const hasRecentlyPayedInvoiceForTerminatedProduct = computed(() => store.state.customerInfoContex.hasRecentlyPayedInvoiceForTerminatedProduct);
const dataChanged = ref(false);
const selectedProduct = reactive({ product: null });
const showSavedAlert = ref(false);
const showFailedAlert = ref(false);
const hasSubmitted = ref(false);
const modalModel = ref(defaultGenericErrorModel);
const changedCardConfirmationMessage = computed(() => `Du har bytt kort för <strong>${selectedProduct.product?.productPackage?.name}</strong> med order id: <strong>${selectedProduct.product?.id}</strong>`);

const { open, close } = useModal({
  component: ErrorModal,
  attrs: {
    model: modalModel,
    onConfirm() {
      close();
    },
  },
  slots: {
    default: '<div id="checkout-container-div" />',
  },
});

const { open: openChangeCardConfirmationModal, close: closeChangeCardConfirmationModal } = useModal({
  component: ConfirmModal,
  attrs: {
    title: 'Byt kort',
    icon: 'far fa-check-circle',
    body: changedCardConfirmationMessage,
    onConfirm() {
      closeChangeCardConfirmationModal();
    },
    onCancel() {
      closeChangeCardConfirmationModal();
    },
  },
});


const form = reactive({
  customerEmail: customerEssentials.value.customerEmail,
  customerPhone: customerEssentials.value.customerPhone,
  notificationBySms: customerEssentials.value.notificationBySms,
  notificationByMail: customerEssentials.value.notificationByMail,
  newsLetterSubscription: customerEssentials.value.newsLetterSubscription,
});

const rules = computed(() => {
  return {
    customerEmail: {
      required: helpers.withMessage('Vänligen fyll i detta fält', required),
      email: helpers.withMessage('Vänligen fyll i en giltig e-postadress', email)
    },
    customerPhone: {
      valid_cellphone: {
        $validator: isValidSwedishCellPhone,
        $message: "Vänligen fyll i ett giltigt mobilnummer"
      },
      required: helpers.withMessage('Vänligen fyll i detta fält', required)
    },
  }
});

const v$ = useVuelidate(rules, form);

onMounted(() => {
  document.title = props.model.name || "Mina tjänster";
  store.dispatch(CUSTOMER_INFO_FETCH, { reFetch: false, checkCancellableProducts: true });
  if (store.state.customerInfoContex.loaded) {
    form.customerEmail = customerEssentials.value.customerEmail;
    form.customerPhone = customerEssentials.value.customerPhone;
    form.notificationBySms = customerEssentials.value.notificationBySms;
    form.notificationByMail = customerEssentials.value.notificationByMail;
    form.newsLetterSubscription = customerEssentials.value.newsLetterSubscription;
  }
  if (!store.state.umbrellaMembersContext.loaded && store.state.customerInfoContex.customerEssentials.customerProducts.umbrellaProductId) {
    store.dispatch(UMBRELLA_MEMBERS_FETCH, { umbrellaProductId: store.state.customerInfoContex.customerEssentials.customerProducts.umbrellaProductId });
  }
});

watch(customerEssentialsFailed, () => {
  if (customerEssentialsFailed.value && hasSubmitted.value) {
    showSavedAlert.value = false;
    showFailedAlert.value = true;
  }
})

watch(hasRecentlyPayedInvoiceForTerminatedProduct, () => {
  if (hasRecentlyPayedInvoiceForTerminatedProduct.value) {
    $('#infoRecentlyPayedInvoice').modal('show');
  }
});

watch(customerEssentialsLoaded, () => {
  if (customerEssentialsLoaded.value && hasSubmitted.value) {
    form.customerEmail = customerEssentials.value.customerEmail;
    form.customerPhone = customerEssentials.value.customerPhone;
    form.notificationBySms = customerEssentials.value.notificationBySms;
    form.notificationByMail = customerEssentials.value.notificationByMail;
    form.newsLetterSubscription = customerEssentials.value.newsLetterSubscription;

    dataChanged.value = false;

    showSavedAlert.value = true;
    showFailedAlert.value = false;
  }
})

const onCloseAlert = () => {
  showSavedAlert.value = false;
  showFailedAlert.value = false;
}

const formatDate = (timestamp) => {
  if (timestamp == null) {
    return '';
  }
  return FormatDate(parseInt(timestamp));
};

const reFetchUmbrellaMembers = () => {
  store.dispatch(UMBRELLA_MEMBERS_FETCH, { umbrellaProductId: store.state.customerInfoContex.customerEssentials.customerProducts.umbrellaProductId });
}

const translatePaymentType = (paymentType) => {
  switch (paymentType) {
    case "INVOICE":
      return "Faktura";
    case "CARD":
      return "Kort";
    case "RESELLER":
      return "Återförsäljare";
    case "UMBRELLA":
      return "Medlem";
    default:
      return paymentType;
  }
};

const onTerminateProductClicked = (e, product) => {
  e?.preventDefault();
  selectedProduct.product = product;
  nextTick(() => {
    $('#terminate-product').modal('show');
  });

};

const onTerminateProduct = (e) => {
  e.preventDefault();
  store.dispatch(CUSTOMER_INFO_POST_TERMINATE_PRODUCT, {
    pppid: selectedProduct.product?.productPackage?.id,
    hasRecentlyPayedInvoice: selectedProduct.product?.productPackage?.recurringPeriodInMonth === 12
  });
  nextTick(() => {
    $('#terminate-product').modal('hide');
  });
};

const onDataChange = () => {
  if (!v$.value.$error) {
    dataChanged.value = true;
  } else {
    dataChanged.value = false;
  }
};
const submitCustomerSettings = () => {
  const csrfToken = $("input[name=__RequestVerificationToken]").val();
  showSavedAlert.value = false;
  showFailedAlert.value = false;
  hasSubmitted.value = true;
  store.dispatch(CUSTOMER_INFO_POST_SETTINGS, {
    csrfToken,
    email: form.customerEmail,
    phoneNumber: form.customerPhone,
    smsNotification: form.notificationBySms,
    newsLetterSubscription: form.newsLetterSubscription,
    mailNotification: form.notificationByMail,
  });
};

const onChangeCard = (e, product) => {
  e?.preventDefault();
  selectedProduct.product = product;
  modalModel.value = changeCardModelLoading;

  open();
  const pppid = selectedProduct.product?.id;
  postNeteasyChangeCardPaymentId({ pppid }).then(response => {
    const paymentId = response.data.paymentId;
    if (paymentId) {
      modalModel.value = changeCardModel;
      const checkoutOptions = {
        checkoutKey: $('#netscheckoutkey').val(),
        paymentId: paymentId,
        containerId: "checkout-container-div",
        language: "sv-SE"
      };
      // eslint-disable-next-line
      const checkout = new Dibs.Checkout(checkoutOptions);
      checkout.on('payment-completed', () => {
        close();
        return openChangeCardConfirmationModal();
      });
    } else {
      modalModel.value = defaultGenericErrorModel;
      return open();
    }
  }).catch(() => {
    modalModel.value = defaultGenericErrorModel;
    return open();
  });
};
</script>
<style lang="scss" scoped>
.submit-button {
  .submit-button-content {
    display: inline-flex;
    align-items: center;
  }
}
</style>
<style lang="scss" scoped>
@import "@/../../styles/abstracts/_variables.scss";
@import "@/../../styles/abstracts/_breakpoints.scss";

.alert-dismissible {
  padding-left: 65px;
}

.hide {
  display: none;
}

input {
  border-color: $silver;
}

table {
  tr:hover td {
    background: inherit;
    color: inherit;
  }

  td {
    border-top: 0;
    padding: 11px 16px;
    font-size: 14px;
    vertical-align: middle;

    @include breakpoint(max-tablet-portrait) {
      font-size: 12px;
    }
  }

  tbody tr {
    background-color: $light-smoke;

    td:first-child {
      width: 18%;

      @include breakpoint(max-tablet-portrait) {
        width: auto;
      }
    }

    td:nth-child(2) {
      width: 20%;

      @include breakpoint(max-tablet-portrait) {
        width: auto;
      }
    }

    td:last-child {
      width: 10%;

      @include breakpoint(max-tablet-portrait) {
        width: auto;
      }
    }
  }
}
</style>
