<template>
  <div class="related__articles__block block-container">
    <div class="container article__teaser__container block-container py-5">
      <div
        v-if="categoryLinks != null"
        class="row d-flex mb-4"
      >
        <div class="col-12">
          <ul class="nav category-listing">
            <li
              v-for="(category, index) in categoryLinks"
              :key="`article_list_category_${index}`"
              class="nav-item mr-2 mt-2 mt-md-0"
              :class="category.Active ? 'active' : ''"
            >
              <a
                class="nav-link"
                :href="category.Url"
              >{{ category.Text }}</a>
            </li>
          </ul>
        </div>
      </div>
      <div class="row">
        <div
          v-for="(article, index) in chunk"
          :key="`article_list_row_${index}`"
          class="col-lg-4 col-md-6 related__articles__teaser mb-2"
        >
          <div :class="index > 6 ? 'fade-in' : ''">
            <a
              :href="article.PageUrl"
              :title="article.Heading"
            >
              <div class="box-shadow">
                <div class="related__articles__teaser-overlay">
                  <div
                    class="related__articles__teaser-img img__scale-zoom"
                    :style="'background:url(' + article.BackgroundImageUrl + ');'"
                  />
                </div>
                <div class="related__articles__teaser__txt-container mb-3">
                  <h4 class="text-black">{{ article.Heading }}</h4>
                  <div
                    v-if="article.CategoryName != null && article.CategoryName != ''"
                    class=" related__articles__teaser-description"
                  >
                    <div class="related__articles__teaser-category mr-2"><span
                      class="text-white"
                    >{{ article.CategoryName }}</span></div>
                    <p
                      class="d-inline"
                      v-html="article.Description"
                    />
                  </div>

                </div>
              </div>
            </a>
          </div>
        </div>
      </div>
      <div
        v-if="chunk.length < articles.length"
        class="row"
      >
        <div class="col-md-6 offset-md-3 pt-5">
          <p
            id="loadMore"
            style="text-align: center;"
          >
            <a
              class="btn btn-default btn-lg"
              @click="getMoreItems"
            >Läs in fler artiklar</a>
          </p>
        </div>
      </div>
    </div>
  </div>
</template>
<script setup>

import { ref, onMounted } from 'vue';

const props = defineProps({
    articles: Array,
    categoryLinks: Array
})

const chunk = ref([]);

onMounted(() => {
    chunk.value = props.articles.slice(0, 6);
});

const getMoreItems = () => {
    chunk.value = props.articles.slice(0, chunk.value.length + 6);
}
</script>
