<template>
  <div
    v-if="items.length > itemsPerPage && pagingEnabled"
    :class="[marginBottom ? marginBottom : 'col-12', { 'col-12': !marginBottom }]"
  >
    <ul class="paging-nav d-flex justify-content-end mt-3 mb-lg-0 pl-0">
      <li
        v-if="activeSpan > 1"
        class="hover-pointer mx-1"
        @click="ShowSpan(activeSpan-1)"
      >
        <span>
          <i class="far fa-chevron-left fa-fw" />
        </span>
      </li>
      <li
        v-for="index in (Math.ceil(items.length / itemsPerPage))"
        :key="index"
        :class="index == activeSpan ? 'active bg-darkocean hover-pointer mx-1':'hover-pointer mx-1'"
        :bind="activeSpan"
        @click="ShowSpan(index)"
      >
        <span :class="index == activeSpan ? 'color-white':''">{{ index }}</span>
      </li>
      <li
        v-if="activeSpan < (items.length / itemsPerPage)"
        class="hover-pointer ml-1"
        @click="ShowSpan(activeSpan+1)"
      >
        <span>
          <i class="far fa-chevron-right fa-fw" />
        </span>
      </li>
    </ul>
  </div>
</template>
<script>
export default {
  name: 'Paging',
  props: {
    items: Array,
    pagingEnabled: Boolean,
    itemsPerPage: Number,
    marginBottom: String
  },
  data: function () {
    return {
      activeSpan: 1,
      itemsChunk: []
    }
  },
  created: function () {
    if (this.items.length > 0) {
      this.ShowSpan(1);
    }
  },
  methods: {
    ShowSpan: function (span) {
      var index = 0;
      var self = this;
      if (((span * self.itemsPerPage) - self.itemsPerPage) < self.items.length) {
        this.activeSpan = span;
        self.itemsChunk = Array();
        this.items.forEach(function (item) {

          if (index < (span * self.itemsPerPage) && index >= (span * self.itemsPerPage - self.itemsPerPage)) {
            self.itemsChunk.push(item);
            self.$emit('chunk', self.itemsChunk);
          }
          index++;
        });
      }
    }
  }
}
</script>