<template>
  <div class="row">
    <div class="col-md-12">
      <div class="d-flex justify-content-between p-2">
        <h4 class="mb-0">
          Avbetalningskrediter
        </h4>
        <NdsIconButton theme="enento" variant="text" prefix="far" iconName="question-circle"
          @click="openHirePurchaseQuestion" aria-label="Förklaring Avbetalningskrediter" />
      </div>

      <div v-if="hasTerminatedHirePurchase" class="d-flex justify-content-end my-3">
        <NdsButton theme="enento" :prefix="{ prefix: 'far', iconName: showTerminated ? 'eye-slash' : 'eye' }"
          variant="secondary" @click="showTerminated = !showTerminated">
          {{ showTerminated ? 'Dölj avslutade' : 'Visa avslutade' }}
        </NdsButton>
      </div>
      <div v-if="hirePurchase.length > 0" class="block__enento__accordion__card-wrapper">
        <template v-for="(inquiry, $index) in hirePurchase">
          <div v-if="inquiry.toDate == null || showTerminated" :key="$index" class="block__enento__accordion__card">
            <div class="panel-heading">
              <a class="d-block collapsed p-3 pr-5" data-toggle="collapse" :href="'#downpayment-' + $index">
                <span class="font-weight-bold">{{ inquiry.creditor }}</span>
              </a>
            </div>
            <div :id="'downpayment-' + $index" class="collapse p-3">
              <div class="row pb-3">
                <div class="col-8">
                  Kreditlimit:
                </div>
                <div class="col-4 pl-0 text-right">
                  {{ NumberFormat(inquiry.grantedCredit) }} kr
                </div>
                <div class="col-8 color-ocean">
                  Utnyttjad kredit:
                </div>
                <div class="col-4 pl-0 color-ocean text-right">
                  {{ NumberFormat(inquiry.usedCredit) }} kr
                </div>
              </div>
              <div class="row">
                <template v-if="inquiry.fromDate != null">
                  <div class="col-8">
                    Startdatum:
                  </div>
                  <div class="col-4 pl-0 text-right">
                    {{ FormatDate(inquiry.fromDate.timeStamp.value) }}
                  </div>
                </template>
                <template v-if="inquiry.toDate == null || inquiry.usedCredit > 0">
                  <div class="col-8">
                    Senast uppdaterad:
                  </div>
                  <div class="col-4 pl-0 text-right">
                    {{ FormatDate(inquiry.updatedAtDate.timeStamp.value) }}
                  </div>
                </template>
                <template v-if="inquiry.toDate != null">
                  <div class="col-8 color-red">
                    Avslutad:
                  </div>
                  <div class="col-4 pl-0 color-red text-right">
                    {{ FormatDate(inquiry.toDate.timeStamp.value) }}
                  </div>
                </template>
              </div>
            </div>
          </div>
        </template>
      </div>
      <ZeroState v-else icon="fas fa-credit-card"
        :text="model.descriptionNoHirePurchaseDevelopment.value || 'Inga avbetalningskrediter registrerade'"
        :isRichText="true" />
    </div>
    <div class="col-md-12 mt-3">
      <div class="d-flex justify-content-between p-2">
        <h4 class="mb-0">
          Kreditutveckling
        </h4>
        <NdsIconButton theme="enento" variant="text" prefix="far" iconName="question-circle"
          @click="openCreditDevelopmentQuestion" aria-label="Förklaring Kreditutveckling" />
      </div>
      <div class="ndsCreditChartContainer">
        <NdsCreditPlotChart title="" emptyTitle="Ingen kreditutveckling är registrerad" :creditData="creditData" />
      </div>
    </div>
  </div>
  <ModalsContainer />
</template>
<script setup>
import { ref, computed } from 'vue';
import { ModalsContainer, useModal } from 'vue-final-modal';
import { NumberFormat, FormatDate } from '../_helpers.js';
import { NdsIconButton, NdsButton, NdsCreditPlotChart } from '@nds/vue';
import ConfirmModal from '../components/modals/ConfirmModal.vue';
import ZeroState from "../components/myinformation/ZeroState.vue";

const props = defineProps({
  model: Object,
  report: Object,
});

const showTerminated = ref(false);
const hasTerminatedHirePurchase = ref(false);
const hirePurchase = computed(() => {
  if (props.report.creditReport?.ctiCreditsWithInquirer?.creditInformations) {
    return props.report.creditReport.ctiCreditsWithInquirer.creditInformations.map((creditInformation) => {
      if (props.report.creditReport.ctiCreditWithInquirerToCreditTypeMapping[creditInformation.creditNumber] === 4) {
        if (creditInformation.toDate && !hasTerminatedHirePurchase.value) {
          hasTerminatedHirePurchase.value = true;
        }
        return creditInformation;
      }
      return null;
    }).filter(Boolean);
  }
  return [];
});

const { open: openHirePurchaseQuestion, close: closeHirePurchaseQuestion } = useModal({
  component: ConfirmModal,
  attrs: {
    title: props.model.questionHirePurchaseTitle.value || 'Avbetalningskrediter',
    icon: 'far fa-question-circle',
    body: props.model.questionHirePurchase.value,
    onConfirm() {
      closeHirePurchaseQuestion();
    },
    onCancel() {
      closeHirePurchaseQuestion();
    },
  },
});

const { open: openCreditDevelopmentQuestion, close: closeCreditDevelopmentQuestion } = useModal({
  component: ConfirmModal,
  attrs: {
    title: props.model.questionHirePurchaseDevelopmentTitle.value || 'Kreditutveckling',
    icon: 'far fa-question-circle',
    body: props.model.questionHirePurchaseDevelopment.value,
    onConfirm() {
      closeCreditDevelopmentQuestion();
    },
    onCancel() {
      closeCreditDevelopmentQuestion();
    },
  },
});

const creditData = computed(() => ({
  futureCreditsLabel: 'Nuläge',
  usedCreditsLabel: 'Utnyttjad kredit',
  usedCreditsLabelTooltip: 'Utnyttjad',
  entries: props.report.hirePurchaseData.entries,
}));

</script>
<style scoped>
.ndsCreditChartContainer {
  width: 100%;
  height: 320px;
}
</style>