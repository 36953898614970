//newcreditwatchapi
import axios from 'axios';

const get = ({url, headers}) => {
    return axios({
        method: 'get',
        url: url,
        headers: Object.assign({}, headers)
    });
};

const basePath = '/newcreditwatchapi';
const getCreditReportPath = `${basePath}/getcreditreport`
const getNewNotificationsPath = `${basePath}/getnewnotifications`
const getFilteredNotificationsPath = `${basePath}/getfilterednotifications`
const getChallengesPath = `${basePath}/getchallenges`
const getSavingGoalsPath = `${basePath}/getsavinggoals`
const getSetNotificationStatusPath = (id) => `${basePath}/SetNotificationStatus/${id}`;

export const getCreditReport = () => get({url: getCreditReportPath, headers: { Accept: 'application/json'}});
export const getNewNotifications = () => get({url: getNewNotificationsPath, headers: { Accept: 'application/json'}});
export const getSetNotificationStatus = (id) => get({url: getSetNotificationStatusPath(id), headers: { Accept: 'application/json'}});
export const getFilteredNotifications = () => get({url: getFilteredNotificationsPath, headers: { Accept: 'application/json'}});
export const getChallenges = () => get({url: getChallengesPath, headers: { Accept: 'application/json'}});
export const getSavingGoals = () => get({url: getSavingGoalsPath, headers: { Accept: 'application/json'}});