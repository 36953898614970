<template>
  <div data-testid="minupplysning-credits-tab">
    <div class="py-4">
      <h3 :class="[report.creditReport.ctiCreditSummary && introText ? 'mb-4' : 'mb-0']">
        <i class="far fa-sack-dollar fa-18 mr-2" />Kreditengagemang
      </h3>
      <div
        v-if="report.creditReport.ctiCreditSummary && introText"
        v-html="introText"
      />
    </div>
    <div>
      <div
        v-if="report.creditReport.ctiCreditSummary"
        class="row px-2 mb-4"
      >
        <div class="col-3 px-3 py-2">
          Totalt utnyttjat kredit
        </div>
        <div
          class="col-3 px-3 py-2 color-ocean font-weight-medium"
          data-testid="min-upplysning-credits-total-used-amount"
        >
          {{ formatToSek(report.creditReport.dailyCreditSummary.creditSummaries[0].usedCredits) }}
        </div>
        <div class="col-3 px-3 py-2">
          Antal krediter
        </div>
        <div
          class="col-3 px-3 py-2 font-weight-medium"
          data-testid="min-upplysning-credits-nr-of-credits"
        >
          {{ formatToAmount(report.creditReport.dailyCreditSummary.creditSummaries[0].numberOfCredits) }}
        </div>
        <div class="col-3 px-3 py-2">
          Totalt beviljad kredit
        </div>
        <div
          class="col-3 px-3 py-2 color-ocean font-weight-medium"
          data-testid="min-upplysning-credits-total-granted-amount"
        >
          {{ formatToSek(report.creditReport.dailyCreditSummary.creditSummaries[0].grantedCredits) }}
        </div>
        <div class="col-3 px-3 py-2">
          Antal kreditgivare
        </div>
        <div
          class="col-3 px-3 py-2 font-weight-medium"
          data-testid="min-upplysning-credits-nr-of-creditors"
        >
          {{ formatToAmount(report.creditReport.dailyCreditSummary.creditSummaries[0].numberOfCreditors) }}
        </div>
      </div>
      <div
        v-if="report.creditReport.ctiCreditSummary"
        class="dashboard__card py-2 pb-3 mb-3 d-none d-xl-block"
      >
        <div class="row">
          <ul class="nav nav-tabs flex-nowrap border-0 w-100">
            <li class="d-flex nav-item text-nowrap">
              <a
                data-toggle="tab"
                href="#blanco-tab"
                data-testid="min-upplysning-credits-blanco-tab"
                :class="activeTab == 5 ? 'active' : ''"
              >Blanco</a>
            </li>
            <li class="d-flex nav-item text-nowrap">
              <a
                data-toggle="tab"
                href="#downpayment-tab"
                data-testid="min-upplysning-credits-downpayment-tab"
                :class="activeTab == 4 ? 'active' : ''"
              >Avbetalningar</a>
            </li>
            <li class="d-flex nav-item text-nowrap">
              <a
                data-toggle="tab"
                href="#creditcard-tab"
                data-testid="min-upplysning-credits-credicard-tab"
                :class="activeTab == 1 ? 'active' : ''"
              >Kontokredit</a>
            </li>
            <li class="d-flex nav-item text-nowrap">
              <a
                data-toggle="tab"
                href="#property-tab"
                data-testid="min-upplysning-credits-properties-tab"
                :class="activeTab == 7 ? 'active' : ''"
              >Fastighet</a>
            </li>
            <li class="d-flex nav-item text-nowrap">
              <a
                data-toggle="tab"
                href="#tenantownership-tab"
                data-testid="min-upplysning-credits-tenant-tab"
                :class="activeTab == 9 ? 'active' : ''"
              >Bostadsrätt</a>
            </li>
            <li class="d-flex nav-item text-nowrap">
              <a
                data-toggle="tab"
                href="#csn-tab"
                data-testid="min-upplysning-credits-csn-tab"
                :class="activeTab == 10 ? 'active' : ''"
              >CSN-lån</a>
            </li>
            <li class="d-flex flex-fill nav-item">
              <a
                href="#"
                class="pe-none flex-fill"
              />
            </li>
          </ul>
        </div>
        <div class="tab-content py-3">
          <div
            id="blanco-tab"
            class="tab-pane pt-0"
            :class="activeTab == 5 ? 'active' : ''"
          >
            <CreditsTab
              :credit-type="5"
              :report="report"
              title="Blanco"
            />
          </div>
          <div
            id="downpayment-tab"
            class="tab-pane pt-0"
            :class="activeTab == 4 ? 'active' : ''"
          >
            <CreditsTab
              :credit-type="4"
              :report="report"
              title="Avbetalningar"
            />
          </div>
          <div
            id="creditcard-tab"
            class="tab-pane pt-0"
            :class="activeTab == 1 ? 'active' : ''"
          >
            <CreditsTab
              :credit-type="1"
              :report="report"
              title="Kontokredit"
            />
          </div>
          <div
            id="property-tab"
            class="tab-pane pt-0"
            :class="activeTab == 7 ? 'active' : ''"
          >
            <CreditsTab
              :credit-type="7"
              :report="report"
              title="Fastighet"
            />
          </div>
          <div
            id="tenantownership-tab"
            class="tab-pane pt-0"
            :class="activeTab == 9 ? 'active' : ''"
          >
            <CreditsTab
              :credit-type="9"
              :report="report"
              title="Bostadsrätt"
            />
          </div>
          <div
            id="csn-tab"
            class="tab-pane pt-0"
            :class="activeTab == 10 ? 'active' : ''"
          >
            <Csn
              v-if="report.creditReport.ctiCsnLoan"
              :cti-csn-loan="report.creditReport.ctiCsnLoan"
            />
            <ZeroState
              v-else
              icon="far fa-graduation-cap"
              text="Inget CSN-lån registrerat"
              data-testid="min-upplysning-credits-csn-zerostate"
            />
            <span class="body-xsmall mt-2 mb-0"><i
              class="far fa-book mr-1"
              data-testid="min-upplysning-credits-csn-source"
            />Källa: CSN</span>
          </div>
        </div>
      </div>
      <ZeroState
        v-else
        icon="far fa-sack-dollar"
        text="Ingen kredit registrerad"
      />
      <span class="d-block body-xsmall px-2 pb-3">
        <i class="far fa-book mr-1" />Källa: Kreditgivare i Sverige
      </span>
    </div>
  </div>
</template>
<script setup>
import { ref, onMounted } from 'vue';
import { formatToSek, formatToAmount } from '../../_helpers.js';
import ZeroState from '../../components/myinformation/ZeroState.vue';
import CreditsTab from '../../components/myinformation/CreditsTab.vue';
import Csn from "../../components/Csn.vue";

const props = defineProps({
    report: Object,
    introText: String
});

const activeTab = ref(5);

onMounted(() => {
    if (props.report.creditReport.ctiCreditsWithInquirer) {
        for (const item of props.report.creditReport.ctiCreditsWithInquirer.creditInformations) {
            if (props.report.creditReport.ctiCreditWithInquirerToCreditTypeMapping[item.creditNumber] === 4) {
                activeTab.value = 4;
                return;
            }
        }
        for (const item of props.report.creditReport.ctiCreditsWithInquirer.creditInformations) {
            if (props.report.creditReport.ctiCreditWithInquirerToCreditTypeMapping[item.creditNumber] === 1) {
                activeTab.value = 1;
                return;
            }
        }
        for (const item of props.report.creditReport.ctiCreditsWithInquirer.creditInformations) {
            if (props.report.creditReport.ctiCreditWithInquirerToCreditTypeMapping[item.creditNumber] === 7) {
                activeTab.value = 7;
                return;
            }
        }
        for (const item of props.report.creditReport.ctiCreditsWithInquirer.creditInformations) {
            if (props.report.creditReport.ctiCreditWithInquirerToCreditTypeMapping[item.creditNumber] === 9) {
                activeTab.value = 9;
                return;
            }
        }
    }
    else if (props.report.creditReport.ctiCsnLoan !== null) {
        activeTab.value = 10;
    }
});
</script>