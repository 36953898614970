<template>
  <div
    class="dashboard__card px-2 py-0"
    data-testid="minupplysning-mobile-income-tab"
  >
    <div class="px-2 py-3">
      <h3 class="mb-0">
        <i class="far fa-money-bill fa-18 mr-2" />Inkomstuppgifter
      </h3>
    </div>
    <IncomeAndTaxationMobile
      v-if="creditReport.ctiConsumerIncomeAndTaxation"
      :cti-consumer-income-and-taxation="creditReport.ctiConsumerIncomeAndTaxation"
      :sole-trader="creditReport.extendedConsumerInformation.soleTrader"
    />
    <ZeroState
      v-else
      icon="far fa-money-bill"
      text="Inga inkomstuppgifter registrerade"
    />
    <div class="d-flex row px-2 pb-3 mx-1">
      <span
        class="body-small mb-3"
        v-html="incomeBottomText"
      />
      <span class="body-xsmall mb-0"><i class="far fa-book mr-1" />Källa: Skatteverket</span>
      <span
        v-if="lastUpdatedFormatedDate"
        class="body-xsmall mb-0 ml-auto"
      >
        <i class="far fa-clock mr-1" />Senast uppdaterad: {{ lastUpdatedFormatedDate }}
      </span>
    </div>
  </div>
</template>
<script setup>
import { computed } from 'vue';
import { FormatDate } from '../../_helpers.js';
import IncomeAndTaxationMobile from '../../components/incomeAndTaxation/IncomeAndTaxationMobile.vue';
import ZeroState from "../../components/myinformation/ZeroState.vue";

const props = defineProps({
    creditReport: Object,
    incomeBottomText: String,
});

const lastUpdatedFormatedDate = computed(() => {
    if (props.creditReport.ctiConsumerIncomeAndTaxation?.incomeAndTaxation[0].updateDate) {
        return FormatDate(props.creditReport.ctiConsumerIncomeAndTaxation.incomeAndTaxation[0].updateDate.timeStamp.value)
    }
    return false;
});
</script>