export default {
    personId: {
        required: true,
        ssn: true
    },
    email: {
        required: true,
        email: true
    },
    description: {
        required: true,
        maxlength: 500
    },
    policyCheck: {
        required: true
    },
}