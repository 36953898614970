<template>
  <div
    class="block fullimagecardblock"
    data-testid="full-image-card-block"
  >
    <div
      class="article-block full-image-card"
      :class="`bg-${model.BackgroundTheme} ${cardMinHeightStyle} ${cardVerticalAlignStyle}`"
    >
      <div
        class="background-cover rounded-border"
        :style="cardBackgroundImageStyle"
      >
        <div
          class="text-container"
          :class="cardContentTextAlignStyle"
        >
          <h3
            v-if="model.Heading"
            :class="'color-' + model.TextColorTheme"
          >
            <em
              v-if="model.FACode"
              class="color-ocean"
              :class="model.FACode"
            />
            {{ model.Heading }}
          </h3>
          <div
            v-if="model.SectionText"
            :class="'color-' + model.TextColorTheme"
            class="enento-tinymce"
            v-html="sectionText"
          />
          <div
            v-if="model.ButtonContentArea"
            class="nds-button-container"
          >
            <ButtonBlock
              v-for="(buttonBlock, index) in model.ButtonContentArea"
              :key="index"
              :model="buttonBlock"
              :is-single-block="model.ButtonContentArea?.length === 1"
              :class="cardContentTextAlignStyle"
            />
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script setup>
import { computed } from 'vue';
import ButtonBlock from './ButtonBlock.vue';
import useCookieConsent from '../../utils/cookieConsentUtils';

const props = defineProps({
  model: Object,
});

const { getCookieConsenXHTML } = useCookieConsent();
const cookieConsenXTML = getCookieConsenXHTML({ id: 'fullImageCard', classes: 'cookie-consent-link-button' });

const cardMinHeightStyle = computed(() => {
  if (props.model.CardSize === "Large") {
    return 'full-image-card--lg';
  }
  if (props.model.CardSize === "Medium") {
    return 'full-image-card--md';
  }
  return '';
});

const cardVerticalAlignStyle = computed(() => {
  if (props.model.VerticalAlign === "top") {
    return 'vertical-align-top';
  }
  if (props.model.VerticalAlign === "middle") {
    return 'vertical-align-middle';
  }
  return '';
});

const cardContentTextAlignStyle = computed(() => {
  if (props.model.SectionTextAlign === "center") {
    return 'text-center';
  }
  if (props.model.SectionTextAlign === "right") {
    return 'text-align-right';
  }
  return 'text-align-left';
});

const cardBackgroundImageStyle = computed(() => `background: url("${props.model.BackgroundImage}") center center no-repeat; background-size: cover;`);

const sectionText = computed(() => props.model.SectionText?.replace(/\{consent\}/i, cookieConsenXTML));

</script>
<style lang="scss">
@import "@/../../styles/abstracts/_variables.scss";
@import "@/../../styles/abstracts/_breakpoints.scss";

.nds-button-container {
  margin: 1.5rem 0 1rem 0;
  padding-bottom: 1rem;
  gap: 1rem;
  display: flex;
  flex-direction: row;
  align-items: center;
  flex-wrap: wrap;
}

@include breakpoint(max-phone) {
  .nds-button-container {
    flex-direction: column;
  }
}

.cookie-consent-link-button {
  background: none;
  color: $dark-ocean;
  border: none;
  padding: 0;
  font: inherit;
  cursor: pointer;
  outline: inherit;

  &:hover {
    text-decoration: underline;
  }
}
</style>