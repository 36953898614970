import $ from 'jquery';
import toastr from 'toastr';
$(function () {

    toastr.options = {
        "closeButton": true,
        "debug": false,
        "newestOnTop": false,
        "progressBar": false,
        "positionClass": "toast-top-right",
        "preventDuplicates": false,
        "onclick": null,
        "showDuration": "300",
        "hideDuration": "1000",
        "timeOut": "5000",
        "extendedTimeOut": "1000",
        "showEasing": "swing",
        "hideEasing": "linear",
        "showMethod": "fadeIn",
        "hideMethod": "fadeOut"
    }

});

export function showErrorToast(errorMsg) {
    toastr.error(errorMsg, 'Ett fel inträffade', { timeOut: 0 });
}

export function showInfoToast() {
    toastr.info('Matnyttig information för besökaren','Ojojoj..');
}

export function showSuccessToast(msg) {
    toastr.success(msg);
}

/*
DOKUMENTATION: https://github.com/CodeSeven/toastr
*/