/**
 * Sets up the router that is used in View mode. When the site is loaded in
 * CMS editing mode, the whole page is always reloaded and the router only gets
 * the current page. The router will not be used for navigating between pages.
 */

import { createRouter, createWebHistory } from 'vue-router';
import { useStore } from 'vuex';
import { EPI_DATA_MODEL_UPDATE_MODEL_BY_URL } from './store/modules/epiDataModel';
import { APP_SHOW_SIDE_NAVIGATION, APP_HIDE_SIDE_NAVIGATION, APP_FETCH_SIDEBAR_MENU } from './store/modules/appContext';
import PageComponentSelector from './components/PageComponentSelector.vue';
import { getPathnameFromUrl } from '../components/checkout/checkoutUtils';

const router = createRouter({
    // Use the HTML HistoryAPI so the # isn't needed in the URL, and
    // Vue routing will work even when going directly to a URL.
    mode: 'history',
    history: createWebHistory(),
    routes: [
        {
            path: '/:pathMatch(.*)*',
            component: PageComponentSelector
        }
    ],
    scrollBehavior() {
        return { top: 0 }
    }
});

router.beforeEach((to, _, next) => {
    const store = useStore();

    if (to.fullPath.includes('checkout')) {
        store.dispatch(APP_HIDE_SIDE_NAVIGATION);
    } else {
        store.dispatch(APP_SHOW_SIDE_NAVIGATION);
    }
    const url = store.state && store.state.epiDataModel.model.url ? getPathnameFromUrl(store.state.epiDataModel.model.url) : '/';
    if (url !== to.fullPath) {
        store.dispatch(EPI_DATA_MODEL_UPDATE_MODEL_BY_URL, to.fullPath);
    }
    if (to.query.mailbox) {
        // handles update sidebar menu when user purchases digital mail box
        const queryParams = { ...to.query };
        delete queryParams.mailbox;
        store.dispatch(APP_FETCH_SIDEBAR_MENU);
        return next({ path: to.path, query: queryParams });
    }

    next();
});

export default router;
