<template>
  <div>
    <div
      id="terminate-product"
      ref="terminate-product-modal"
      class="modal fade"
      tabindex="-1"
      role="dialog"
      aria-labelledby="teriminate-modal"
      aria-hidden="true"
    >
      <div
        class="modal-dialog modal-dialog-centered"
        role="document"
      >
        <slot />
      </div>
    </div>
  </div>
</template>
<script setup>

</script>