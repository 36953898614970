<template>
  <div
    class="dashboard__card px-2 py-0"
    data-testid="minupplysning-mobile-inquiries-tab"
  >
    <div class="d-flex align-items-center px-2 py-3">
      <h3 class="mb-0">
        <i class="far fa-user fa-18 mr-2" />Senaste frågor hos UC
      </h3>
      <a
        class="ml-auto"
        data-toggle="modal"
        data-target="#question-inquiries"
        aria-expanded="false"
        aria-controls="question-inquiries"
      ><i class="far fa-question-circle color-darkocean hover-pointer" /></a>
    </div>
    <p
      v-if="report.creditReport.ctiConsumerInquires"
      class="p-2"
    >
      Antal registrerade förfrågningar hos UC de senaste 12 månaderna: <span
        class="font-weight-bold"
        data-testid="min-upplysning-inquiries-noTotal"
      >{{ inquiriesAmount }}</span>
    </p>
    <ConsumerInquiries
      v-if="report.creditReport.ctiConsumerInquires"
      :consumer-inquiries="report.creditReport.ctiConsumerInquires"
    />
    <template v-if="report.creditReport.ctiConsumerInquires && report.creditReport.ctiConsumerInquires.inquiriesMinucOnly && report.creditReport.ctiConsumerInquires.inquiriesMinucOnly.length > 0">
      <div class="d-flex align-items-center px-2 py-3">
        {{ report.creditReport.ctiConsumerInquires.inquiriesMinucOnly.length }} frågor har registrerats hos UC utan att frågorna redovisas i upplysningen
      </div>
      <ConsumerInquiriesMinucOnly
        v-if="report.creditReport.ctiConsumerInquires"
        :consumer-inquiries="report.creditReport.ctiConsumerInquires"
      />
    </template>
    <ZeroState
      v-if="!report.creditReport.ctiConsumerInquires"
      icon="far fa-comments"
      text="Ingen förfrågan registrerad"
    />
    <span class="d-block body-xsmall px-2 pb-3"><i class="far fa-book mr-1" />Källa: UC</span>
    <InfoModal
      v-if="introText"
      id="question-inquiries"
      title="Senaste frågor hos UC"
      :body="introText.value"
    />
  </div>
</template>
<script setup>
import { computed } from 'vue';
import {
    formatToAmount
} from '../../_helpers.js';
import ConsumerInquiries from '../../components/ConsumerInquiries.vue';
import ConsumerInquiriesMinucOnly from '../../components/ConsumerInquiriesMinucOnly.vue';
import ZeroState from '../../components/myinformation/ZeroState.vue';
import InfoModal from "../../components/modals/InfoModal.vue";

const props = defineProps({
    report: Object,
    introText: String
});

const inquiriesAmount = computed(() => {
    if (props.report.creditReport?.ctiConsumerInquires) {
        const today = new Date(); // Get today's date
        const twelveMonthsAgo = new Date(today.getFullYear() - 1, today.getMonth(), today.getDate()); // Get the date 12 months ago
        // Filter the inquiries that were made in the last 12 months
        const inquiriesLast12Months = props.report.creditReport.ctiConsumerInquires.inquiries.filter(inquiry => {
            const inquiryDate = new Date(inquiry.dateOfInquiry.timeStamp.value);
            return inquiryDate >= twelveMonthsAgo && inquiryDate <= today;
        });

        // return the count of inquiries made in the last 12 months formated
        return formatToAmount(inquiriesLast12Months.length);
    }
    return formatToAmount(0);
});
</script>