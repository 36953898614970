import axios from 'axios';

const basePath = '/view';
const messagesForErrorStatusPath = `${basePath}/MessagesForErrorStatus`;

const get = ({url, headers, params}) => {
    return axios({
        method: 'get',
        url,
        params,
        headers,
    });
};

export const getMessagesForErrorStatus = ({ statusCode })  => get({url: messagesForErrorStatusPath, params: { statusCode }, headers: { Accept: 'application/json', 'Content-Type': 'application/json' }});