import $ from 'jquery';
import { calcProduct, calcExtras, calcOrderPrice } from './_calculations.js';
$(document).ready(function () {

    var $orderForm = $('.validationFormOne');
    var $orderFormBlock = $('.validationFormOrderForm');
    var $orderFormProductPackage = $('.validationFormProductPackage');
    var $orderCustomerRegistration = $('.validationFormCustomerRegistration');
    var $orderLayer = $('.order-layer');
    var $orderOverlay = $('#order-overlay');

// CHECK AGE IF < OR > 21
    var personalIdentityNumberToAge = function (personalIdentityNumber) {
        var birthDate = personalIdentityNumber.substr(0, 8);
        var normalizedBirthDate = birthDate.substr(0, 4) + '-' + birthDate.substr(4, 2) + '-' + birthDate.substr(6, 2);
        var normalizedBirthDateObj = new Date(normalizedBirthDate);
        var now = new Date();

        var ageDifMs = now - normalizedBirthDateObj.getTime();
        var ageDate = new Date(ageDifMs);

        return Math.abs(ageDate.getUTCFullYear() - 1970);
    };

// ORDER FORM
    $orderLayer.hide();

    function initOrder($form) {
        $('html, body').animate({
            scrollTop: 0
        }, 'slow');
        var email = $form.find('input[name=email]').val();
        var personId = $form.find('input[name=personId]').val();
        var mobileNo = $form.find('input[name=phone]').val();
        var orderPageId = $form.find('input[name=orderpageid]').val();
        var orderPageId2 = $form.find('input[name=orderpageidunder21]').val();
        var registrationBlockId = $form.find('input[name=registration-block-id]').val();
        var digitalMailboxId = $form.find('input[name=digitalmailbox-id]').val();
        var age = personalIdentityNumberToAge(personId);

        var finalOrderPageId = orderPageId; // kör orderPageId by default

        if (orderPageId2 && age < 21) {
            finalOrderPageId = orderPageId2;
        }

        $.ajax({
            type: 'GET',
            url: '/ajax/View/GetOrderView',
            data: {
                productPackageOrderPage: finalOrderPageId,
                registrationBlockId: registrationBlockId,
                digitalMailboxId: digitalMailboxId
            },
            dataType: 'html',
            success: function (url) {
                $('.order-layer').append(url);
                $('.conditions-information').hide();
                calcProduct();
                calcExtras();
                calcOrderPrice();
                if (email !== '' && personId !== '' && mobileNo !== '') {
                    $("#listKey").val("Beställningsfönster");
                    $('#orderEmail').val(email);
                    $('#orderPersonId').val(personId);
                    $('#orderPhone').val(mobileNo);
                    $('.personalInformation, .discount-input').hide();
                } else {
                    $("#listKey").val("Beställningsfönster med formulär");
                    $('.discount-input').hide();
                    if ($('.order-layer .productpackage').length > 1) {
                        $("#orderProductPackeBtn").hide();
                        $("#orderMultipleChoiceBtn").show();
                        $('.conditions').hide();

                        $('.personalInformation').hide();
                    } else {
                        $('.personalInformation').show();
                    }
                }
                $('#order-overlay').css('background', 'rgba(0,0,0,0.6)');
                if ($("input[name='productradio']:checked").attr("data-numberOffFreeMonths") === "0" || $("input[name='productradio']:checked").attr("data-numberOffFreeMonthsText") === "") {
                    $('#productPackagePriceLabel').text($("input[name='productradio']:checked").attr('price') + " " + $("input[name='productradio']:checked").attr('description'));
                }
                else {
                    $('#productPackagePriceLabel').text($("input[name='productradio']:checked").attr("data-numberOffFreeMonthsText"));
                }
                $('#productPackageSubLabel').text($("input[name='productradio']:checked").attr('subdescription'));
                $orderLayer.find('form').validate({
                    errorPlacement(error, element) {
                        if (element.attr("name") === "conditionsPolicyCheck") {
                            error.addClass('checkbox-error-placement');
                            error.insertAfter($('.policy-error'));
                        } else if (element.attr("name") === "conditionsCheck") {
                            error.addClass('checkbox-error-placement');
                            error.insertAfter($('.conditions-error'));
                        } else {
                            error.insertAfter(element);
                        }
                    },
                    rules: {
                        orderPersonId: {
                            required: true,
                            ssn: true
                        },
                        orderEmail: {
                            required: true,
                            email: true
                        },
                        orderPhone: {
                            required: true,
                            phone: true
                        },
                        conditionsPolicyCheck: {
                            required: true
                        },
                        conditionsCheck: {
                            required: true
                        },
                    },
                    errorElement: 'small',
                    errorClass: 'is-invalid',
                });
            },
            error: function () {
            }
        });
        $orderLayer.fadeIn();
        $orderOverlay.fadeIn();
    }

    if ($orderLayer.parent().hasClass('editMode')) {
        $orderLayer.show();
    }

    $orderForm.on('submit', function (e) {
        if ($(this).parents('.buttonblock').length > 0) {
            e.preventDefault();
            if ($(this).find('button[type=submit]').hasClass('disabled')) {
                // Do not show orderLayer 
            } else {
                initOrder($(this));
            }
        }
    });
    $orderForm.on('validated', function (e) {
        if ($(this).parents('.buttonblock').length === 0) {
            e.preventDefault();
            if ($(this).find('button[type=submit]').hasClass('disabled')) {
                // Do not show orderLayer 
            } else {
                initOrder($(this));
            }
        }
    });


    $orderFormProductPackage.on('validated', function (e) {
        e.preventDefault();
        if ($(this).find('button[type=submit]').hasClass('disabled')) {
            // Do not show orderLayer 
        } else {
            initOrder($(this));
        }
    });
    $orderFormBlock.on('validated', function (e) {
        e.preventDefault();
        if ($(this).find('button[type=submit]').hasClass('disabled')) {
            // Do not show orderLayer 
        } else {
            initOrder($(this));
        }
    });

    $orderCustomerRegistration.on('validated', function (e) {
        e.preventDefault();
        if ($(this).find('button[type=submit]').hasClass('disabled')) {
            // Do not show orderLayer 
        } else {
            initOrder($(this));
        }
    });


    $(document.body).on("click", ".order-layer .close, .btn-modal-close", function () {
        $orderLayer.fadeOut();
        $(".order-layer .form").remove();
        $(".order-layer .overlay").remove();
        $("#order-overlay").hide();

    });


    /* CLOSE receipt */
    $(document.body).on("click", ".close-receipt", function () {
        $orderLayer.fadeOut();
        $('.dibs-layer').hide();
        $('#validationFormOne input#personid, #validationFormOne input#phone, #validationFormOne input#email').val('');

        $('.order-layer .overlay').remove();
        $('.order-layer .form').remove();

    });

    $(document.body).on("click", "#orderMultipleChoiceBtn", function (event) {
        event.preventDefault();
        $("#orderMultipleChoiceBtn").hide();
        $("#orderProductPackeBtn").show();
        $('.productpackage').hide();
        $('.productpackage.active').show();
        $('.productpackage.active .productpackage-marker').hide();
        $('.productpackage.active').removeClass('productpackage');
        $('.conditions').show();
        $('.personalInformation').show();
    });

    /* SET ACTIVE PRODUCT */
    $('.order-layer input:checkbox').removeAttr('checked');
    $('.order-layer .offers .product').removeClass('active');

    if ($('.order-layer .products').length) {
        $('.order-layer .products input[name=productradio]:checked').closest('.product').addClass('active');
    }

    //SHOW PURCHASE STORAGE WITH CORRECT #HASH IN URL
    var hash = location.hash.substring(1);
    if (hash === 'kop') {
        initOrder($(this));
    }

});
