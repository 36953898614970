<template>
  <div
    class="bottom-mobile-menu d-md-none d-flex align-items-center"
    data-testid="mobile-bottom-menu"
  >
    <div
      v-for="(item, index) in bottomMenu"
      :key="`mobile_bottom_menu${index}`"
      :data-test="item.Name"
      class="flex-grow-1"
    >
      <RouterLink
        :to="item.Url"
        :class="item.Url === route.path ? 'active':''"
      >
        <i :class="item.FontAwesomeClass" />
        <span>{{ item.Title }}</span>
      </RouterLink>
    </div>
  </div>
</template>
<script setup>
import { useStore,  } from 'vuex';
import { useRoute } from 'vue-router'
import { computed, onMounted } from 'vue';
import { APP_FETCH_BOTTOM_MENU } from '../Scripts/store/modules/appContext';

const store = useStore();
const route = useRoute();
const bottomMenu = computed(() => store.state.appContext.bottomMenu);

onMounted(() => {
    if(!store.state.appContext.bottomMenuLoaded) {
        store.dispatch(APP_FETCH_BOTTOM_MENU);
    }
});

</script>
<style lang="scss">
    .bottom-mobile-menu {
        position: fixed;
        bottom: 0;
        z-index: 10;
        height: 69px;
        width: 100%;
        background-color: #333;
        text-align: center;

        > div {
            display: grid;
        }

        a {
            i {
                color: #fff;
            }

            &.active {
                i {
                    color: #F39200;
                }
            }
        }

        span {
            font-size: 12px;
            line-height: 18px;
            font-weight: 400;
            color: #fff;
            font-family: 'Roboto';
            display: block;
            width: 100%;
        }
    }
</style>