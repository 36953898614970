<template>
  <div
    class="dashboard__card px-2 py-0"
    data-testid="minupplysning-mobile-remarks-tab"
  >
    <div class="d-flex align-items-center px-2 py-3">
      <h3 class="mb-0">
        <i class="far fa-user fa-18 mr-2" />Anmärkningar
      </h3>
      <a
        class="ml-auto"
        data-toggle="modal"
        data-target="#question-remarks"
        aria-expanded="false"
        aria-controls="question-remarks"
      ><i class="far fa-question-circle color-darkocean hover-pointer" /></a>
    </div>
    <div class="p-2">
      <p
        v-if="report.creditReport.ctiConsumerPaymentInformation && report.creditReport.ctiConsumerPaymentInformation.historicalComplaintsSpecified && report.creditReport.ctiConsumerPaymentInformation.historicalComplaintsSpecified.length > 0"
      >
        Det finns <span class="font-weight-medium">{{
          report.creditReport.ctiConsumerPaymentInformation.historicalComplaintsSpecified.length
        }} st</span>
        anmärkningar på totalt <span class="font-weight-medium">
          {{ complaintAmount }}</span>
        registrerade.
      </p>
    </div>
    <RemarksMobile
      v-if="report.creditReport.ctiConsumerPaymentInformation != null"
      :show-end-date="false"
      :complaints="report.creditReport.ctiConsumerPaymentInformation.historicalComplaintsSpecified"
    />
    <RemarksKfmMobile
      v-if="report.creditReport.ctiConsumerPaymentInformation != null"
      :complaints="report.creditReport.ctiConsumerPaymentInformation"
      :model="model"
    />
    <ZeroState
      v-else
      icon="far fa-comment-exclamation"
      text="Ingen anmärkning registrerad"
    />
    <span class="d-block body-xsmall px-2 pb-3"><i class="far fa-book mr-1" />Källa: Kronofogden, tingsrätt och
      kreditgivare</span>
    <InfoModal
      v-if="introText"
      id="question-remarks"
      title="Anmärkningar"
      :body="introText"
    />
  </div>
</template>
<script setup>
import { computed } from 'vue';
import RemarksMobile from '../../components/RemarksMobile.vue';
import ZeroState from '../../components/myinformation/ZeroState.vue';
import InfoModal from "../../components/modals/InfoModal.vue";
import RemarksKfmMobile from "../../components/RemarksKfmMobile.vue";
import { formatToSek } from '../../_helpers.js';

const props = defineProps({
  report: Object,
  introText: String,
  model: Object
});

const complaintAmount = computed(() => {
  if (props.report.creditReport?.ctiConsumerPaymentInformation?.historicalComplaintsSpecified) {
    return formatToSek(props.report.creditReport.ctiConsumerPaymentInformation.historicalComplaintsSpecified.map(({ complaintAmount }) => complaintAmount).reduce((a, b) => a + b, 0))
  }
  return formatToSek(0);
});

</script>