import {
    getNewNotifications,
    getSetNotificationStatus,
} from '../../providers/creditWatchApiProviders';

const CUSTOMER_CREDIT_NOTIFICATIONS = 'CUSTOMER_CREDIT_NOTIFICATIONS';
export const CUSTOMER_CREDIT_NOTIFICATIONS_FETCH = `${CUSTOMER_CREDIT_NOTIFICATIONS}/FETCH`;
export const CUSTOMER_CREDIT_NOTIFICATIONS_FETCH_AND_CLEAR_CATEGORY = `${CUSTOMER_CREDIT_NOTIFICATIONS}/FETCH_AND_CLEAR_CATEGORY`;
const CUSTOMER_CREDIT_NOTIFICATIONS_UPDATE = `${CUSTOMER_CREDIT_NOTIFICATIONS}/UPDATE`;
const CUSTOMER_CREDIT_NOTIFICATIONS_FAILED = `${CUSTOMER_CREDIT_NOTIFICATIONS}/FAILED`;
const CUSTOMER_CREDIT_NOTIFICATIONS_PENDING = `${CUSTOMER_CREDIT_NOTIFICATIONS}/PENDING`;

const fitlerDuplicateCategories = (customerCreditNotifications) => {
    const customerCreditNotificationsSet = new Set();
    return customerCreditNotifications.filter(notification => {
        const duplicate = customerCreditNotificationsSet.has(notification.Category);
        customerCreditNotificationsSet.add(notification.Category);
        return !duplicate;
    });
}

const state = {
    customerCreditNotifications: [],
    loaded: false,
    failed: false,
    pending: false,
    filterCategory: '', // "UC_SCORE", "SAVING_GOAL"
};

const mutations = {
    [CUSTOMER_CREDIT_NOTIFICATIONS_UPDATE](state, newModel) {
        state.customerCreditNotifications = fitlerDuplicateCategories(newModel);
        state.loaded = true;
        state.failed = false;
        state.pending = false;
    },
    [CUSTOMER_CREDIT_NOTIFICATIONS_FAILED](state) {
        state.customerCreditNotifications = [];
        state.loaded = true;
        state.failed = true;
        state.pending = false;
    },
    [CUSTOMER_CREDIT_NOTIFICATIONS_PENDING](state) {
        state.customerCreditNotifications = [];
        state.loaded = false;
        state.failed = false;
        state.pending = true;
    },
};

const actions = {
    [CUSTOMER_CREDIT_NOTIFICATIONS_FETCH]({ commit }) {
        commit(CUSTOMER_CREDIT_NOTIFICATIONS_PENDING);
        return getNewNotifications().then(response => {
            commit(CUSTOMER_CREDIT_NOTIFICATIONS_UPDATE, response.data);
        }).catch(() => {
            commit(CUSTOMER_CREDIT_NOTIFICATIONS_FAILED);
        });
    },
    [CUSTOMER_CREDIT_NOTIFICATIONS_FETCH_AND_CLEAR_CATEGORY]({ commit, dispatch }, { filterCategory }) {
        // TODO type categories, filterCategory examples "UC_SCORE", "SAVING_GOAL"
        commit(CUSTOMER_CREDIT_NOTIFICATIONS_PENDING);
        return getNewNotifications().then(response => {
            const { Id: uCnotificationId } = response.data.find(({ Category }) => Category === filterCategory);
            if (uCnotificationId) {
                getSetNotificationStatus(uCnotificationId).then(() => {
                    dispatch(CUSTOMER_CREDIT_NOTIFICATIONS_FETCH);
                });
            } else {
                commit(CUSTOMER_CREDIT_NOTIFICATIONS_UPDATE, response.data);
            }

        }).catch(() => {
            commit(CUSTOMER_CREDIT_NOTIFICATIONS_FAILED);
        });
    },
};

export const customerCreditNotificationsContex = {
    state,
    mutations,
    actions
};
