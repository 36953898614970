<template>
  <div class="col-12 mt-2">
    <div class="row">
      <div class="col-6">
        <div class="row">
          <div class="col-12">
            <div class="saving-goal-header-wrapper">
              {{ model.savingsGoalsTitle.value }}
              <a
                v-if="model.questionSavingGoal"
                class="ml-auto"
                data-toggle="modal"
                data-target="#saving-goal-question"
                aria-expanded="false"
                aria-controls="saving-goal-question"
              >
                <i class="far fa-question-circle color-darkocean hover-pointer" />
              </a>
            </div>
          </div>
        </div>
        <span
          v-if="savingGoals.length == 1"
          class="small-label"
        >{{ savingGoals.length }} aktivt sparmål</span>
        <span
          v-if="savingGoals.length > 1"
          class="small-label"
        >{{ savingGoals.length }} aktiva sparmål</span>
        <span
          v-if="savingGoals.length == 0"
          class="small-label"
        >0 aktiva sparmål</span><br>
        <span
          v-if="savingGoals.length == 0"
          class="small-label dark"
          v-html="model.noSavingsGoal.value"
        />
      </div>
      <div class="col-6 new-saving-goal-link">
        <a
          v-if="savingGoals.length < 10"
          href="#"
          class="action-link right"
          @click="newSaveGoalModal = !newSaveGoalModal"
        >Nytt sparmål</a>
      </div>
    </div>
    <div class="px-1">
      <div
        v-for="(goal, index) in savingGoals"
        :key="`saving_goals_info_${index}`"
        class="row"
      >
        <div class="col-2 col-md-1 pt-3 saving-goal-icon-container">
          <i class="fas fa-hand-holding-usd saving-goal-icon" />
        </div>
        <div class="col-7 col-md-8 pt-3 saving-goal-details-container">
          <h4>{{ goal.savingGoal }}</h4>
          <span
            v-if="goal.latestUpdate != null"
            class="small-label"
          >
            {{
              NumberFormat(goal.latestUpdate.savedValue)
            }} av {{ NumberFormat(goal.targetValue) }} kr
          </span>
          <span
            v-else
            class="small-label"
          >0 av {{ NumberFormat(goal.targetValue) }} kr</span>
        </div>
        <div class="col-3 pt-3 new-saving-goal-link">
          <a
            href="#"
            class="action-link right"
            @click="ShowUpdateSavingGoalOverlay(goal)"
          >Uppdatera</a>
        </div>
        <div class="col-12">
          <div class="savings-container">
            <div
              v-if="goal.latestUpdate != null"
              class="savings-meter"
              data-testid="savings-meter-savings"
              :style="'max-width:100%;width:' + (goal.latestUpdate.savedValue / goal.targetValue) * 100 + '%;'"
            />
          </div>
        </div>
      </div>
    </div>
    <div
      v-if="newSaveGoalModal"
      class="save-goal-card new-save-goal-card-overlay new-overlay"
      style="display:block;"
    >
      <div class="popup">
        <i
          class="fal fa-times fa-lg btn-close"
          @click="newSaveGoalModal = !newSaveGoalModal"
        />
        <h3>Skapa sparmål</h3>
        <span class="slider-box-label">Vad vill du spara till?</span>
        <input
          v-model="newGoal.savingGoal"
          type="text"
          class="slider-label-box"
        ><br>
        <span class="slider-box-label">Hur mycket behöver du spara?</span>
        <input
          v-model="newGoal.targetValue"
          type="number"
          class="slider-label-box"
        >
        <span class="slider-box-label">När vill du uppnå ditt sparmål?</span>
        <input
          v-model="newGoal.durationInMonth"
          class="savings-slider"
          type="range"
          min="0.5"
          max="5"
          step="0.5"
        >
        <div class="slider-label-container">
          <span :style="'left:' + ((newGoal.durationInMonth - 0.5) / 5) * 100 + '%;'">{{ newGoal.durationInMonth }}
            år</span>
        </div>
        <h5>Att spara i månaden</h5>
        <span
          v-if="newGoal.targetValue > 0 && newGoal.durationInMonth > 0"
          class="amount"
        >
          {{
            NumberFormat(Math.round(newGoal.targetValue / (newGoal.durationInMonth * 12), 0))
          }} kr
        </span><br>
        <div
          class=""
          style="float:right;display:block;"
        >
          <a
            href="#"
            class="btn btn-close btn-orange"
            @click="CreateSavingGoal"
          >
            Skapa sparmål
          </a>
        </div>
      </div>
    </div>
    <div
      v-if="updateSaveGoalModal"
      class="save-goal-card update-save-goal-card-overlay new-overlay"
      style="display:block;"
    >
      <div class="popup">
        <i
          class="fal fa-times fa-lg btn-close"
          @click="updateSaveGoalModal = !updateSaveGoalModal"
        />
        <h3>Uppdatera sparmål</h3>
        <span class="slider-box-label">Sparmål</span>
        <div class="slider-label-box disabled">
          <span>{{ currentSavingGoal.savingGoal }}</span>
        </div>

        <span class="slider-box-label">Hur mycket har du sparat totalt?</span>
        <input
          v-model="currentSavingGoal.latestUpdate.savedValue"
          type="number"
          class="slider-label-box"
        >
        <span class="small slider-box-label">Skriv in det totala beloppet som du har sparat, sedan sparmålets
          start.</span><br>
        <span class="slider-box-label">När vill du uppnå ditt sparmål?</span>
        <div
          class="slider-label-box disabled"
          style="margin-bottom:0"
        >
          <span>{{ currentSavingGoal.durationInMonth / 12 }} år</span>
        </div>
        <span class="small slider-box-label">Från startdatumet: {{ currentSavingGoal.startDate }}</span><br>
        <h5>Att spara i månaden</h5>
        <span class="amount">{{ CalculateSavingsPerMonth(currentSavingGoal) }} kr</span><br>
        <div
          class=""
          style="float:right;display:block;"
        >
          <a
            href="#"
            class="btn btn-close btn-delete-saving-goal"
            @click="DeleteSavingGoal"
          >
            Ta bort sparmål
          </a>
          <a
            href="#"
            class="btn btn-close btn-orange"
            @click="UpdateSavingGoal"
          >
            Spara
          </a>
        </div>
      </div>
    </div>
    <InfoModal
      id="saving-goal-question"
      :title="model.questionSavingGoalTitle.value"
      :body="model.questionSavingGoal.value"
    />
  </div>
</template>
<script>
import { OddOrEven, NumberFormat, FormatDate } from '@/_helpers.js';
import InfoModal from '@/components/modals/InfoModal.vue';
import $ from 'jquery';
export default {
  name: 'SavingGoals',
  components: {
    InfoModal
  },
  props: {
    savingGoals: Array,
    model: Object
  },
  data: function () {
    return {
      newSaveGoalModal: false,
      updateSaveGoalModal: false,
      newGoal: {
        "savingGoal": "",
        "startDate": new Date().toISOString().substr(0, 10),
        "durationInMonth": 0.5,
        "targetValue": 0,
        "savingGoalStatus": "ACTIVE"
      },
      currentSavingGoal: {
        latestUpdate: {
          savedValue: null
        }
      }
    }
  },
  methods: {
    OddOrEven: function (index) {
      return OddOrEven(index);
    },
    FormatDate: function (timestamp) {
      return FormatDate(timestamp);
    },
    NumberFormat: function (num) {
      return NumberFormat(num);
    },

    MonthDiff: function (d1, d2) {
      var months;
      months = (d2.getFullYear() - d1.getFullYear()) * 12;
      months -= d1.getMonth();
      months += d2.getMonth();
      return months <= 0 ? 0 : months;
    },
    CreateSavingGoal: function () {
      var self = this;
      var createModel = {
        savingGoal: self.newGoal.savingGoal,
        startDate: self.newGoal.startDate,
        durationInMonth: self.newGoal.durationInMonth,
        targetValue: self.newGoal.targetValue
      };
      $.post("/newcreditwatchapi/createsavinggoal", createModel
        , function (response) {
          response.latestUpdate = {
            "savedValue": 0
          };
          self.savingGoals.push(response);
          self.newGoal = {
            "savingGoal": "",
            "startDate": new Date().toISOString().substr(0, 10),
            "durationInMonth": 0.5,
            "targetValue": 0,
            "latestUpdate": {
              "savedValue": 0
            },
            "savingGoalStatus": "ACTIVE"
          };
          self.newSaveGoalModal = !self.newSaveGoalModal;
        });
    },
    ShowUpdateSavingGoalOverlay: function (goal) {
      this.updateSaveGoalModal = !this.updateSaveGoalModal;
      this.currentSavingGoal = goal;
    },
    UpdateSavingGoal: function () {
      var updateModel = {
        savingGoalId: this.currentSavingGoal.id,
        savedValue: this.currentSavingGoal.latestUpdate.savedValue
      };
      var self = this;
      $.post("/newcreditwatchapi/updatesavinggoal", updateModel
        , function () {
          self.updateSaveGoalModal = !self.updateSaveGoalModal;
        });
    },
    CalculateSavingsPerMonth: function (savingGoal) {
      var savedValue = savingGoal.latestUpdate ? savingGoal.latestUpdate.savedValue : 0;
      return this.NumberFormat(Math.round((savingGoal.targetValue - savedValue) / (savingGoal.durationInMonth - this.MonthDiff(new Date(savingGoal.startDate), new Date())), 0))
    },
    DeleteSavingGoal: function () {
      var self = this;
      $.ajax({
        url: '/newcreditwatchapi/deletesavinggoal/' + this.currentSavingGoal.id,
        type: 'DELETE',
        success: function () {
          $.each(self.savingGoals, function (i) {
            if (self.savingGoals[i].id === self.currentSavingGoal.id) {
              self.savingGoals.splice(i, 1);
            }
          });
        }
      });
      self.updateSaveGoalModal = !self.updateSaveGoalModal;
    },
  }
}
</script>