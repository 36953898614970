<template>
  <div>
    <div
      id="income-and-taxation-table-year-0"
      class="dashboard__table"
    >
      <div
        v-if="ctiCsnLoan.hasInstallmentLoan"
        id="csn-accordion-0"
        class="block__enento__accordion"
      >
        <div class="panel-group border-0">
          <div class="panel-heading">
            <a
              class="d-block collapsed"
              data-toggle="collapse"
              href="#csn-installment-accordion"
              data-testid="csn-accordion-0"
            >
              <p class="dashboard__table-column color-nightrider py-3 mb-0">
                Annuitetslån <span class="d-block font-weight-light color-ocean">
                  {{
                    ctiCsnLoan.csnLoanElement[0] && ctiCsnLoan.csnLoanElement[0].installmentLoan && ctiCsnLoan.csnLoanElement[0].installmentLoan.creditAmount !== null ?
                      NumberFormat(ctiCsnLoan.csnLoanElement[0].installmentLoan.creditAmount) + " kr" :
                      "-"
                  }}
                </span>
              </p>
            </a>
          </div>
          <div
            id="csn-installment-accordion"
            class="collapse"
            data-parent="#csn-accordion-0"
          >
            <div class="dashboard__table-column">
              <p class="mb-0">
                <span class="color-emperor font-weight-medium">Återbetalningstid</span>
                <span class="d-block">
                  <template v-if="ctiCsnLoan.csnLoanElement[1] && ctiCsnLoan.csnLoanElement[1].installmentLoan">
                    {{
                      ctiCsnLoan.csnLoanElement[1].installmentLoan.repaymentPeriod == 0 ? '-' : ctiCsnLoan.csnLoanElement[1].installmentLoan.repaymentPeriod + " år"
                    }}
                  </template>
                  <span v-else>-</span>
                </span>
              </p>
            </div>
            <div
              v-for="(loanElement, index) in ctiCsnLoan.csnLoanElement"
              :key="index"
              class="dashboard__table-column"
            >
              <p class="mb-0">
                <span
                  v-if="loanElement"
                  class="color-emperor font-weight-medium"
                >
                  {{ FormatDate(loanElement.date.timeStamp.value) }}
                </span>
                <span class="d-block">
                  <template v-if="loanElement && loanElement.installmentLoan">
                    {{ NumberFormat(loanElement.installmentLoan.creditAmount) }} kr
                  </template>
                  <span v-else>-</span>
                </span>
              </p>
            </div>
          </div>
        </div>
      </div>
      <div
        v-if="ctiCsnLoan.hasInstallmentLoan2022"
        id="csn-accordion-0-1"
        class="block__enento__accordion"
      >
        <div class="panel-group border-0">
          <div class="panel-heading">
            <a
              class="d-block collapsed"
              data-toggle="collapse"
              href="#csn-installment2022-accordion"
              data-testid="csn-accordion-0-1"
            >
              <p class="dashboard__table-column color-nightrider py-3 mb-0">
                Annuitetslån 2022
                <span class="d-block font-weight-light color-ocean">
                  {{
                    ctiCsnLoan.csnLoanElement[0] && ctiCsnLoan.csnLoanElement[0].installmentLoan2022 && ctiCsnLoan.csnLoanElement[0].installmentLoan2022.creditAmount !== null ?
                      NumberFormat(ctiCsnLoan.csnLoanElement[0].installmentLoan2022.creditAmount) + " kr" :
                      '<span>-</span>'
                  }}
                </span>
              </p>
            </a>
          </div>
          <div
            id="csn-installment2022-accordion"
            class="collapse"
            data-parent="#csn-accordion-0-1"
          >
            <div class="dashboard__table-column">
              <p class="mb-0">
                <span class="color-emperor font-weight-medium">Återbetalningstid</span>
                <span class="d-block">
                  <template v-if="ctiCsnLoan.csnLoanElement[1] && ctiCsnLoan.csnLoanElement[1].installmentLoan2022">
                    {{
                      ctiCsnLoan.csnLoanElement[1].installmentLoan2022.repaymentPeriod == 0 ? '-' : ctiCsnLoan.csnLoanElement[1].installmentLoan2022.repaymentPeriod + " år"
                    }}
                  </template>
                  <span v-else>-</span>
                </span>
              </p>
            </div>
            <div
              v-for="(loanElement, index) in ctiCsnLoan.csnLoanElement"
              :key="index"
              class="dashboard__table-column"
            >
              <p class="mb-0">
                <span
                  v-if="loanElement"
                  class="color-emperor font-weight-medium"
                >
                  {{ FormatDate(loanElement.date.timeStamp.value) }}
                </span>
                <span class="d-block">
                  <template v-if="loanElement && loanElement.installmentLoan2022">
                    {{ NumberFormat(loanElement.installmentLoan2022.creditAmount) }} kr
                  </template>
                  <span v-else>-</span>
                </span>
              </p>
            </div>
          </div>
        </div>
      </div>
      <div
        v-if="ctiCsnLoan.hasStudentLoan"
        id="csn-accordion-1"
        class="block__enento__accordion"
      >
        <div class="panel-group border-0">
          <div class="panel-heading">
            <a
              class="d-block collapsed"
              data-toggle="collapse"
              href="#csn-studentloan-accordion"
              data-testid="csn-accordion-1"
            >
              <p class="dashboard__table-column color-nightrider py-3 mb-0">
                Studielån <span class="d-block font-weight-light color-ocean">
                  {{
                    NumberFormat(ctiCsnLoan.csnLoanElement[0].studentLoan.creditAmount)
                  }} kr
                </span>
              </p>
            </a>
          </div>
          <div
            id="csn-studentloan-accordion"
            class="collapse"
            data-parent="#csn-accordion-1"
          >
            <div class="dashboard__table-column">
              <p class="mb-0">
                <span class="color-emperor font-weight-medium">Återbetalningstid</span>
                <span class="d-block">
                  <template v-if="ctiCsnLoan.csnLoanElement[1] && ctiCsnLoan.csnLoanElement[1].studentLoan">
                    {{
                      ctiCsnLoan.csnLoanElement[1].studentLoan.repaymentPeriod == 0 ? '-' : ctiCsnLoan.csnLoanElement[1].studentLoan.repaymentPeriod + " år"
                    }}
                  </template>
                </span>
              </p>
            </div>
            <div
              v-for="(loanElement, index) in ctiCsnLoan.csnLoanElement"
              :key="index"
              class="dashboard__table-column"
            >
              <p class="mb-0">
                <span
                  v-if="loanElement"
                  class="color-emperor font-weight-medium"
                >
                  {{ FormatDate(loanElement.date.timeStamp.value) }}
                </span>
                <span class="d-block">
                  <template v-if="loanElement && loanElement.studentLoan">
                    {{ NumberFormat(loanElement.studentLoan.creditAmount) }} kr
                  </template>
                  <span v-else>-</span>
                </span>
              </p>
            </div>
          </div>
        </div>
      </div>
      <div
        v-if="ctiCsnLoan.hasStudentMeans"
        id="csn-accordion-2"
        class="block__enento__accordion"
      >
        <div class="panel-group border-0">
          <div class="panel-heading">
            <a
              class="d-block collapsed"
              data-toggle="collapse"
              href="#csn-studentmeans-accordion"
              data-testid="csn-accordion-2"
            >
              <p class="dashboard__table-column color-nightrider py-3 mb-0">
                Studiemedelslån <span class="d-block font-weight-light color-ocean">
                  {{
                    NumberFormat(ctiCsnLoan.csnLoanElement[0].studentMeans.creditAmount)
                  }} kr
                </span>
              </p>
            </a>
          </div>
          <div
            id="csn-studentmeans-accordion"
            class="collapse"
            data-parent="#csn-accordion-2"
          >
            <div class="dashboard__table-column">
              <p class="mb-0">
                <span class="color-emperor font-weight-medium">Återbetalningstid</span>
                <span class="d-block">
                  <template v-if="ctiCsnLoan.csnLoanElement[1] && ctiCsnLoan.csnLoanElement[1].studentMeans">
                    {{
                      ctiCsnLoan.csnLoanElement[1].studentMeans.repaymentPeriod == 0 ? '-' : ctiCsnLoan.csnLoanElement[1].studentMeans.repaymentPeriod + " år"
                    }}
                  </template>
                </span>
              </p>
            </div>
            <div
              v-for="(loanElement, index) in ctiCsnLoan.csnLoanElement"
              :key="index"
              class="dashboard__table-column"
            >
              <p class="mb-0">
                <span
                  v-if="loanElement"
                  class="color-emperor font-weight-medium"
                >
                  {{ FormatDate(loanElement.date.timeStamp.value) }}
                </span>
                <span class="d-block">
                  <template v-if="loanElement && loanElement.studentMeans">
                    {{ NumberFormat(loanElement.studentMeans.creditAmount) }} kr
                  </template>
                  <span v-else>-</span>
                </span>
              </p>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
    import { FormatDate, NumberFormat } from '../_helpers.js';

    export default {
        name: 'CsnMobile',
        props: {
            ctiCsnLoan: {},
        },
        methods: {
            FormatDate: function (timestamp) {
                return FormatDate(timestamp);
            },
            NumberFormat: function (num) {
                return NumberFormat(num);
            }
        }
    }
</script>
<style lang="scss" scoped>
    @import "@/../../styles/abstracts/_variables.scss";
    @import "@/../../styles/abstracts/_mixins.scss";

    .current {
        background-color: $ice-blue;
    }
</style>