
const useCookieConsent = () => {
    const getCookieConsenXHTML = ({id, classes, title = "godkänna cookies" }) =>  (`<button id="acceptCookiesLinkButton-${id}" data-testid="accept-cookies-link-button" class="${classes}" onclick="onCookieConsentLinkButtonClicked()"><b>${title}</b></button>`);
    const onCookieConsentLinkButtonClicked = () => {
        if (window?.CookieConsent?.show) {
            return window.CookieConsent.show();
        }
        return console.log('failed to show cookies modal (this shows when developing on localhost or when cookieinformation failed to load)');
    }
    if (!window.onCookieConsentLinkButtonClicked) {
        window.onCookieConsentLinkButtonClicked = onCookieConsentLinkButtonClicked;
    }
    return { onCookieConsentLinkButtonClicked, getCookieConsenXHTML }
};
export default useCookieConsent;