import $ from 'jquery';
//var $siteMenu = $('.site-menu');
//var $siteMenuChild = $('.site-menu__child');
//var $siteMenuGrandChild = $('.site-menu__grandchild');

//var $siteHeader = $('.site-header');
//var $siteHeaderSub = $('.site-header__sub');

//// SHOW ACTIVE LINKTRAIL IN NAV
//$('.site-menu .active').parentsUntil($siteMenu, 'li').show().addClass('active');
//$('.navbar-nav .active').parentsUntil($siteMenu, 'li').addClass('active');

//// ICON INDICATORS
//$('.site-menu .js-toggle i').removeClass('fa-chevron-up').addClass('fa-chevron-down');
//$('.site-menu .active > .js-toggle i').removeClass('fa-chevron-down').addClass('fa-chevron-up');
//$('.site-header__sub .parent .parent i').removeClass('fa-chevron-down').addClass('fa-chevron-right');

//$siteMenuChild.each(function () {
//    let $childMenu = $(this);
//    let $parentLi = $childMenu.parent();

//    $parentLi.hasClass('active') ? $childMenu.addClass('open') : $childMenu.addClass('closed');
//});

//$('.site-menu .js-toggle').on('click', function (event) {
//    event.preventDefault();
//    let $link = $(this);
//    let $childUl = $link.next();

//    if ($childUl.hasClass('open')) {
//        $link.find('i').removeClass('fa-chevron-up').addClass('fa-chevron-down');
//        $childUl.slideUp().removeClass('open').addClass('closed');
//    } else { 
//        $link.find('i').removeClass('fa-chevron-down').addClass('fa-chevron-up');
//        $childUl.slideDown().removeClass('closed').addClass('open');
//    }

//}); 

//// HEADER VISIBLE ON SCROLL
//var didScroll;
//var lastScrollTop = 0;
//var delta = 5;
//var navbarHeight = $($siteHeader).outerHeight();

//$(window).scroll(function (event) {
//    didScroll = true;
//});

//setInterval(function () {
//    if (didScroll && !$('html').hasClass('overlay-visible')) {
//        hasScrolled();
//        didScroll = false;
//    }
//}, 250);

//function hasScrolled() { 
//    var st = $(this).scrollTop();
//    if (Math.abs(lastScrollTop - st) <= delta)
//        return;
//    if (st > lastScrollTop && st > navbarHeight) { 
//        $siteHeader.removeClass('site-header--nav-down').addClass('site-header--nav-up');
//    } else {
//        if (st + $(window).height() < $(document).height()) {
//            $siteHeader.removeClass('site-header--nav-up').addClass('site-header--nav-down');
//        }
//    }
//    lastScrollTop = st; 
//}

$(function () {

    $('.site-header').hover(function () {
        // Do nothing
    }, function () {
        $('.cascade__menu-expanded .tab-pane').removeClass('active');
        $('.cascade__menu-expanded .tab-pane').removeClass('show');
        $('.cascade__menu-top .nav-link').removeClass('active');
    });
    $('.cascade__menu-trigger .nav-pills > li > a').hover(function () {
        $(this).tab('show');
    });
    $('.site-header__top').hover(function () {
        $('.cascade__menu-expanded .tab-pane').removeClass('active');
        $('.cascade__menu-expanded .tab-pane').removeClass('show');
        $('.cascade__menu-top .nav-link').removeClass('active');
    });
    $('.cascade-hide').hover(function () {
        $('.cascade__menu-expanded .tab-pane').removeClass('active');
        $('.cascade__menu-expanded .tab-pane').removeClass('show');
        $('.cascade__menu-top .nav-link').removeClass('active');
    });
});

