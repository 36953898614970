<template>
  <div
    class="productlockedblock dashboard__card d-flex flex-column"
    :style="{'background':'url(' + model.backgroundImage.value.url + ') center center no-repeat', backgroundSize: 'cover'}"
  >
    <div class="d-flex align-items-center mb-3">
      <h3 class="color-white mb-0">
        {{ model.heading.value }}
      </h3> 
      <span class="rounded-border bg-gray p-1 ml-auto">
        <i class="far fa-lock fa-fw color-white" />
      </span>
    </div>
    <div class="text-center color-white px-md-4 mt-auto">
      <!--<h3 class="mb-3">{{ model.mainBody.value }}></h3>-->
      <p v-html="model.mainBody.value" />
      <a
        class="btn btn-sm btn-transparent font-weight-light w-md-60 mb-2"
        :href="model.link.value.url"
        role="button"
      ><span class="color-white">{{ model.linkText.value }}</span><i
        class="far fa-arrow-right fa-fw color-white font-weight-light ml-2"
      /></a>
    </div>
  </div>
</template>
<script>
export default {
  name: 'ProductLockedBlock',
  props: {
    currentPage: {},
    notifications: Array,
    menuItems: Array,
    model: Object
  },
}
</script>