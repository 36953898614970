import { getFraudBarrierStatus } from '../../providers/frontendApiControllerProviders';
import { postChangeProtectionStatus } from '../../providers/customerApiProvider';
import { getContentByFriendlyUrl } from '../../providers/epiContentProvider';

const FRAUD_BARRIER = 'FRAUD_BARRIER';
export const FRAUD_BARRIER_FETCH = `${FRAUD_BARRIER}/FETCH`;
export const FRAUD_BARRIER_MODEL_FETCH = `${FRAUD_BARRIER}/MODEL_FETCH`;
export const FRAUD_BARRIER_POST = `${FRAUD_BARRIER}/POST`;
const FRAUD_BARRIER_INIT = `${FRAUD_BARRIER}/INIT`;
const FRAUD_BARRIER_UPDATE = `${FRAUD_BARRIER}/UPDATE`;
const FRAUD_BARRIER_UPDATE_FAILED = `${FRAUD_BARRIER}/UPDATE_FAILED`;
const FRAUD_BARRIER_MODEL_INIT = `${FRAUD_BARRIER}/MODEL_INIT`;
const FRAUD_BARRIER_MODEL_UPDATE = `${FRAUD_BARRIER}/MODEL_UPDATE`;
const FRAUD_BARRIER_MODEL_UPDATE_FAILED = `${FRAUD_BARRIER}/MODEL_FAILED`;

const emptyFraudBarrier = {
    FraudBarrierDate: "",
    FraudBarrierUploadedDate: -1,
    FraudBarrierOn: false,
    FraudBarrierOff: false,
    FraudBarrierOnWarning: false,
    HasPoliceReport: false,
    PoliceReportNames: [],
    PoliceReportCanUpload: false,
    FraudBarrierShowStatus: false,
    FraudBarrierShowFiveDayStatus: false,
    FraudBarrierShowStatusNotification: false,
    FraudBarrierStatus: "",
    IsFraudBarrierOpen: false
}

const state = {
    fraudBarrier: emptyFraudBarrier,
    loaded: false,
    failed: false,
    pending: false,
    fraudBarrierModel: {},
    modelLoaded: false,
    modelFailed: false,
    modelPending: false,
};

const mutations = {
    [FRAUD_BARRIER_UPDATE](state, newModel) {
        state.fraudBarrier = newModel;
        state.loaded = true;
        state.failed = false;
        state.pending = false;
    },
    [FRAUD_BARRIER_UPDATE_FAILED](state) {
        state.fraudBarrier = emptyFraudBarrier;
        state.loaded = true;
        state.failed = true;
        state.pending = false;
    },
    [FRAUD_BARRIER_INIT](state) {
        state.fraudBarrier = emptyFraudBarrier;
        state.loaded = false;
        state.failed = false;
        state.pending = true;
    },
    [FRAUD_BARRIER_MODEL_UPDATE](state, newModel) {
        state.fraudBarrierModel = newModel;
        state.modelLoaded = true;
        state.modelFailed = false;
        state.modelPending = false;
    },
    [FRAUD_BARRIER_MODEL_UPDATE_FAILED](state) {
        state.fraudBarrierModel = {};
        state.modelLoaded = true;
        state.modelFailed = true;
        state.modelPending = false;
    },
    [FRAUD_BARRIER_MODEL_INIT](state) {
        state.fraudBarrierModel = {};
        state.modelLoaded = false;
        state.modelFailed = false;
        state.modelPending = true;
    },
};

const actions = {
    [FRAUD_BARRIER_FETCH]({commit}) {
        commit(FRAUD_BARRIER_INIT);
        return getFraudBarrierStatus().then(response => {
            commit(FRAUD_BARRIER_UPDATE, response.data);
        }).catch(()=> {
            commit(FRAUD_BARRIER_UPDATE_FAILED);
        });
    },
    [FRAUD_BARRIER_MODEL_FETCH]({commit}) {
        commit(FRAUD_BARRIER_MODEL_INIT);
        return getContentByFriendlyUrl("/mina-tjanster/bedragerisparr").then(response => {
            commit(FRAUD_BARRIER_MODEL_UPDATE, response.data);
        }).catch(()=> {
            commit(FRAUD_BARRIER_MODEL_UPDATE_FAILED);
        });
    },
    [FRAUD_BARRIER_POST]({commit, dispatch}, {newStatus, csrfToken}) {
        commit(FRAUD_BARRIER_INIT);
        return postChangeProtectionStatus({newStatus, csrfToken}).then(response => {
            if (response.data.statusCode == 429) {
                return commit(FRAUD_BARRIER_UPDATE_FAILED);
            }
            dispatch(FRAUD_BARRIER_FETCH);
        }).catch(()=> {
            commit(FRAUD_BARRIER_UPDATE_FAILED);
        });
    },
};

export const fraudBarrierContex = {
    state,
    mutations,
    actions
};
